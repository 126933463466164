import {encryptTransform} from 'redux-persist-transform-encrypt'

const encryptor = encryptTransform({
    secretKey: 'NPEZJOSECR',
    onError: (error) => {
        // Handle the error.
        // eslint-disable-next-line no-console
        console.log(error)
    },
})
export default encryptor
