const HelperMethods = {
    formatDate(d) {
        if (!d) {
            return ''
        }
        const date = new Date(d)
        let dd = date.getDate()
        let mm = date.getMonth() + 1
        const yyyy = date.getFullYear()

        if (dd < 10) {
            dd = `0${dd}`
        }
        if (mm < 10) {
            mm = `0${mm}`
        }

        return `${mm}/${dd}/${yyyy}`
    },
    formatFaxAndPhoneNumber(value) {
        let input = value.replace(/\D/g, '').substring(0, 10)
        const size = input.length
        if (size > 0) {
            input = '(' + input
        }
        if (size > 3) {
            input = input.slice(0, 4) + ') ' + input.slice(4)
        }
        if (size > 6) {
            input = input.slice(0, 9) + '-' + input.slice(9)
        }
        return input
    },
    formatDecimal(val) {
        const hasDecimal = !!(val % 1)
        if (hasDecimal) {
            return val.toFixed(2)
        }
        return val
    },

    datesAreOnSameDay(firstDate, secondDate) {
        return (
            firstDate.getFullYear() === secondDate.getFullYear() &&
            firstDate.getMonth() === secondDate.getMonth() &&
            firstDate.getDate() === secondDate.getDate()
        )
    },

    numberOfMonthsBetweenDates(startDate, endDate) {
        startDate = new Date(startDate)
        endDate = new Date(endDate)

        return (
            endDate.getMonth() -
            startDate.getMonth() +
            12 * (endDate.getFullYear() - startDate.getFullYear())
        )
    },

    dateInputMask(input, originalValue) {
        let newValue = input
        if (newValue.length < 3) {
            newValue = newValue.replace(/\D/, '')
        }
        if (newValue.length === 3) {
            if (newValue.charAt(2) !== '/') {
                newValue = newValue.replace(/[^0-9/]/g, '')
                newValue = [newValue.slice(0, 2), '/', newValue.slice(2)].join('')
            } else {
                newValue = newValue.replace(/[^0-9/]/g, '')
            }
        }
        if (newValue.length === 4 && !/^\d+$/.test(newValue.charAt(3))) {
            return originalValue
        }
        if (newValue.length === 5 && !/^\d+$/.test(newValue.charAt(4))) {
            return originalValue
        }
        if (newValue.length === 6) {
            if (newValue.charAt(5) !== '/') {
                newValue = newValue.replace(/[^0-9/]/g, '')
                newValue = [newValue.slice(0, 5), '/', newValue.slice(5)].join('')
            } else {
                newValue = newValue.replace(/[^0-9/]/g, '')
            }
        }
        if (newValue.length === 7 && !/^\d+$/.test(newValue.charAt(6))) {
            return originalValue
        }
        if (newValue.length === 8 && !/^\d+$/.test(newValue.charAt(7))) {
            return originalValue
        }
        if (newValue.length === 9 && !/^\d+$/.test(newValue.charAt(8))) {
            return originalValue
        }
        if (newValue.length === 10 && !/^\d+$/.test(newValue.charAt(9))) {
            return originalValue
        }
        if (newValue.length > 10) {
            return originalValue
        }

        return newValue
    },

    ssnMask(input, originalValue) {
        let newValue = input
        if (newValue.length < 4) {
            newValue = newValue.replace(/\D/, '')
        }
        if (newValue.length === 4) {
            if (newValue.charAt(3) !== '-') {
                newValue = newValue.replace(/[^0-9-]/g, '')
                newValue = [newValue.slice(0, 3), '-', newValue.slice(3)].join('')
            } else {
                newValue = newValue.replace(/[^0-9-]/g, '')
            }
        }
        if (newValue.length === 5 && !/^\d+$/.test(newValue.charAt(4))) {
            return originalValue
        }
        if (newValue.length === 6 && !/^\d+$/.test(newValue.charAt(5))) {
            return originalValue
        }
        if (newValue.length === 7) {
            if (newValue.charAt(3) !== '-') {
                newValue = newValue.replace(/[^0-9-]/g, '')
                newValue = [newValue.slice(0, 3), '-', newValue.slice(3)].join('')
            }
            if (newValue.charAt(6) !== '-') {
                newValue = newValue.replace(/[^0-9-]/g, '')
                newValue = [newValue.slice(0, 6), '-', newValue.slice(6)].join('')
            } else {
                newValue = newValue.replace(/[^0-9-]/g, '')
            }
        }

        if (newValue.length === 8) {
            if (newValue.charAt(3) !== '-') {
                newValue = newValue.replace(/[^0-9-]/g, '')
                newValue = [newValue.slice(0, 3), '-', newValue.slice(3)].join('')
            }
            if (newValue.charAt(6) !== '-') {
                newValue = newValue.replace(/[^0-9-]/g, '')
                newValue = [newValue.slice(0, 6), '-', newValue.slice(6)].join('')
            }
            if (!/^\d+$/.test(newValue.charAt(7))) {
                return originalValue
            }
        }

        if (newValue.length === 9) {
            if (newValue.charAt(3) !== '-') {
                newValue = newValue.replace(/[^0-9-]/g, '')
                newValue = [newValue.slice(0, 3), '-', newValue.slice(3)].join('')
            }
            if (newValue.charAt(6) !== '-') {
                newValue = newValue.replace(/[^0-9-]/g, '')
                newValue = [newValue.slice(0, 6), '-', newValue.slice(6)].join('')
            }
            if (!/^\d+$/.test(newValue.charAt(8))) {
                return originalValue
            }
        }

        if (newValue.length === 10) {
            if (newValue.charAt(3) !== '-') {
                newValue = newValue.replace(/[^0-9-]/g, '')
                newValue = [newValue.slice(0, 3), '-', newValue.slice(6)].join('')
            }
            if (newValue.charAt(6) !== '-') {
                newValue = newValue.replace(/[^0-9-]/g, '')
                newValue = [newValue.slice(0, 6), '-', newValue.slice(6)].join('')
            }
            if (!/^\d+$/.test(newValue.charAt(9))) {
                return originalValue
            }
        }

        if (newValue.length === 11) {
            if (newValue.charAt(3) !== '-') {
                newValue = newValue.replace(/[^0-9-]/g, '')
                newValue = [newValue.slice(0, 3), '-', newValue.slice(6)].join('')
            }
            if (newValue.charAt(6) !== '-') {
                newValue = newValue.replace(/[^0-9-]/g, '')
                newValue = [newValue.slice(0, 6), '-', newValue.slice(6)].join('')
            }
            if (!/^\d+$/.test(newValue.charAt(10))) {
                return originalValue
            }
        }
        if (newValue.length > 11) {
            return originalValue
        }

        return newValue
    },
    ssnMaskLast4(ssn) {
        let maskedNumbers

        let visibleNumbers

        if (ssn.length > 7 && ssn.includes('-')) {
            maskedNumbers = ssn.substring(0, 6).replace(/[^-]/g, '*')

            visibleNumbers = ssn.substring(6, ssn.length)

            return `${maskedNumbers}${visibleNumbers}`
        }

        if (!ssn.includes('-')) {
            ssn = ssn.replace(/-/g, '')

            const regex = /^([^\s]{3})([^\s]{2})([^\s]{4})$/g

            const match = regex.exec(ssn)

            if (match) {
                match.shift()
                ssn = match.join('-')
            }

            maskedNumbers = ssn.substring(0, 6).replace(/[^-]/g, '*')

            visibleNumbers = ssn.substring(6, ssn.length)

            return `${maskedNumbers}${visibleNumbers}`
        }

        return ssn.replace(/[^-]/g, '*')
    },
}

export const isCredentialsFormatValid = (userName, password) => {
    let isValid = true
    if (userName !== '') {
        userName.trim()
    }
    if (password !== '') {
        password.trim()
    }
    if (userName === '' && password === '') {
        isValid = false
    } else if (userName === '') {
        isValid = false
    } else if (password === '') {
        isValid = false
    }
    return isValid
}

export const openPdf = (data) => {
    const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}))
    const link = document.createElement('a')
    link.href = url
    link.target = '_blank'
    link.rel = 'noopener noreferrer'
    document.body.appendChild(link)
    link.click()
}

export default HelperMethods
