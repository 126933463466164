import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {LoadingOverlay} from 'aba-ui'
import styles from './NavAccordion.module.css'
import useUser from '../../../libs/hooks/useUser'
import RTIDAPI from '../../../helpers/AxiosInstance'
import {openPdf} from '../../../helpers/HelperMethods'

export default function NavAccordion({links}) {
    const {childLink, parentLink} = styles
    const [showSublinks, setShowSublinks] = useState(false)
    const [loading, setLoading] = useState(false)
    const {userData} = useUser()

    const getPDF = async () => {
        try {
            setLoading('Loading...')
            const {data} = await RTIDAPI.get(`report/noPortalAccount`, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/pdf',
                },
            })
            openPdf(data)
            setLoading(false)
        } catch (error) {
            alert('An error occurred while loading your report')
            console.log(error)
            setLoading(false)
        }
    }

    const {UserRole, ProgramType} = userData
    return (
        <div>
            {loading && <LoadingOverlay title={loading} />}
            {links.name === 'Help' && (
                <a
                    href="http://www.theaba.org/"
                    className={parentLink}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    ABA Website
                </a>
            )}
            <div
                className={parentLink}
                onClick={() => setShowSublinks(!showSublinks)}
                role="button"
                tabIndex="-1"
            >
                <span>{links.name}</span>

                <div
                    style={{
                        display: 'inline-block',
                        float: 'right',
                        verticalAlign: 'middle',
                    }}
                >
                    <FontAwesomeIcon
                        icon={showSublinks ? 'angle-down' : 'angle-right'}
                        style={{
                            color: 'white',
                        }}
                    />
                </div>
            </div>

            <div>
                {showSublinks &&
                    links.subLinks.map((subLink, result) => {
                        if (
                            (subLink.name === 'Residents without portal account' ||
                                subLink.name === 'ADVANCED Exam' ||
                                subLink.name === 'Exam Registration Status' ||
                                subLink.name === 'SOE and OSCE Exams') &&
                            ProgramType !== 513
                        ) {
                            return
                        }
                        if (
                            subLink.role &&
                            !subLink.role.includes(UserRole) &&
                            UserRole !== 'SuperUser'
                        ) {
                            return
                        }
                        if (subLink.name === 'Residents without portal account') {
                            return (
                                <Link
                                    className={childLink}
                                    rel="noreferrer noopener"
                                    onClick={() => {
                                        getPDF(result.ProgramID)
                                    }}
                                    key={subLink.name}
                                >
                                    {subLink.name}
                                </Link>
                            )
                        }

                        return (
                            <Link to={subLink.url} className={childLink} key={subLink.name}>
                                {subLink.name}
                            </Link>
                        )
                    })}
            </div>
        </div>
    )
}

NavAccordion.propTypes = {
    links: PropTypes.shape({
        name: PropTypes.string,
        subLinks: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                url: PropTypes.string,
            }),
        ),
    }).isRequired,
}
