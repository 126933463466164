import React, {useState, useEffect} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import RTIDAPI from '../../helpers/AxiosInstance'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import '../styles/table.css'
import '../styles/invoiceDetails.css'
import {ColorEnums} from '../../helpers/GeneralEnums'
import ABAContainer from '../components/ABAComponents/ABAContainer/ABAContainer'
import ABAHeader from '../components/ABAComponents/ABAHeader'
import {LoadingOverlay} from 'aba-ui'

export default function InvoiceDetails(props) {
    const location = useLocation()
    const invoiceId = location.state?.invoiceId
    const navigate = useNavigate()

    const [loading, setLoading] = useState(null)
    const [invoiceDetails, setInvoiceDetails] = useState(null)

    const getInvoiceDetails = async () => {
        try {
            setLoading('Getting invoice details')
            const {data} = await RTIDAPI.get(`invoice/invoiceDetails/${invoiceId}`)
            setInvoiceDetails(data)
        } catch (error) {
            alert(error)
        } finally {
            setLoading(null)
        }
    }

    const renderRow = (firstCol, secondCol) => {
        return (
            <tr className="invoiceDetailRow">
                <td>{firstCol}</td>
                <td>{secondCol ? secondCol : '--'}</td>
            </tr>
        )
    }

    useEffect(() => {
        getInvoiceDetails()
    }, [])

    return (
        <div>
            {loading && <LoadingOverlay title={loading} />}
            <ABAHeader text="Account" />
            <ABAContainer label="Invoice Details">
                <div
                    style={{
                        width: '800px',
                        margin: '40px auto 20px auto',
                        paddingLeft: '10px',
                        fontWeight: 'bold',
                        fontSize: '14px',
                    }}
                >
                    LINE ITEMS
                </div>
                <table
                    className="table"
                    style={{
                        width: '800px',
                    }}
                >
                    <thead>
                        <tr className="tableHeader" style={{textAlign: 'left'}}>
                            <th style={{width: '35%'}}>Amount</th>
                            <th style={{width: '65%'}}>Description</th>
                        </tr>
                    </thead>
                    <tbody className="invoiceDetailTableBody">
                        {renderRow(`$${invoiceDetails?.TotalAmount}`, invoiceDetails?.Description)}
                    </tbody>
                    <div
                        style={{
                            paddingLeft: '10px',
                            fontWeight: 'bold',
                            marginTop: '30px',
                            marginBottom: '15px',
                        }}
                    >
                        DETAILS
                    </div>
                    <tbody className="invoiceDetailTableBody">
                        {renderRow('Organization', 'The American Board of Anesthesiology, Inc.')}
                        {renderRow('Payment ID', invoiceDetails?.PaymentId.toUpperCase())}
                        {renderRow('Date', invoiceDetails?.DatePaid)}
                        {renderRow('Description', invoiceDetails?.Description)}
                        {renderRow(
                            'Amount',
                            invoiceDetails?.TotalAmount && `$${invoiceDetails.TotalAmount}`,
                        )}
                        {renderRow('Payment Type', invoiceDetails?.PaymentTypeLabel)}
                        {renderRow('Approval Code', invoiceDetails?.ApprovalCode)}
                    </tbody>
                </table>
                <div
                    style={{
                        width: '800px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingLeft: '10px',
                        color: ColorEnums.danube,
                        paddingTop: '10px',
                        paddingBottom: '50px',
                    }}
                    onClick={() => {
                        navigate('/invoices')
                    }}
                >
                    <div className="returnLink" style={{display: 'inline-block'}}>
                        <span style={{paddingRight: '5px'}}>
                            <FontAwesomeIcon icon="angle-left" />
                        </span>
                        Return to Invoices
                    </div>
                </div>
            </ABAContainer>
        </div>
    )
}
