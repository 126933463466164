import {useConfiguration} from '../../../libs/hooks/hooks'
import useAuthentication from '../../../libs/hooks/useAuthentication'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'

const getErrorMessage = (errorMessage) => {
    switch (errorMessage) {
        case 'unauthorized':
            return 'Your session has expired. Please login again.'
        default:
            return errorMessage
    }
}

const useLogin = () => {
    const {buildID, environment} = useConfiguration()
    const location = useLocation()
    const state = location.state
    const {errorMessage} = useSelector((state) => state.global)
    const authentication = useAuthentication()

    const onSubmit = (username, password) => {
        authentication.login(username, password)
    }

    return {
        onSubmit,
        buildID,
        environment,
        errorMessage: getErrorMessage(state?.errorMessage || errorMessage),
    }
}

export default useLogin
