// This page has all the actions possible with redux. These are utilized in ActionCreators and in reducer files

export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_BEGIN = 'LOGIN_BEGIN'
export const ADD_OPTION_SETS = 'ADD_OPTION_SETS'
export const RESET_REDUX = 'RESET_REDUX'
export const SCORECARD_FAILED = 'SCORECARD_FAILED'
export const SCORECARD_SUCCESS = 'SCORECARD_SUCCESS'
export const RESIDENTLISTING_SUCCESS = 'RESIDENTLISTING_SUCCESS'
export const RESIDENTLISTING_FAILED = 'RESIDENTLISTING_FAILED'
export const RESIDENTLISTING_UPDATE = 'RESIDENTLISTING_UPDATE'
export const RESIDENTLISTING_LOAD = 'RESIDENTLISTING_LOAD'
export const RESIDENT_CLOSE = 'RESIDENT_CLOSE'
export const RESIDENT_OPEN = 'RESIDENT_OPEN'
export const TRAININGRECORDS_SUCCESS = 'TRAININGRECORDS_SUCCESS'
export const TRAININGRECORDS_FAILED = 'TRAININGRECORDS_FAILED'
export const TRAININGRECORDS_UPDATE = 'TRAININGRECORDS_UPDATE'
export const TRAININGRECORDS_LOAD = 'TRAININGRECORDS_LOAD'
export const TRAININGSUMMARY_SUCCESS = 'TRAININGSUMMARY_SUCCESS'
export const TRAININGSUMMARY_FAILED = 'TRAININGSUMMARY_FAILED'
export const TRAININGSUMMARY_UPDATE = 'TRAININGSUMMARY_UPDATE'
export const WINDOW_FOCUSED = 'WINDOW_FOCUSED'
