import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from './HelpAccordion.module.css'

export default function HelpAccordion(props) {
    const {links} = props
    const {childLink, parentLink} = styles
    const [showSublinks, setShowSublinks] = useState(false)

    return (
        <div>
            <div
                className={parentLink}
                onClick={() => setShowSublinks(!showSublinks)}
                onKeyDown={() => setShowSublinks(!showSublinks)}
                role="button"
                tabIndex="-1"
            >
                <span>{`View Here >`}</span>

                <div
                    style={{
                        display: 'inline-block',
                        float: 'right',
                        verticalAlign: 'middle',
                    }}
                >
                    <FontAwesomeIcon
                        icon={showSublinks ? 'angle-down' : 'angle-right'}
                        style={{
                            color: 'white',
                        }}
                    />
                </div>
            </div>
            {showSublinks &&
                links.map((link) => {
                    return (
                        <div key={link.name}>
                            <a
                                href={link.url}
                                className={childLink}
                                target="_blank"
                                download
                                rel="noreferrer noopener"
                            >
                                {link.name}
                            </a>
                        </div>
                    )
                })}
        </div>
    )
}

HelpAccordion.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string,
        }),
    ).isRequired,
}
