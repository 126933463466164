import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getUserInfo} from '../../redux/Actions/LoginActionCreators'
import * as Sentry from '@sentry/browser'

const useUser = (fetch = false) => {
    const dispatch = useDispatch()
    const userData = useSelector(({user}) => user)
    const fetchuserData = async () => {
        try {
            dispatch(getUserInfo())
        } catch (error) {}
    }

    useEffect(() => {
        if (userData?.ProgramId) {
            Sentry.setUser({username: userData.UserName})
        }
        if (fetch) {
            fetchuserData()
        }
    }, [])

    return {userData, fetchuserData}
}

export default useUser
