import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import useUser from '../../../libs/hooks/useUser'
import ABAContainer from '../ABAComponents/ABAContainer/ABAContainer'
import styles from './Scorecard.module.css'
import ScorecardButton from './ScorecardButton'
import getScorecardValues from './ScorecardLogic'
import RTIDAPI from '../../../helpers/AxiosInstance'
import userEvent from '@testing-library/user-event'
import {LoadingOverlay} from 'aba-ui'

export default function Scorecard() {
    const {userData} = useUser()
    const [loading, setLoading] = useState(true)

    const {
        TotalTrainingRecordsInDraft,
        TotalTrainingRecordsInValidate,
        TotalEnrollmentFormsInDraft,
        TotalEnrollmentFormsInValidate,
        TotalITEInDraft,
        TotalITEInvoiced,
        TotalITEPaid,
        ProductId,
    } = useSelector(({scorecard}) => scorecard)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {buttonContainer} = styles
    const [eventDetails, setEventDetails] = useState({})

    const getEventDetails = async (programType, productId) => {
        try {
            return await RTIDAPI.get(`ite/eventDetail/${programType}/${productId}`, {})
        } catch (err) {
            alert(err)
        }
    }

    useEffect(() => {
        const fetchScorecardData = async () => {
            if (userData) {
                dispatch(getScorecardValues(userData.ProgramId))
            }
        }
        fetchScorecardData()
    }, [userData.ProgramId, dispatch])

    useEffect(() => {
        const fetchEventDetail = async () => {
            if (ProductId) {
                const eventDetail = await getEventDetails(userData.ProgramType, ProductId)
                setEventDetails(eventDetail.data)
            }
        }
        fetchEventDetail()
        setLoading(false)
    }, [ProductId])

    function navigateTo(labelText) {
        switch (labelText) {
            case 'training':
                navigate('/trainingReports')
                break
            case 'enrollment':
                navigate('/enrollmentForms')
                break
            case 'ite':
                navigate('/inTrainingExam')
                break
            default:
                navigate('/residentListing')
                break
        }
    }

    if (TotalTrainingRecordsInDraft === null) {
        return <div>Loading...</div>
    }
    if (userData && userData.IsCanadianProgram) {
        return null
    }
    return (
        <ABAContainer label="Scorecard">
            {loading && <LoadingOverlay title={loading} />}
            <div className={buttonContainer}>
                <ScorecardButton
                    label="Training Reports"
                    onClick={() => navigateTo('training')}
                    onKeyPress={() => navigateTo('training')}
                    tabIndex={0}
                    numberInDraft={TotalTrainingRecordsInDraft}
                    numberInValidate={TotalTrainingRecordsInValidate}
                />
                <ScorecardButton
                    label="Enrollment Forms"
                    onClick={() => navigateTo('enrollment')}
                    onKeyPress={() => navigateTo('enrollment')}
                    tabIndex={0}
                    numberInDraft={TotalEnrollmentFormsInDraft}
                    numberInValidate={TotalEnrollmentFormsInValidate}
                />
                <ScorecardButton
                    label="ITE"
                    onClick={() => navigateTo('ite')}
                    onKeyPress={() => navigateTo('ite')}
                    tabIndex={0}
                    numberInDraft={TotalITEInDraft}
                    iteInvoiced={TotalITEInvoiced}
                    itePaid={TotalITEPaid}
                    eventDetail={eventDetails}
                />
            </div>
        </ABAContainer>
    )
}
