import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import styles from './ABAActionPopup.module.css'
import {ColorEnums} from '../../../../helpers/GeneralEnums'

const ABAActionPopup = (props) => {
    const {actions, uniqueId} = props
    const [showPopup, setShowPopup] = useState(false)
    const {popup, ellipsis} = styles

    const determineRadius = (index, lastLink) => {
        if (lastLink === 1) {
            return '15%'
        }
        if (index === 0) {
            return '15% 15% 0% 0%'
        }

        if (index === lastLink - 1) {
            return '0% 0% 15% 15%'
        }
        return '0%'
    }

    return (
        <div
            onMouseEnter={() => setShowPopup(true)}
            onMouseLeave={() => setShowPopup(false)}
        >
            <div
                className={ellipsis}
                style={{backgroundColor: showPopup ? ColorEnums.backgroundGray : 'white'}}
            >
                <FontAwesomeIcon
                    color={ColorEnums.danube}
                    icon="ellipsis-v"
                    style={{verticalAlign: 'middle'}}
                />
            </div>
            {showPopup && actions.length > 0 && (
                <div
                    style={{
                        position: 'absolute',
                        marginTop: '-37px',
                        marginLeft: '-45px',
                        border: `1px solid ${ColorEnums.cloudBurst}`,
                        borderRadius: '10%',
                        backgroundColor: 'white',
                    }}
                >
                    {actions.map((action, index) => {
                        return (
                            <div
                                key={`${uniqueId}-${action.name}`}
                                onClick={action.onClick}
                                onKeyDown={action.onClick}
                                className={popup}
                                role="button"
                                tabIndex={0}
                                style={{
                                    borderRadius: determineRadius(index, actions.length),
                                }}
                            >
                                <div
                                    style={{
                                        textDecoration: 'none',
                                        color: ColorEnums.danube,
                                        display: 'inline-block',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    {action.name}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

ABAActionPopup.defaultProps = {
    actions: [],
    uniqueId: '',
}

ABAActionPopup.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.object),
    uniqueId: PropTypes.string,
}
export default ABAActionPopup
