import React from 'react'
import PropTypes from 'prop-types'
import {ColorEnums} from '../../../helpers/GeneralEnums'

export default function ABARequiredField(props) {
    const {text} = props
    return (
        <div
            style={{
                color: ColorEnums.pomegranateDark,
                marginTop: 10,
                fontSize: 12,
            }}
        >
            * {text}
        </div>
    )
}
ABARequiredField.defaultProps = {
    text: 'Required Field',
}

ABARequiredField.propTypes = {
    text: PropTypes.string,
}
