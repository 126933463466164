import React from 'react'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/es/integration/react'
import {library} from '@fortawesome/fontawesome-svg-core'
import {ThemeProvider, ABABlue} from 'aba-ui'
import {
    faAngleLeft,
    faAngleRight,
    faAngleDown,
    faEdit,
    faEllipsisV,
    faLock,
    faSearch,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faTimes,
    faUserCircle,
} from '@fortawesome/free-solid-svg-icons'
import {BrowserRouter as Router} from 'react-router-dom'
import './App.css'
import axios from 'axios'
import Main from './main'
import ConfigureStore from './redux/configuration/configureStore'
import ErrorBoundary from './ErrorBoundary'

// Any time we want to add an icon from the font awesome library, we just import it from @fortawesome/free-solid-svg-icons and then add it as a parameter to library.add().
// This is an explicit import that allows us to not have to import icons over and over again in multiple files.
// More info on this can be found at:  https://github.com/FortAwesome/react-fontawesome#explicit-import

library.add(
    faAngleLeft,
    faAngleRight,
    faAngleDown,
    faEdit,
    faEllipsisV,
    faLock,
    faSearch,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faTimes,
    faUserCircle,
)

const {persistor, store} = ConfigureStore()

const App = () => {
    axios.defaults.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
    }
    axios.defaults.withCredentials = true

    return (
        <ErrorBoundary>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <ThemeProvider theme={ABABlue}>
                        <Router>
                            <Main />
                        </Router>
                    </ThemeProvider>
                </PersistGate>
            </Provider>
        </ErrorBoundary>
    )
}

export default App
