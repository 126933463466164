import * as ActionTypes from './ActionTypes'

export const TrainingRecordsSuccess = (trainingRecords) => {
    return {
        type: ActionTypes.TRAININGRECORDS_SUCCESS,
        payload: trainingRecords,
    }
}

export const TrainingRecordsFailed = (errorMessage) => {
    return {
        type: ActionTypes.TRAININGRECORDS_FAILED,
        payload: {errorMessage},
    }
}

export const LoadTrainingRecords = (loading = true) => {
    return {
        type: ActionTypes.TRAININGRECORDS_LOAD,
        payload: loading,
    }
}

export const UpdateTrainingRecord = (trainingRecord) => {
    return {
        type: ActionTypes.TRAININGRECORDS_UPDATE,
        payload: trainingRecord,
    }
}
