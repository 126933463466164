import React from 'react'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import {ColorEnums} from '../../../helpers/GeneralEnums'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default function ReportList({reports, onOpen, width = '700px'}) {
    const bodyCellStyle = {
        color: ColorEnums.cloudBurst,
        fontFamily: 'lato',
    }

    return (
        <>
            <Table sx={{margin: 'auto'}} style={{marginLeft: 'auto', marginRight: 'auto', width}}>
                <colgroup>
                    <col />
                    <col style={{width: '40px'}} />
                </colgroup>
                {reports?.length ? (
                    <TableBody>
                        {reports.map((report) => {
                            return (
                                <TableRow
                                    key={`${report.DocumentID}-${report.DisplayDate}`}
                                    onClick={() => {
                                        onOpen(report)
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    hover
                                >
                                    <TableCell style={bodyCellStyle}>{report.DisplayName}</TableCell>
                                    <TableCell style={bodyCellStyle}>
                                        <FontAwesomeIcon
                                            icon={'angle-right'}
                                            style={{
                                                color: ColorEnums.danube,
                                                marginLeft: 10,
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                ) : (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={2} style={{textAlign: 'center', ...bodyCellStyle}}>
                                No records to display
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
        </>
    )
}
