import React, {useState, useEffect, useRef} from 'react'
import {useLocation } from 'react-router-dom'
import {useSelector} from 'react-redux'
import RTIDAPI from '../../../helpers/AxiosInstance'
import ABAContainer from '../ABAComponents/ABAContainer/ABAContainer'
import ABATable from '../ABAComponents/ABATable/ABATable'
import {TextInput, Dropdown, Button, LoadingOverlay} from 'aba-ui'
import HelperMethods from '../../../helpers/HelperMethods'
import ValidationHelpers from '../../../helpers/ValidationHelpers'
import useOptionSets from '../../../libs/hooks/useOptionSets'
import {GeneralEnums, ColorEnums} from '../../../helpers/GeneralEnums'
import styles from './Training.module.css'
import useTraining from '../../../libs/hooks/useTraining'
import useUser from '../../../libs/hooks/useUser'

const TrainingRotation = (props) => {
    const {
        training_mainContainer,
        training_subContainer,
        training_inputRow,
        training_inputContainer,
    } = styles

    const location = useLocation ()
    const optionSets = useOptionSets()
    const [rotationStartDate, setRotationStartDate] = useState('')
    const [rotationEndDate, setRotationEndDate] = useState('')
    const [editId, setEditId] = useState(null)
    const [trainingType, setTrainingType] = useState('')
    const [rotationType, setRotationType] = useState('')
    const [loaRelatedEssential, setLOARelatedEssential] = useState('')
    const [rotationRecords, setRotationRecords] = useState([])
    const [trainingRotationTypes, setTrainingRotationTypes] = useState([])
    const [filteredTrainingTypes, setFilteredTrainingTypes] = useState([])
    const [filteredRotationTypes, setFilteredRotationTypes] = useState([])
    const [rotationInputStatus, setRotationInputStatus] = useState({
        rotationStartDate: GeneralEnums.textInputStatus.default,
        rotationEndDate: GeneralEnums.textInputStatus.default,
        trainingType: GeneralEnums.textInputStatus.default,
        rotationType: GeneralEnums.textInputStatus.default,
        loaRelatedEssential: GeneralEnums.textInputStatus.default,
    })
    const [loading, setLoading] = useState(false)
    const {resident, trainingId, priorTraining, trainingRecord} = props
    const {userData} = useUser()
    const {submitTrainingRotation, getTrainingRotations, deleteTrainingRotation} =
        useTraining()

    const trainingIdSearch = trainingId || location.state.trainingId
    let trainingData = useSelector(({trainingRecords}) =>
        trainingRecords.TrainingRecords.find(
            (trainingRecord) => trainingRecord.TrainingId === trainingIdSearch,
        ),
    )

    let title
    if (!trainingData?.TrainingId) {
        // No existing training record found in redux
        title = `ADD NEW TRAINING ROTATION ${'\u00a0\u00a0'} (${HelperMethods.formatDate(
            trainingRecord.StartDate,
        )} - ${HelperMethods.formatDate(trainingRecord.EndDate)})`
    } else if (!trainingRecord?.StartDate) {
        // Training record found but no data passed as prop
        title = `${
            trainingData.TrainingPeriod
        } Training Rotation ${'\u00a0\u00a0'} (${HelperMethods.formatDate(
            trainingData.StartDate,
        )} - ${HelperMethods.formatDate(trainingData.EndDate)})`
    } else {
        // Training data passed as prop
        title = `${
            trainingRecord.TrainingPeriod
        } Training Rotation ${'\u00a0\u00a0'} (${HelperMethods.formatDate(
            trainingRecord.StartDate,
        )} - ${HelperMethods.formatDate(trainingRecord.EndDate)})`
    }

    const editRotation = (rotationId) => {
        const rotation = rotationRecords.find(
            (rotationRecord) => rotationRecord.RotationId === rotationId,
        )

        setEditId(rotationId)
        setRotationStartDate(rotation.BeginDate)
        setRotationEndDate(rotation.EndDate)
        setTrainingType(rotation.TrainingType)

        //we need to repopulate the filtered rotation types collection when editing in order to select the appropriate rotation type
        const rotationTypes = trainingRotationTypes.filter(
            (data) => data.TrainingTypeValue === +rotation.TrainingType,
        )
        setFilteredRotationTypes(rotationTypes)
        setRotationType(rotation.RotationType)
        setLOARelatedEssential(rotation.LOARelatedToEssentialAttribute?.toString())
    }

    const deleteRotation = async (rotationId) => {
        try {
            setLoading(true)
            await deleteTrainingRotation(rotationId).then(() => {
                handleRemove(rotationId)
            })
        } finally {
            setLoading(false)
        }
    }

    const handleRemove = async (editId) => {
        setRotationRecords(rotationRecords.filter((data) => data.RotationId !== editId))
        let theTrainingId = null
        if (trainingData?.TrainingId) {
            theTrainingId = trainingData.TrainingId
        } else if (location.state?.trainingId) {
            theTrainingId = location.state.trainingId
        } else {
            theTrainingId = props.trainingId
        }

        await getTrainingRotations(theTrainingId).then((rotations) => {
            setRotationRecords(rotations.data)
        })
    }

    const saveButtonDisabled = () => {
        const months = HelperMethods.numberOfMonthsBetweenDates(
            rotationStartDate,
            rotationEndDate,
        )

        if (editId !== null) {
            if (months <= 6 && priorTraining !== '0') {
                return false
            } else if (months >= 6 && priorTraining !== '0') {
                return true
            }
        } else {
            if (
                priorTraining === '0' && //current period
                (months >= 6 || rotationRecords[0]?.TotalMonthsTrained >= 6)
            ) {
                return true
            }
        }

        return false
    }
    const displayLOAEssentialDropdown = () => {
        if (trainingType === '4616' || trainingType === 4616) {
            //leave of absence
            return (
                <div className={training_inputRow} style={{marginTop: '1px'}}>
                    <div className={training_inputContainer}>
                        <Dropdown
                            placeholder="LOA Related to Essential Attribute"
                            options={optionSets.loaRelatedEssential.map((loaRelated) => {
                                return {
                                    value: loaRelated.Value,
                                    name: loaRelated.Label,
                                }
                            })}
                            onChange={(value) => {
                                setLOARelatedEssential(value)
                            }}
                            dropdownStatus={rotationInputStatus.loaRelatedEssential}
                            selectedValue={loaRelatedEssential}
                            errorMessage={'Please select a value'}
                            required
                        />
                    </div>
                </div>
            )
        }
    }

    const validateTrainingRotation = () => {
        let status = {...rotationInputStatus}
        Object.keys(status).forEach(
            (value) => (status[value] = GeneralEnums.textInputStatus.default),
        )

        if (!rotationStartDate || !ValidationHelpers.validateDate(rotationStartDate)) {
            status.rotationStartDate = GeneralEnums.textInputStatus.error
        }

        if (!rotationEndDate || !ValidationHelpers.validateDate(rotationEndDate)) {
            status.rotationEndDate = GeneralEnums.textInputStatus.error
        }

        if (!trainingType) {
            status.trainingType = GeneralEnums.textInputStatus.error
        }

        if (!rotationType && filteredRotationTypes.length >= 2) {
            status.rotationType = GeneralEnums.textInputStatus.error
        }

        //leave of absence
        if (trainingType === '4616' && loaRelatedEssential === '') {
            status.loaRelatedEssential = GeneralEnums.textInputStatus.error
        }

        setRotationInputStatus(status)

        if (Object.values(status).includes(GeneralEnums.textInputStatus.error)) {
            return false
        } else {
            return true
        }
    }

    const getTrainingRotationTypes = (programType, trackType) => {
        return new Promise((resolve) => {
            RTIDAPI.get(`lookups/trainingRotationTypes/${programType}/${trackType}`, {})
                .then((response) => resolve(response.data))
                .catch((err) => err)
        })
    }

    const traningTypeOnChange = (value) => {
        setRotationType('')
        setTrainingType(value)
        const rotationTypes = trainingRotationTypes.filter(
            (data) => data.TrainingTypeValue === +value,
        )
        setFilteredRotationTypes(rotationTypes)

        //Clinical Anesthesia
        if (value.toString() === '4610') {
            //Default rotation type to Anesthesia
            const anesthesia = rotationTypes.find(
                (rotationType) => rotationType.RotationTypeValue.toString() === '1',
            )
            setRotationType(anesthesia.RotationTypeValue)
        }
    }

    useEffect(() => {
        const getData = async () => {
            let theTrainingId = null
            if (trainingData?.TrainingId) {
                theTrainingId = trainingData.TrainingId
            } else if (location.state?.trainingId) {
                theTrainingId = location.state.trainingId
            } else {
                theTrainingId = props.trainingId
            }

            const trainingRotations = await getTrainingRotations(theTrainingId)
            setRotationRecords(trainingRotations.data)

            const trainingRotationTypes = await getTrainingRotationTypes(
                resident.ProgramType,
                resident.TrackType,
            )
            setTrainingRotationTypes(trainingRotationTypes)
            const trainingTypes = [
                ...new Map(
                    trainingRotationTypes.map((item) => [
                        item['TrainingTypeValue'],
                        item,
                    ]),
                ).values(),
            ]

            setFilteredTrainingTypes(trainingTypes)
        }
        getData()
        setLoading(false)
    }, [])

    return (
        <ABAContainer
            label={title}
            loading={false}
            residentName={resident.ResidentDisplayName}
        >
            {loading && <LoadingOverlay title="Loading..." />}
            <div className={training_mainContainer}>
                <div className={training_subContainer}></div>
                <div>
                    <div className={training_inputRow} style={{marginTop: '50px'}}>
                        <div className={training_inputContainer}>
                            <Dropdown
                                placeholder="Training Type "
                                options={filteredTrainingTypes.map((trainingTypes) => {
                                    return {
                                        value: trainingTypes.TrainingTypeValue,
                                        name: trainingTypes.TrainingTypeLabel,
                                    }
                                })}
                                onChange={(value) => {
                                    traningTypeOnChange(value)
                                    setLOARelatedEssential('')
                                }}
                                dropdownStatus={rotationInputStatus.trainingType}
                                selectedValue={trainingType}
                                errorMessage={'Please select a value.'}
                                required
                            />
                        </div>
                        <div className={training_inputContainer}>
                            <Dropdown
                                placeholder="Rotation Type "
                                options={filteredRotationTypes.map((rotationTypes) => {
                                    return {
                                        value: rotationTypes.RotationTypeValue,
                                        name: rotationTypes.RotationTypeLabel,
                                    }
                                })}
                                onChange={(value) => setRotationType(value)}
                                dropdownStatus={rotationInputStatus.rotationType}
                                selectedValue={rotationType}
                                errorMessage={'Please select a value.'}
                                required={
                                    filteredRotationTypes &&
                                    filteredRotationTypes.length > 0
                                        ? 1
                                        : 0
                                }
                            />
                        </div>
                    </div>
                    {/* leave of absense */}
                    {displayLOAEssentialDropdown()}
                    <div className={training_inputRow} style={{marginTop: '50px'}}>
                        <div className={training_inputContainer}>
                            <TextInput
                                name="startDate"
                                required
                                value={rotationStartDate}
                                label="Start Date"
                                onChange={(event) => {
                                    setRotationStartDate(
                                        HelperMethods.dateInputMask(
                                            event.target.value,
                                            rotationStartDate,
                                        ),
                                        saveButtonDisabled(),
                                    )
                                }}
                                textInputStatus={rotationInputStatus.rotationStartDate}
                                maxLength={10}
                                placeholder="Start Date (mm/dd/yyyy)"
                                errorMessage={'Please enter a valid date'}
                            />
                        </div>
                        <div className={training_inputContainer}>
                            <TextInput
                                name="endDate"
                                required
                                value={rotationEndDate}
                                label="End Date"
                                onChange={(event) => {
                                    setRotationEndDate(
                                        HelperMethods.dateInputMask(
                                            event.target.value,
                                            rotationEndDate,
                                        ),
                                        saveButtonDisabled(),
                                    )
                                }}
                                textInputStatus={rotationInputStatus.rotationEndDate}
                                maxLength={10}
                                placeholder="End Date (mm/dd/yyyy)"
                                errorMessage={'Please enter a valid date'}
                            />
                        </div>
                    </div>
                    <Button
                        text="Save Rotation"
                        disabled={saveButtonDisabled()}
                        onClick={async () => {
                            const rotationInfo = {
                                RotationId: editId ? editId : null,
                                TrainingId:
                                    trainingData && trainingData.TrainingId
                                        ? trainingData.TrainingId
                                        : props.trainingId,
                                TrainingPeriodId: userData.TrainingPeriodId,
                                BeginDate: rotationStartDate,
                                EndDate: rotationEndDate,
                                TrainingType: trainingType,
                                RotationType: rotationType,
                                LOARelatedToEssentialAttribute: loaRelatedEssential,
                            }
                            setLoading(true)

                            try {
                                if (validateTrainingRotation()) {
                                    const submittedRecord = await submitTrainingRotation(
                                        rotationInfo,
                                    )
                                    setRotationRecords(submittedRecord.data)
                                    setRotationEndDate('')
                                    setRotationStartDate('')
                                    setTrainingType('')
                                    setRotationType('')
                                    setLOARelatedEssential('')
                                    setEditId(null)
                                }
                            } catch (err) {
                                alert(err.response.data.ExceptionMessage)
                                setRotationEndDate('')
                                setRotationStartDate('')
                            } finally {
                                setLoading(false)
                            }
                        }}
                        style={{
                            marginTop: 0,
                            marginLeft: '37.5%',
                            marginBottom: '13vh',
                            marginTop: 40,
                            width: '20vw',
                            color: saveButtonDisabled() ? ColorEnums.darkGray : 'white',
                            backgroundColor: saveButtonDisabled()
                                ? ColorEnums.backgroundGray
                                : '#418fde',
                            borderRadius: '20px',
                        }}
                    >
                        Save / Add New Rotation
                    </Button>
                </div>
                <div
                    style={{
                        marginBottom: 100,
                    }}
                >
                    <ABATable
                        data={rotationRecords}
                        editRotation={editRotation}
                        setEditId={setEditId}
                        deleteRotation={deleteRotation}
                        noDataMessage="No Rotations to display"
                        header={[
                            {
                                name: 'Start Date',
                                prop: 'BeginDate',
                            },
                            {
                                name: 'End Date',
                                prop: 'EndDate',
                            },
                            {
                                name: 'Months',
                                prop: 'Months',
                            },
                            {
                                name: 'Training Type',
                                prop: 'TrainingTypeName',
                            },
                            {
                                name: 'Rotation Type',
                                prop: 'RotationTypeName',
                            },
                            {
                                name: 'Action',
                                prop: 'action',
                            },
                        ]}
                    />
                </div>
            </div>
        </ABAContainer>
    )
}

export default TrainingRotation
