import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router'
import RTIDAPI, {setProgramId} from '../../helpers/AxiosInstance'
import {isCredentialsFormatValid} from '../../helpers/HelperMethods'
import {
    LoginBegin,
    LoginFailed,
    LoginSuccess,
    ResetRedux,
} from '../../redux/Actions/LoginActionCreators'

const useAuthentication = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const login = async (username, password) => {
        dispatch(LoginBegin())
        if (!isCredentialsFormatValid(username, password)) {
            dispatch(LoginFailed('Valid user ID /password is required.'))
            return
        }

        try {
            const apiResponse = await RTIDAPI.post(`authentication/authenticate`, {
                UserName: username,
                Password: password,
            })
            dispatch(LoginSuccess(apiResponse.data))
            if (apiResponse.data.ProgramId) {
                setProgramId(apiResponse.data.ProgramId)
                if (apiResponse.data.IsCanadianProgram === true) {
                    navigate('/inTrainingExam')
                } else {
                    navigate('/residentListing')
                }
            }
        } catch (error) {
            dispatch(LoginFailed(error.response?.data?.Message || 'An error occurred'))
        }
    }

    const logout = async (message) => {
        try {
            if (message) {
                dispatch(LoginFailed(message))
            }
            RTIDAPI.post(`authentication/logout`)
        } catch (error) {
            console.log('error logging out', error)
        } finally {
            dispatch(ResetRedux())
            navigate('/login')
        }
    }
    return {login, logout}
}

export default useAuthentication
