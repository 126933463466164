import * as ActionTypes from '../Actions/ActionTypes'

const defaultState = {
    TotalTrainingRecordsInDraft: null,
    TotalTrainingRecordsInValidate: null,
    TotalEnrollmentFormsInDraft: null,
    TotalEnrollmentFormsInValidate: null,
    TotalITEInDraft: null,
    TotalITEInvoiced: null,
    TotalITEPaid: null,
    ProductId: null,
    ErrorMessage: null,
}

const Scorecard = (state = defaultState, {type, payload}) => {
    switch (type) {
        case ActionTypes.SCORECARD_SUCCESS:
            return {
                ...state,
                ...payload,
                ErrorMessage: null,
            }
        case ActionTypes.SCORECARD_FAILED:
            return {
                ...defaultState,
                ErrorMessage: payload.ErrorMessage,
            }
        case ActionTypes.RESET_REDUX:
            return defaultState
        default:
            return state
    }
}
export default Scorecard
