import React from 'react'
import PropTypes from 'prop-types'
import styles from './ABAContainer.module.css'

const ABAContainer = (props) => {
    const {label, children, width, textAlign = 'center', loading, residentName} = props

    const {container, containerHeader} = styles

    return (
        <div
            className={container}
            width={width}
            style={{backgroundColor: 'white', position: 'relative'}}
        >
            <div
                className={containerHeader}
                style={{
                    display: 'flex',
                    textAlign,
                    width: '100%',
                    position: 'relative',
                }}
            >
                <div style={{left: 10, position: 'absolute'}}>{residentName}</div>
                <div style={{textAlign: 'center', width: '100%'}}>{label}</div>
            </div>
            {children}
            {loading && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        top: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: '#F5FCFF88',
                    }}
                />
            )}
        </div>
    )
}

ABAContainer.defaultProps = {
    residentName: '',
    label: '',
    width: '100%',
    textAlign: 'left',
    loading: false,
}
ABAContainer.propTypes = {
    residentName: PropTypes.string,
    label: PropTypes.string,
    width: PropTypes.string,
    children: PropTypes.node.isRequired,
    textAlign: PropTypes.string,
    loading: PropTypes.bool,
}
export default ABAContainer
