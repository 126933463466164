import {LoginPage} from 'aba-ui'
import React, {useEffect} from 'react'
import {LoadingOverlay} from 'aba-ui'
import RTIDLogo from '../../../images/ABA-RTID-Logo-White-transparent.png'
import useLogin from './Login.hooks'
import {useSelector, useDispatch} from 'react-redux'
import {LoginFailed} from '../../../redux/Actions/LoginActionCreators'
import RTIDAPI from '../../../helpers/AxiosInstance'

const Login = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        const maintenanceCheck = async () => {
            try {
                await RTIDAPI.get('authentication/maintenanceStatus')
            } catch (error) {
                dispatch(LoginFailed(error.response?.data?.Message || 'Connection Error'))
            }
        }
        maintenanceCheck()
    }, [])
    const loginProps = useLogin()
    const {loading} = useSelector((state) => state.global)
    return (
        <>
            <LoginPage
                {...loginProps}
                titleText="Login"
                logoSrc={RTIDLogo}
                logoWidth="300px"
                usernameLabel="User ID"
            />
            {loading && <LoadingOverlay title="Logging in" />}
        </>
    )
}

export default Login
