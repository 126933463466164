import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import PropTypes from 'prop-types'
import styles from './ResidentListing.module.css'

const ResidentListingDetailsHeader = (props) => {
    const {label, linkName, linkUrl, children, residentEnrollmentId} = props
    const {detailsHeader, detailsHeaderContainer} = styles

    const navigate = useNavigate()

    const handleClick = () => {
        // Step 4: Navigate when the button is clicked
        if (residentEnrollmentId) {
            navigate(linkUrl, {state: {residentEnrollmentId}})
        } else {
            navigate(linkUrl)
        }
    }

    return (
        <div>
            <div className={detailsHeaderContainer}>
                <div className={detailsHeader} style={{textAlign: 'left'}}>
                    {label}
                </div>
                <div className={detailsHeader} style={{textAlign: 'right'}}>
                    {linkName && linkUrl && (
                        <button
                            onClick={handleClick} // Step 3: Call handleClick on button click
                            style={{
                                color: 'white',
                                textDecoration: 'none',
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            {linkName}
                        </button>
                    )}
                </div>
            </div>
            {children}
        </div>
    )
}

ResidentListingDetailsHeader.defaultProps = {
    label: '',
    linkName: '',
    linkUrl: '/',
}
ResidentListingDetailsHeader.propTypes = {
    label: PropTypes.string,
    linkName: PropTypes.string,
    linkUrl: PropTypes.string,
    children: PropTypes.node.isRequired,
    residentEnrollmentId: PropTypes.string,
}
export default ResidentListingDetailsHeader
