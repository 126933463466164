// eslint-disable-next-line
/* eslint-disable react/jsx-indent */
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import {useSelector} from 'react-redux'
import {GeneralEnums} from '../../../helpers/GeneralEnums'
import ABAContainer from '../ABAComponents/ABAContainer/ABAContainer'
import {TextInput, Button, Dropdown, LoadingOverlay} from 'aba-ui'
import styles from './Training.module.css'
import useOptionSets from '../../../libs/hooks/useOptionSets'
import HelperMethods from '../../../helpers/HelperMethods'
import useUser from '../../../libs/hooks/useUser'
import useTraining from '../../../libs/hooks/useTraining'
import * as Sentry from '@sentry/react'

const TrainingRecord = (props) => {
    const {training_mainContainer, training_subContainer, training_inputRow, training_inputContainer} =
        styles

    const navigate = useNavigate()
    const [checkRotation, setCheckRotation] = useState('')
    const optionSets = useOptionSets()
    const {userData} = useUser()
    const {
        resident,
        trainingId,
        validateTrainingRecord,
        startDate,
        endDate,
        willContinueTraining,
        priorTraining,
        setStartDate,
        setEndDate,
        setWillContinueTraining,
        setPriorTraining,
        inputStatus,
        startDateErr,
        endDateErr,
        priorCBProgramCity,
        priorCBProgramDirector,
        priorCBProgramName,
        priorCBProgramState,
        priorCBProgramType,
        setPriorCBProgramCity,
        setPriorCBProgramDirector,
        setPriorCBProgramName,
        setPriorCBProgramState,
        setPriorCBProgramType,
    } = props
    const [trackType, setTrackType] = useState(resident.TrackType)
    const trainingData = useSelector(({trainingRecords}) =>
        trainingRecords.TrainingRecords.find(
            (trainingRecord) => trainingRecord.TrainingId === trainingId,
        ),
    )
    const {submitAutoCompleteTraining, checkForExistingRotationRecords} = useTraining()

    useEffect(() => {
        const getData = async () => {
            if (trainingId) {
                const rotationCheck = await checkForExistingRotationRecords(trainingId)
                setCheckRotation(rotationCheck)
            }
        }
        getData()
    }, [])

    const title =
        trainingData && trainingData.TrainingId
            ? trainingData.TrainingPeriod + ' Training Record'
            : 'ADD NEW TRAINING RECORD'

    const [loading, setLoading] = useState(false)
    const {Validated} = GeneralEnums.trainingStatusCodes

    const submitAutoCompleteTrainingHandler = () => {
        let trainingPeriod = null
        let programPeriod = null

        if (trainingData) {
            trainingPeriod = trainingData.TrainingPeriodId
            programPeriod = trainingData.ProgramPeriodId
        } else {
            trainingPeriod = userData.TrainingPeriodId
            programPeriod = userData.ProgramPeriodId
        }

        const trainingInfo = {
            TrainingId: trainingData && trainingData.TrainingId ? trainingData.TrainingId : null,
            ContactId: resident.ContactId,
            ResidentName: `${resident.LastName}, ${resident.FirstName}`,
            ProgramId: userData.ProgramId,
            ProgramPeriodId: programPeriod,
            TrainingPeriodId: trainingPeriod,
            ResidentEnrollmentId: resident.ResidentEnrollmentId,
            ProgramType: userData.ProgramType,
            TrackType: resident.TrackType,
            StartDate: startDate,
            EndDate: endDate,
            WillContinue: willContinueTraining,
            PriorTraining: priorTraining,
            ProgramRoleId: userData.ProgramRoleId,
            PDIRId: null,
            ResidentTrainingStartDate: resident.TrainingStartDate,
            TrainingPeriodEndDate: trainingData.TrainingPeriodEndDate,
        }
        setLoading(true)
        try {
            submitAutoCompleteTraining(trainingInfo).then(() => {
                setLoading(false)
                navigate('/residentListing', {
                    state: {
                        residentEnrollmentId: resident.ResidentEnrollmentId,
                    },
                })
            })
        } catch (err) {
            Sentry.captureException(err)
            alert(
                'An error occurred while running the auto complete training process.  Please contact the ABA.',
            )
        }
    }
    const renderPriorCBTrainingInputs = () => {
        if (priorTraining === '1') {
            return (
                <div>
                    <h5 className={training_inputRow} style={{marginBottom: 15}}>
                        Prior Clinical Base Training
                    </h5>

                    <div className={training_inputRow}>
                        <div className={training_inputContainer}>
                            <TextInput
                                name="programName"
                                required
                                value={priorCBProgramName}
                                label="Program Name"
                                onChange={(event) => {
                                    setPriorCBProgramName(event.target.value)
                                }}
                                textInputStatus={inputStatus.priorCBProgramName}
                                placeholder="Program Name"
                                maxLength={50}
                            />
                        </div>
                        <div className={training_inputContainer}>
                            <TextInput
                                name="programCity"
                                required
                                value={priorCBProgramCity}
                                label="Program City"
                                onChange={(event) => {
                                    setPriorCBProgramCity(event.target.value)
                                }}
                                textInputStatus={inputStatus.priorCBProgramCity}
                                placeholder="Program City"
                                maxLength={25}
                            />
                        </div>
                    </div>

                    <div className={training_inputRow}>
                        <div className={training_inputContainer}>
                            <TextInput
                                name="programState"
                                required
                                value={priorCBProgramState}
                                label="Program State"
                                onChange={(event) => {
                                    setPriorCBProgramState(event.target.value)
                                }}
                                textInputStatus={inputStatus.priorCBProgramState}
                                placeholder="Program State"
                                maxLength={15}
                            />
                        </div>
                        <div className={training_inputContainer}>
                            <TextInput
                                name="programDirector"
                                required
                                value={priorCBProgramDirector}
                                label="Program Director"
                                onChange={(event) => {
                                    setPriorCBProgramDirector(event.target.value)
                                }}
                                textInputStatus={inputStatus.priorCBProgramDirector}
                                placeholder="Program Director"
                                maxLength={30}
                            />
                        </div>
                    </div>
                    <div className={training_inputRow}>
                        <div className={training_inputContainer}>
                            <Dropdown
                                required
                                placeholder="Program Type"
                                onChange={(value) => {
                                    setPriorCBProgramType(value)
                                }}
                                options={optionSets.priorCBProgramTypes.map((programType) => {
                                    return {
                                        value: programType.Value,
                                        name: programType.Label,
                                    }
                                })}
                                dropdownStatus={inputStatus.priorCBProgramType}
                                errorMessage={'Please select a value'}
                                selectedValue={priorCBProgramType}
                            />
                        </div>
                    </div>
                </div>
            )
        }
    }
    return (
        <ABAContainer loading={loading} label={title} residentName={resident.ResidentDisplayName}>
            {loading && <LoadingOverlay title="Loading..." />}
            <div className={training_mainContainer}>
                <div className={training_subContainer} />
                <div>
                    <div className={training_inputRow} style={{marginTop: '50px'}}>
                        <div className={training_inputContainer}>
                            <TextInput
                                name="startDate"
                                required
                                value={startDate}
                                label="Start Date"
                                onChange={(event) => {
                                    setStartDate(
                                        HelperMethods.dateInputMask(event.target.value, startDate),
                                    )
                                }}
                                textInputStatus={inputStatus.startDate}
                                maxLength={10}
                                placeholder="Start Date (mm/dd/yyyy)"
                                errorMessage={startDateErr}
                            />
                        </div>
                        <div className={training_inputContainer}>
                            <TextInput
                                name="endDate"
                                onChange={(event) => {
                                    setEndDate(HelperMethods.dateInputMask(event.target.value, endDate))
                                }}
                                required
                                value={endDate}
                                label="End Date"
                                textInputStatus={inputStatus.endDate}
                                maxLength={10}
                                placeholder="End Date (mm/dd/yyyy)"
                                errorMessage={endDateErr}
                            />
                        </div>
                    </div>
                    <div className={training_inputRow}>
                        <div className={training_inputContainer}>
                            <Dropdown
                                required
                                placeholder="Will Continue Training "
                                onChange={(value) => {
                                    setWillContinueTraining(value)
                                }}
                                options={optionSets.willContinue.map((willContinue) => {
                                    return {
                                        value: willContinue.Value,
                                        name: willContinue.Label,
                                    }
                                })}
                                dropdownStatus={inputStatus.willContinueTraining}
                                errorMessage={'Please select a value'}
                                selectedValue={willContinueTraining}
                            />
                        </div>
                        <div className={training_inputContainer}>
                            <Dropdown
                                required
                                placeholder="Prior Training "
                                onChange={(value) => {
                                    setPriorTraining(value)
                                }}
                                options={optionSets.priorTrainings.map((priorTraining) => {
                                    return {
                                        value: priorTraining.Value,
                                        name: priorTraining.Label,
                                    }
                                })}
                                dropdownStatus={inputStatus.priorTraining}
                                errorMessage={'Please select a value'}
                                selectedValue={priorTraining}
                            />
                        </div>
                    </div>
                    <div style={{paddingBottom: '30px'}}>
                        {' '}
                        {priorTraining === '0' &&
                        (!trainingData ||
                            (trainingData.StatusCode != Validated &&
                                trackType == 1 &&
                                checkRotation == 0)) ? (
                            <Button
                                text="Auto-Complete"
                                style={{
                                    marginTop: 0,
                                    marginLeft: '55vw',
                                    width: '10vw',
                                    color: 'black',
                                    backgroundColor: 'orange',
                                    borderRadius: '20px',
                                    display: 'none',
                                }}
                                onClick={async () => {
                                    if (validateTrainingRecord()) {
                                        submitAutoCompleteTrainingHandler()
                                    }
                                }}
                            >
                                Auto Complete
                            </Button>
                        ) : null}
                    </div>
                    {renderPriorCBTrainingInputs()}
                </div>
            </div>
        </ABAContainer>
    )
}

export default TrainingRecord
