import React from 'react'
import PropTypes from 'prop-types'
import {Navigate, useParams} from 'react-router-dom'
import useUser from './libs/hooks/useUser'
import {setProgramHeaderKey} from './helpers/AxiosInstance'

function PrivateRoute({component: Component, ...rest}) {
    const {userData} = useUser(true)
    const params = useParams()
    const {program} = params

    if (program) {
        setProgramHeaderKey(program)
    } else if (userData.ProgramId) {
        setProgramHeaderKey(userData.UserName)
    }

    // Check if user is authenticated (based on your logic, e.g., checking if userData.ProgramId exists)
    if (!userData.ProgramId) {
        // User not authenticated, redirect to login
        return <Navigate to="/login" replace />
    }

    // User is authenticated, render the component passed to PrivateRoute
    // Spread the rest of the props to the Component
    return <Component {...rest} />
}

PrivateRoute.propTypes = {
    component: PropTypes.elementType.isRequired,
}

export default PrivateRoute
