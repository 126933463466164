import React, {useState} from 'react'
import {Button, Modal, StyleVariant} from 'aba-ui'
import styles from './TrainingAttestationModal.module.css'
import ABACheckbox from '../ABAComponents/ABACheckbox'
import RTIDAPI from '../../../helpers/AxiosInstance'
import {LoadingOverlay} from 'aba-ui'

const {modal, content, titleBlock, buttonContainer, paragraph, li} = styles

const TrainingAttestationModal = ({
    onCancel,
    nameDisplay,
    abaId,
    trainingPeriod,
    programType,
    trainingId,
}) => {
    const [attest, setAttest] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        if (attest !== true) {
            window.alert(
                'In order to submit, you must check the attestation checkbox. If you are not ready to attest, click "Cancel".',
            )
            return
        }

        try {
            setLoading('Loading...')
            await RTIDAPI.put('training/trainingAttestation', {
                TrainingId: trainingId,
                Attestation: true,
            })
            onCancel(false)
            window.location.reload()
        } catch (err) {
            console.error(err)
        }
    }
    const label = () => {
        if (programType === '513') {
            return 'I attest that the anesthesiology program faculty and/or staff have informed the resident about his/her unsatisfactory Clinical Competence Committee Report, and that the resident has reviewed this information.'
        } else {
            return 'I attest that the subspecialty program faculty and/or staff have informed the fellow about his/her unsatisfactory Clinical Competence Committee Report, and that the fellow has reviewed this information.'
        }
    }

    return (
        <Modal>
            {loading && <LoadingOverlay title={loading} />}
            <div className={modal}>
                <div className={content}>
                    <div className={titleBlock}>
                        <div
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            {nameDisplay}
                        </div>
                        <div
                            style={{
                                fontWeight: 'bold',
                                fontSize: '16px',
                            }}
                        >
                            {abaId}
                        </div>
                        <div
                            style={{
                                fontWeight: 'bold',
                                fontSize: '16px',
                            }}
                        >
                            {trainingPeriod}
                        </div>
                    </div>
                    <div style={{}}>
                        {programType === '513' ? (
                            <div>
                                <p className={paragraph}>
                                    The Clinical Competence Committee (CCC) Report has
                                    been submitted electronically for the above named
                                    physician. The resident received an overall clinical
                                    competence grade of unsatisfactory for the current
                                    report period. The ABA requires that the Program
                                    Director and/or the clinical competence committee
                                    chair and the above-named resident provide
                                    acknowledgement that the designated program faculty
                                    and the resident have reviewed this important
                                    information. Please note that a resident will have to
                                    extend his/her clinical anesthesia training beyond 36
                                    months in order to satisfy the requirements of the
                                    continuum of education in anesthesiology if:
                                    <ul>
                                        <li className={li}>
                                            The resident received a CCC Report that is not
                                            satisfactory for the most recent period of
                                            clinical anesthesia training preceding the
                                            current report period, OR:
                                        </li>
                                        <li className={li}>
                                            The resident receives a CCC Report that is not
                                            satisfactory for clinical anesthesia training
                                            during the next period of training, OR:
                                        </li>
                                        <li className={li}>
                                            The resident leaves the program without
                                            receiving a satisfactory CCC Report covering
                                            the next six months of clinical anesthesia
                                            training.
                                        </li>
                                    </ul>
                                    Our policy regarding creditable training is defined in
                                    our Policy Book, which is available on our website at
                                    https://theaba.org.
                                </p>
                            </div>
                        ) : (
                            <p className={paragraph}>
                                The Clinical Competence Committee (CCC) Report has been
                                submitted electronically for the above named physician.
                                The fellow received an overall clinical competence grade
                                of unsatisfactory for the current report period. The ABA
                                requires that the Program Director and the above-named
                                fellow provide acknowledgement that the designated program
                                faculty and the fellow have reviewed this important
                                information. Please note that a fellow will have to extend
                                his/her clinical anesthesia training beyond 12 months in
                                order to satisfy the requirements of the continuum of
                                education in anesthesiology if:
                                <ul>
                                    <li className={li}>
                                        The fellow received a CCC Report that is not
                                        satisfactory for the most recent period of
                                        clinical anesthesia training preceding the current
                                        report period, OR
                                    </li>
                                    <li className={li}>
                                        The fellow receives a CCC Report that is not
                                        satisfactory forclinical anesthesia training
                                        during the next period of training, OR
                                    </li>
                                    <li className={li}>
                                        The fellow leaves the program without receiving a
                                        satisfactory CCCReport covering the next six
                                        months of subspecialty training.
                                    </li>
                                </ul>
                                Our policy regarding creditable training is defined in our
                                Policy Book, which is available on our website at
                                https://theaba.org.
                            </p>
                        )}
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <ABACheckbox
                            isChecked={attest}
                            onChangeEvent={() => setAttest(!attest)}
                            label={label()}
                        />
                    </div>
                    <div className={buttonContainer}>
                        <div style={{marginRight: '25%'}}>
                            <Button
                                variant={StyleVariant.FILLED}
                                onClick={() => onCancel(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div style={{marginLeft: '25%'}}>
                            <Button variant={StyleVariant.FILLED} onClick={handleSubmit}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default TrainingAttestationModal
