import React, {useState, useEffect} from 'react'
import styles from '../styles/trainingFeedback.css'
import {Button, StyleVariant, LoadingOverlay} from 'aba-ui'
import HelperMethods from '../../helpers/HelperMethods'
import {useSelector} from 'react-redux'
import ABAContainer from '../components/ABAComponents/ABAContainer/ABAContainer'
import ABAHeader from '../components/ABAComponents/ABAHeader'
import {TextField} from '@mui/material'
import {useNavigate, useLocation} from 'react-router-dom'
import useUser from '../../libs/hooks/useUser'
import RTIDAPI from '../../helpers/AxiosInstance'
import {GeneralEnums} from '../../helpers/GeneralEnums'

const TrainingFeedback = (props) => {
    const residentData = useSelector(({residentListing}) => residentListing)
    const {userData} = useUser()
    const location = useLocation()
    const residentEnrollmentId = location.state?.residentEnrollmentId

    const resident = residentData.ResidentListing.find(
        (data) => data.ResidentEnrollmentId === residentEnrollmentId,
    )

    const navigate = useNavigate()
    const [text, setText] = useState('')
    const [errorText, setErrorText] = useState('')
    const [feedback, setFeedback] = useState([])
    const [loading, setLoading] = useState(true)

    const handleChange = (event) => {
        setText(event.target.value)
    }

    const getFeedback = (residentEnrollmentId) => {
        return new Promise((resolve) => {
            RTIDAPI.get(`training/getTrainingFeedback/${residentEnrollmentId}`, {})
                .then((response) => resolve(response.data))
                .catch((err) => err)
        })
    }

    const updateFeedback = (feedbackId) => {
        return new Promise((resolve) => {
            RTIDAPI.post(`training/updateTrainingFeedback/${feedbackId}`, {})
                .then((response) => resolve(response.data))
                .catch((err) => err)
        })
    }

    const submitFeedback = () => {
        return new Promise((resolve) => {
            RTIDAPI.post(`training/submitTrainingFeedback`, {
                TrainingProgramId: userData.ProgramId,
                ProgramContactId: userData.ProgramRoleId,
                ResidentEnrollmentId: residentEnrollmentId,
                ResidentOrDoctorId: resident.ContactId,
                TrainingProgramNumber: userData.ProgramNumber,
                ProgramType: userData.ProgramType.toString(),
                ABAId: resident.ABAID,
                DoctorName: resident?.ResidentDisplayName,
                FeedbackMessage: text,
            })
                .then((response) => resolve(response.data))
                .catch((err) => err)
        })
    }

    const renderFeedback = () => {
        if (feedback.length < 1) {
            return (
                <div className="row" style={{display: 'block', textAlign: 'center', paddingTop: '30px'}}>
                    No Feedback to Display
                </div>
            )
        }

        return feedback.map((fb) => {
            return (
                <div key={fb.FeedbackId}>
                    <div className="row">
                        <div className="cell">
                            <div>{fb.FeedbackMessage}</div>
                            <div className="date-submitted">
                                Submitted {HelperMethods.formatDate(fb.CreatedOn)}
                            </div>
                        </div>
                        <div className="cell" style={{paddingLeft: '10px'}}>
                            <div>{fb.Reply}</div>
                            <div className="date-submitted">
                                {fb.ReplyDate && `Replied ${HelperMethods.formatDate(fb.ReplyDate)}`}
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    useEffect(() => {
        const getData = async () => {
            const {Responded} = GeneralEnums.feedbackStatusCodes
            const feedbackData = await getFeedback(residentEnrollmentId)
            setFeedback(feedbackData)

            if (resident.FeedbackStatusCode === Responded) {
                await updateFeedback(resident.FeedbackId)
            }
            setLoading(false)
        }
        getData()
    }, [])

    return (
        <div className="container">
            {loading && <LoadingOverlay />}
            <ABAHeader text="Training Feedback" />
            <ABAContainer residentName={resident?.ResidentDisplayName}>
                <div className="sub_title">Existing Feedback</div>
                <div style={{marginLeft: '60px', display: 'flex'}}>
                    <div className="cellTitle">Feedback</div>
                    <div className="cellTitle">Response</div>
                </div>
                {renderFeedback()}
                <div className="sub_title" style={{paddingTop: '45px'}}>
                    Submit New Feedback
                </div>
                <div>
                    <div className="container">
                        <div
                            style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '700px',
                            }}
                        >
                            <TextField
                                value={text}
                                onChange={handleChange}
                                name="feedback"
                                multiline
                                helperText={errorText}
                                error={errorText ? true : false}
                                inputProps={{
                                    maxLength: 5000,
                                    style: {height: '3em'},
                                }}
                                style={{
                                    width: '100%',
                                }}
                                size="large"
                            />
                            <div style={{textAlign: 'right'}}>{text.length}/5000</div>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                margin: 10,
                            }}
                        >
                            <div style={{margin: 20, marginRight: 200}}>
                                <Button
                                    style={{width: 125}}
                                    variant={StyleVariant.OUTLINED}
                                    text="Cancel"
                                    onClick={() => {
                                        navigate('/residentListing', {})
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div style={{margin: 20}}>
                                <Button
                                    style={{width: 125}}
                                    variant={StyleVariant.FILLED}
                                    text="Submit"
                                    helperText="You must enter feedback to submit."
                                    onClick={() => {
                                        const submit = async () => {
                                            if (text.length > 0) {
                                                errorText && setErrorText('')
                                                setLoading(true)
                                                const newFeedback = await submitFeedback()
                                                setText('')
                                                setFeedback(newFeedback)
                                                setLoading(false)
                                            } else {
                                                setErrorText('You must enter feedback to submit.')
                                            }
                                        }

                                        submit()
                                    }}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </ABAContainer>
        </div>
    )
}

export default TrainingFeedback
