import * as ActionTypes from '../Actions/ActionTypes'

const defaultState = {
    windowIsFocused: true,
    errorMessage: null,
    loading: false,
}

export default global = (state = defaultState, {type, payload}) => {
    switch (type) {
        case ActionTypes.WINDOW_FOCUSED:
            return {
                ...state,
                windowIsFocused: payload,
            }
        case ActionTypes.LOGIN_FAILED:
            return {...defaultState, errorMessage: payload, loading: false}
        case ActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                errorMessage: null,
                loading: false,
            }
        case ActionTypes.LOGIN_BEGIN:
            return {
                ...state,
                errorMessage: null,
                loading: true,
            }
        default:
            return state
    }
}

export const windowIsFocusedSelector = (state) => state.global.windowIsFocused
