import React, {useState, useEffect} from 'react'
import styles from '../styles/trainingFeedback.css'
import {useSelector} from 'react-redux'
import ABAContainer from '../components/ABAComponents/ABAContainer/ABAContainer'
import {Button, StyleVariant} from 'aba-ui'
import {useNavigate, useLocation} from 'react-router-dom'
import useTraining from '../../libs/hooks/useTraining'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import HelperMethods from '../../helpers/HelperMethods'
import useOptionSets from '../../libs/hooks/useOptionSets'
import {ColorEnums, GeneralEnums} from '../../helpers/GeneralEnums'

const ViewCompletedTraining = (props) => {
    const location = useLocation()
    const residentEnrollmentId = location.state?.residentEnrollmentId
    const trainingId = location.state?.trainingId
    const previousResident = location.state?.previousResident
    const previousTrainingRecord = location.state?.previousTrainingRecord
    const {getTrainingRotations, getTrainingEssentials} = useTraining()
    const optionSets = useOptionSets()
    const navigate = useNavigate()

    let residentData, trainingData, resident
    if (!previousResident) {
        residentData = useSelector(({residentListing}) => residentListing)
    } else {
        residentData = [previousResident]
    }

    if (!previousTrainingRecord) {
        trainingData = useSelector(({trainingRecords}) =>
            trainingRecords.TrainingRecords.find(
                (trainingRecord) => trainingRecord.TrainingId === trainingId,
            ),
        )
    } else {
        trainingData = previousTrainingRecord.find(
            (trainingRecord) => trainingRecord.TrainingId === trainingId,
        )
    }

    const title =
        trainingData && trainingData.TrainingId
            ? trainingData.TrainingPeriod + ' Training Record'
            : 'ADD NEW TRAINING RECORD'

    if (previousResident) {
        resident = previousResident
    } else {
        resident = residentData.ResidentListing.find(
            (data) => data.ResidentEnrollmentId === residentEnrollmentId,
        )
    }

    const willContinue = optionSets.willContinue.find((os) => os.Value === trainingData?.WillContinue)

    const priorTraining = optionSets.priorTrainings.find(
        (os) => os.Value === trainingData?.PriorTraining,
    )

    const [rotationRecords, setRotationRecords] = useState([])
    const [trainingEssentials, setTrainingEssentials] = useState([])
    const displayTrainingStartDate = () => {
        if (
            willContinue?.Value == GeneralEnums.willContinueTrainingOptions.yes ||
            willContinue?.Value == GeneralEnums.willContinueTrainingOptions.leftProgram
        ) {
            if (trainingData.TrainingPeriodStartDate > resident.TrainingStartDate) {
                return HelperMethods.formatDate(trainingData.TrainingPeriodStartDate)
            } else {
                return HelperMethods.formatDate(resident.TrainingStartDate)
            }
        }
        return HelperMethods.formatDate(trainingData.TrainingPeriodStartDate)
    }
    const displayTrainingEndDate = () => {
        if (
            willContinue?.Value == GeneralEnums.willContinueTrainingOptions.noGraduation
        ) {
            if (trainingData.TrainingPeriodEndDate < resident.GraduationDate) {
                return HelperMethods.formatDate(trainingData.TrainingPeriodEndDate)
            } else {
                return HelperMethods.formatDate(resident.GraduationDate)
            }
        }
        return HelperMethods.formatDate(trainingData.TrainingPeriodEndDate)
    }
    useEffect(() => {
        const getData = async () => {
            const trainingRotations = await getTrainingRotations(trainingId)
            setRotationRecords(trainingRotations.data)
            const trainingEssentials = await getTrainingEssentials(trainingId)
            setTrainingEssentials(trainingEssentials.data)
        }
        getData()
    }, [])

    return (
        <div style={{margin: 50}} className="container">
            <ABAContainer label={title} residentName={resident?.ResidentDisplayName}>
                <div className="sub_title">Training</div>
                <div className="row">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    style={{
                                        color: ColorEnums.blueGray,
                                    }}
                                >
                                    Start Date
                                </TableCell>
                                <TableCell
                                    style={{
                                        color: ColorEnums.blueGray,
                                    }}
                                >
                                    End Date
                                </TableCell>
                                <TableCell
                                    style={{
                                        color: ColorEnums.blueGray,
                                    }}
                                >
                                    Will Continue
                                </TableCell>
                                <TableCell
                                    style={{
                                        color: ColorEnums.blueGray,
                                    }}
                                >
                                    Prior Training
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {HelperMethods.formatDate(trainingData?.StartDate)}
                                </TableCell>
                                <TableCell>{HelperMethods.formatDate(trainingData?.EndDate)}</TableCell>
                                <TableCell>{willContinue && willContinue.Label}</TableCell>
                                <TableCell>{priorTraining && priorTraining.Label}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
                <div className="sub_title">Rotations</div>
                <div className="row">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    style={{
                                        color: ColorEnums.blueGray,
                                    }}
                                >
                                    Start Date
                                </TableCell>
                                <TableCell
                                    style={{
                                        color: ColorEnums.blueGray,
                                    }}
                                >
                                    End Date
                                </TableCell>
                                <TableCell
                                    style={{
                                        color: ColorEnums.blueGray,
                                    }}
                                >
                                    Months
                                </TableCell>
                                <TableCell
                                    style={{
                                        color: ColorEnums.blueGray,
                                    }}
                                >
                                    Training Type
                                </TableCell>
                                <TableCell
                                    style={{
                                        color: ColorEnums.blueGray,
                                    }}
                                >
                                    Rotation Type
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {rotationRecords && rotationRecords.length > 0 ? (
                            <TableBody>
                                {rotationRecords.map((rotationRecord) => (
                                    <TableRow key={rotationRecord.rotations}>
                                        <TableCell>{rotationRecord.BeginDate}</TableCell>
                                        <TableCell>{rotationRecord.EndDate}</TableCell>
                                        <TableCell>{rotationRecord.Months}</TableCell>
                                        <TableCell>{rotationRecord.TrainingTypeName}</TableCell>
                                        <TableCell>{rotationRecord.RotationTypeName}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan="6" style={{textAlign: 'center'}}>
                                        There are no Rotations to display
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </div>
                <div className="sub_title">Clinical Competence Attributes</div>
                <div className="row">
                    <Table>
                        <TableHead className="titleRow">
                            <TableRow>
                                <TableCell
                                    style={{
                                        color: ColorEnums.blueGray,
                                    }}
                                >
                                    Overall Result
                                </TableCell>
                                <TableCell
                                    style={{
                                        color: ColorEnums.blueGray,
                                    }}
                                >
                                    Demo Moral
                                </TableCell>
                                <TableCell
                                    style={{
                                        color: ColorEnums.blueGray,
                                    }}
                                >
                                    Is Reliable
                                </TableCell>
                                <TableCell
                                    style={{
                                        color: ColorEnums.blueGray,
                                    }}
                                >
                                    Learns From Experience
                                </TableCell>
                                <TableCell
                                    style={{
                                        color: ColorEnums.blueGray,
                                    }}
                                >
                                    Reacts To Stress
                                </TableCell>
                                <TableCell
                                    style={{
                                        color: ColorEnums.blueGray,
                                    }}
                                >
                                    Substance Abuse
                                </TableCell>
                                <TableCell
                                    style={{
                                        color: ColorEnums.blueGray,
                                    }}
                                >
                                    Impairment
                                </TableCell>
                                <TableCell
                                    style={{
                                        color: ColorEnums.blueGray,
                                    }}
                                >
                                    Demonstrates Respect
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {trainingEssentials ? (
                            <TableBody>
                                <TableRow key={trainingData?.TrainingId}>
                                    <TableCell>{trainingEssentials.ResultLabel}</TableCell>
                                    <TableCell>{trainingEssentials.DemoMoralLabel}</TableCell>
                                    <TableCell>{trainingEssentials.ReliableLabel}</TableCell>
                                    <TableCell>{trainingEssentials.LearnsFromExperienceLabel}</TableCell>
                                    <TableCell>{trainingEssentials.ReactStressLabel}</TableCell>
                                    <TableCell>{trainingEssentials.SubstanceAbuseLabel}</TableCell>
                                    <TableCell>{trainingEssentials.ImpairmentLabel}</TableCell>
                                    <TableCell>{trainingEssentials.RespectLabel}</TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan="8" style={{textAlign: 'center'}}>
                                        There are no Essential Attributes for this Training Type
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10,
                    }}
                >
                    <Button
                        style={{width: 150, margin: 20}}
                        variant={StyleVariant.FILLED}
                        onClick={() => {
                            navigate('/residentListing', {
                                state: {residentEnrollmentId},
                            })
                        }}
                    >
                        Close
                    </Button>
                </div>
            </ABAContainer>
        </div>
    )
}
export default ViewCompletedTraining
