import React, {useState, useEffect} from 'react'
import useUser from '../../libs/hooks/useUser'
import ABAHeader from '../components/ABAComponents/ABAHeader'
import ABAContainer from '../components/ABAComponents/ABAContainer/ABAContainer'
import {Button, Dropdown, TextInput, StyleVariant} from 'aba-ui'
import RTIDAPI from '../../helpers/AxiosInstance'
import useOptionSets from '../../libs/hooks/useOptionSets'
import {useNavigate} from 'react-router-dom'
import {GeneralEnums} from '../../helpers/GeneralEnums'
import HelperMethods from '../../helpers/HelperMethods'
export default function ProgramInformation() {
    const navigate = useNavigate()
    const [RRCId, setRRCId] = useState('')
    const [Department, setDepartment] = useState('')
    const [lastRCCReview, setLastRCCReview] = useState('')
    const [nextRCCReview, setNextRCCReview] = useState('')
    const [numberOfAccResidents, setNumberOfAccResidents] = useState('')
    const [country, setCountry] = useState()
    const [addressState, setAddressState] = useState()
    const [streetName, setStreetName] = useState('')
    const [streetName2, setStreetName2] = useState('')
    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState()
    const [phone, setPhone] = useState('')
    const [fax, setFax] = useState('--')
    const {userData} = useUser()
    const optionSets = useOptionSets()
    const [CountryInputStatus, setCountryInputStatus] = useState({
        country: GeneralEnums.textInputStatus.default,
    })
    const [errorMessagePhoneNumber, setErrorMessagePhoneNumber] = useState('')
    const [inputStatusPhoneNumber, setInputStatusPhoneNumber] = useState(
        GeneralEnums.textInputStatus.default,
    )
    const [errorMessageFax, setErrorMessageFax] = useState('')
    const [inputStatusFax, setInputStatusFax] = useState(
        GeneralEnums.textInputStatus.default,
    )
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchAccountInformation()
    }, [])
    const fetchAccountInformation = async () => {
        setLoading('Loading program information')

        try {
            const response = await RTIDAPI.get(
                `account/getProgramInformation/${userData.AccountId}`,
            )
            const {
                RRCID,
                Department,
                Country,
                LastRRCReview,
                NextRRCReview,
                NumberOfResidents,
                Phone,
                City,
                AddressLine1,
                AddressLine2,
                PostalCode,
                State,
                Fax,
            } = response.data
            setRRCId(RRCID)
            setDepartment(Department)
            setLastRCCReview(LastRRCReview)
            setNextRCCReview(NextRRCReview)
            setNumberOfAccResidents(NumberOfResidents)
            setCountry(Country)
            setAddressState(State)
            setStreetName(AddressLine1)
            setStreetName2(AddressLine2)
            setCity(City)
            setPhone(Phone)
            setPostalCode(PostalCode)
            setFax(Fax)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError('An error occurred while fetching')
        }
    }
    const updateAddressInformations = async (programInformation) => {
        try {
            return await RTIDAPI.put(
                `account/UpdateAddressInformation`,
                programInformation,
            )
        } catch (err) {
            alert(error.response.data.ExceptionMessage)
        }
    }

    const cancel = () => {
        navigate('/residentListing')
    }
    const validatePhoneNumber = () => {
        let status = {...inputStatusPhoneNumber}
        Object.keys(status).forEach(
            (value) => (status[value] = GeneralEnums.textInputStatus.default),
        )
        if (phone && phone.length < 14) {
            setInputStatusPhoneNumber(GeneralEnums.textInputStatus.error)
            setErrorMessagePhoneNumber('Please enter a valid number (000) 000-0000')
            return false
        }
        if (inputStatusPhoneNumber === GeneralEnums.textInputStatus.error) {
            setInputStatusPhoneNumber(GeneralEnums.textInputStatus.default)
            setErrorMessagePhoneNumber('')
        }

        return true
    }

    const validateFax = () => {
        let status = {...inputStatusFax}
        Object.keys(status).forEach(
            (value) => (status[value] = GeneralEnums.textInputStatus.default),
        )
        if (fax && fax.length < 14) {
            setInputStatusFax(GeneralEnums.textInputStatus.error)
            setErrorMessageFax('Please enter a valid fax (000) 000-0000')
            return false
        }
        if (inputStatusFax === GeneralEnums.textInputStatus.error) {
            setInputStatusFax(GeneralEnums.textInputStatus.default)
            setErrorMessageFax('')
        }

        return true
    }
    const validateCountry = () => {
        let status = {...CountryInputStatus}
        Object.keys(status).forEach(
            (value) => (status[value] = GeneralEnums.textInputStatus.default),
        )
        if (!country && country.length < 1) {
            status.country = GeneralEnums.textInputStatus.error
        }

        setCountryInputStatus(status)
        if (Object.values(status).includes(GeneralEnums.textInputStatus.error)) {
            return false
        } else {
            return true
        }
    }
    return (
        <div>
            <ABAHeader text="Account" />
            <ABAContainer label="Program Information" loading={loading}>
                <div
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        width: '50%',
                        marginLeft: '30%',
                    }}
                >
                    <div style={{width: '40%', marginRight: '2%'}}>
                        <div>
                            <span style={{fontWeight: 'bold', marginRight: '2%'}}>
                                Program ID:
                            </span>
                            {userData.ProgramNumber}
                        </div>

                        <div>
                            <span style={{fontWeight: 'bold', marginRight: '2%'}}>
                                Program Type:
                            </span>
                            {userData.ProgramType}
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold', marginRight: '2%'}}>
                                Program Name:
                            </span>
                            {userData.ProgramName}
                        </div>
                    </div>

                    <div style={{width: '40%', marginRight: '2%'}}>
                        <div>
                            <span style={{fontWeight: 'bold', marginRight: '2%'}}>
                                RRC ID:
                            </span>
                            {RRCId}
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold', marginRight: '2%'}}>
                                Last RCC Review:
                            </span>
                            {HelperMethods.formatDate(lastRCCReview)}
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold', marginRight: '2%'}}>
                                Next RCC Review:
                            </span>
                            {HelperMethods.formatDate(nextRCCReview)}
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold', marginRight: '2%'}}>
                                Number of Accredited Residents:
                            </span>
                            {numberOfAccResidents}
                        </div>
                    </div>
                </div>

                <div style={{width: '32%', marginLeft: '30%'}}>
                    <h2 style={{textAlign: 'center', marginTop: '5%'}}>Address</h2>
                    <TextInput
                        label="Department"
                        placeholder="Department"
                        maxLength={100}
                        onChange={(event) => {
                            setDepartment(event.target.value)
                        }}
                        value={Department}
                    />
                    <TextInput
                        label="streetName"
                        placeholder="Street Name"
                        maxLength={250}
                        onChange={(event) => {
                            setStreetName(event.target.value)
                        }}
                        value={streetName}
                    />
                    <TextInput
                        label="streetName2"
                        placeholder="Street Name 2"
                        maxLength={250}
                        onChange={(event) => {
                            setStreetName2(event.target.value)
                        }}
                        value={streetName2}
                    />
                    <TextInput
                        label="City"
                        placeholder="City"
                        onChange={(event) => {
                            setCity(event.target.value)
                        }}
                        value={city}
                    />

                    <Dropdown
                        required
                        placeholder="Country"
                        onChange={(value) => {
                            setCountry(value)
                        }}
                        options={optionSets.countries.map((country) => {
                            return {
                                value: country.Value,
                                name: country.Label,
                            }
                        })}
                        dropdownStatus={CountryInputStatus.country}
                        selectedValue={country}
                        errorMessage={'Please select a country'}
                    />

                    {country === GeneralEnums.cert_countryIds.usa && (
                        <Dropdown
                            placeholder="State"
                            onChange={(value) => {
                                setAddressState(value)
                            }}
                            options={optionSets.states.map((addresstate) => {
                                return {
                                    value: addresstate.Value,
                                    name: addresstate.Label,
                                }
                            })}
                            selectedValue={addressState}
                            errorMessage={'Please select a state'}
                        />
                    )}
                    <TextInput
                        label="Postal Code"
                        placeholder="Postal Code"
                        onChange={(event) => {
                            setPostalCode(event.target.value)
                        }}
                        value={postalCode}
                    />
                    <TextInput
                        label="Phone"
                        placeholder="Phone"
                        maxLength={14}
                        onChange={(event) => {
                            setPhone(
                                HelperMethods.formatFaxAndPhoneNumber(event.target.value),
                            )
                        }}
                        value={phone}
                        textInputStatus={inputStatusPhoneNumber}
                        errorMessage={errorMessagePhoneNumber}
                    />
                    <TextInput
                        label="Fax"
                        placeholder="Fax"
                        onChange={(event) => {
                            setFax(
                                HelperMethods.formatFaxAndPhoneNumber(event.target.value),
                            )
                        }}
                        value={fax}
                        textInputStatus={inputStatusFax}
                        errorMessage={errorMessageFax}
                    />
                    <Button
                        variant={StyleVariant.OUTLINED}
                        style={{width: '30%'}}
                        onClick={(event) => {
                            cancel(event)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        text="Submit"
                        onClick={async () => {
                            const programInformation = {
                                AccountId: userData.AccountId,
                                AddressLine1: streetName,
                                AddressLine2: streetName2,
                                Country: country,
                                State: addressState,
                                City: city,
                                Department: Department,
                                PostalCode: postalCode,
                                phone: phone,
                                Fax: fax,
                            }

                            if (validateCountry()) {
                                if (validatePhoneNumber()) {
                                    if (validateFax()) {
                                        setLoading(true)
                                        await updateAddressInformations(
                                            programInformation,
                                        )
                                        setLoading(false)
                                        alert('Updated Successfully!')
                                        navigate('/residentListing')
                                    }
                                }
                            }
                        }}
                        variant={StyleVariant.FILLED}
                        style={{width: '30%', margin: '2%', marginLeft: '38%'}}
                    >
                        Submit
                    </Button>
                </div>
            </ABAContainer>
        </div>
    )
}
