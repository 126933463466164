import React from 'react'
import PropTypes from 'prop-types'
import styles from './Scorecard.module.css'
import {GeneralEnums} from '../../../helpers/GeneralEnums'

const ScorecardButton = (props) => {
    const {
        label,
        onClick,
        onKeyPress,
        tabIndex,
        numberInDraft,
        numberInValidate,
        iteInvoiced,
        itePaid,
        eventDetail,
    } = props

    const today = new Date()

    const {scorecardButton, greenStatus, yellowStatus, redStatus, disabledStatus} = styles
    const totalNumber = numberInDraft + numberInValidate
    const displayText =
        totalNumber > 0 && label !== 'ITE' ? `${label} (${totalNumber})` : label

    const applyCSS = () => {
        let cssToApply = ''
        if (label === 'Training Reports' || label === 'Enrollment Forms') {
            if (numberInDraft > 0) {
                cssToApply = `${scorecardButton} ${redStatus}`
            } else if (numberInDraft === 0 && numberInValidate > 0) {
                cssToApply = `${scorecardButton} ${yellowStatus}`
            } else if (numberInDraft === 0) {
                cssToApply = `${scorecardButton} ${greenStatus}`
            } else {
                cssToApply = `${scorecardButton} ${greenStatus}`
            }
        }
        if (label === 'ITE') {
            if (
                !eventDetail ||
                eventDetail.StatusCode !== GeneralEnums.eventStatus.scheduled ||
                (eventDetail.StartDate && today > new Date(eventDetail.StartDate))
            ) {
                cssToApply = `${scorecardButton} ${disabledStatus}`
            } else if (itePaid > 0) {
                cssToApply = `${scorecardButton} ${greenStatus}`
            } else if (iteInvoiced > 0) {
                cssToApply = `${scorecardButton} ${yellowStatus}`
            } else {
                cssToApply = `${scorecardButton} ${redStatus}`
            }
        }
        return cssToApply
    }

    return (
        <div>
            <button
                className={applyCSS()}
                onClick={onClick}
                onKeyPress={onKeyPress}
                tabIndex={tabIndex}
                label={label}
                type="button"
            >
                {displayText}
            </button>
        </div>
    )
}

ScorecardButton.defaultProps = {
    label: '',
    numberInDraft: 0,
    numberInValidate: 0,
    iteInvoiced: 0,
    itePaid: 0,
    iteRegistrationClosed: false,
    tabIndex: 0,
    eventDetail: null,
}

ScorecardButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func.isRequired,
    tabIndex: PropTypes.number,
    numberInValidate: PropTypes.number,
    numberInDraft: PropTypes.number,
    iteInvoiced: PropTypes.number,
    itePaid: PropTypes.number,
    iteRegistrationClosed: PropTypes.bool,
    label: PropTypes.string,
    eventDetail: PropTypes.any,
}
export default ScorecardButton
