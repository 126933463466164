import * as ActionTypes from './ActionTypes'

export const ExpandedResidentSuccess = (expandedResidentData) => {
    return {
        type: ActionTypes.RESIDENT_OPEN,
        payload: expandedResidentData,
    }
}

export const ExpandedResidentFailure = (errorMessage) => {
    return {
        type: ActionTypes.RESIDENT_CLOSE,
        payload: {errorMessage},
    }
}
