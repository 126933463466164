import * as yup from 'yup'

const ValidationHelpers = {
    validateDate(date) {
        if (!date) {
            return false
        }

        // validating date format
        const dateRegEx = /^\d{2}[./-]\d{2}[./-]\d{4}$/
        if (!date.match(dateRegEx)) {
            return false
        }

        // validating if date entered is an actual date
        const timestamp = Date.parse(date)
        if (Number.isNaN(timestamp) === true) {
            return false
        }

        return true
    },

    // checking if string exists and making sure it is not just whitespace
    validateString(str) {
        if (!str) {
            return false
        }

        // regex checking string to see if there's only whitespace.  If so, then we return false
        if (!/\S/.test(str)) {
            return false
        }

        return true
    },

    // validation for if a string only contains numbers
    validateNumbersOnly(value) {
        return /^\d+$/.test(value)
    },

    validateEmail(email) {
        const regExEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/

        if (!regExEmail.test(email.trim())) {
            return false
        }

        return true
    },
}

const ssnRegex =
    /^((?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4})|((?!219 09 9999|078 05 1120)(?!666|000|9\d{2})\d{3} (?!00)\d{2} (?!0{4})\d{4})|((?!219099999|078051120)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4})$/

const dateRegex = /(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/

export const enrollmentValidationSchema = (isPrimary, isConfirmation) => {
    const validators = {
        LastName: yup.string().required(),
        FirstName: yup.string().required(),
        SSN: yup.string().matches(ssnRegex, 'Invalid SSN.'),
        BirthDate: yup
            .date()

            .nullable()
            .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
        CountryOfBirthId: yup.string().typeError('Please select a birth country.').required(),
        GenderId: yup.string().required(),
        EmailAddress: yup.string().email().required(),
        MedicalSchoolCountryId: yup.string().required(),
        MedicalSchoolId: yup
            .string()
            .nullable(true)
            .when('MedicalSchoolCountryId', ([selection], schema) => {
                const result = +selection === 1 || +selection === 2
                return result ? schema.nullable(true).required() : schema
            }),
        InternationalMedicalSchoolName: yup
            .string()
            .nullable(true)
            .when('MedicalSchoolCountryId', {
                is: (selection) => +selection === 3 || +selection === 4,
                then: (schema) => schema.nullable(true).required(),
            }),
        MedicalDegreeId: yup.string().required(),
        DateResidentBeganTraining: yup.string().matches(dateRegex).required(),
        MedicalDegreeConferredDate: yup.string().matches(dateRegex).required(),

        PriorCBTraining: yup.string().matches(/[0,1]/),
        DatePriorCBTrainingStarted: yup.string().when('PriorCBTraining', {
            is: ([selection]) => +selection === 1,
            then: (schema) => schema.matches(dateRegex).required(),
        }),
        PriorCBTrainingDuration: yup
            .string()
            .when('PriorCBTraining', {
                is: ([selection]) => +selection === 1,
                then: (schema) => schema.required(),
            })
            .nullable(),
        IsCertifying: yup.boolean(),
        TrackType: yup.string().required(),
        PriorCBTrainingProgramName: yup.string().when('PriorCBTraining', {
            is: ([selection]) => +selection === 1,
            then: (schema) => schema.required(),
        }),
        PriorCBTrainingProgramCity: yup.string().when('PriorCBTraining', {
            is: ([selection]) => +selection === 1,
            then: (schema) => schema.required(),
        }),
        PriorCBTrainingProgramState: yup.string().when('PriorCBTraining', {
            is: ([selection]) => +selection === 1,
            then: (schema) => schema.required(),
        }),
        PriorCBTrainingProgramDirector: yup.string().when('PriorCBTraining', {
            is: ([selection]) => +selection === 1,
            then: (schema) => schema.required(),
        }),
        PriorCBTrainingProgramType: yup
            .string()
            .when('PriorCBTraining', {
                is: ([selection]) => +selection === 1,
                then: (schema) => schema.required(),
            })
            .nullable(),
        /*  Attestation: isConfirmation
            ? yup
                  .bool()
                  .oneOf(
                      [true],
                      'You must attest to the accuracy of the information provided on this form.',
                  )
            : yup.bool(), */
    }

    if (!isPrimary) {
        validators.DatePrimaryResidencyCompleted = yup.string().matches(dateRegex).required()
        validators.PrimarySpecialty = yup.string().required()
    }

    return yup.object().shape(validators)
}

export default ValidationHelpers
