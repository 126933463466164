import {Container, LoadingOverlay} from 'aba-ui'
import ABAHeader from '../components/ABAComponents/ABAHeader'
import React, {useEffect, useState} from 'react'
import useReport from '../../libs/hooks/useReport'
import ABAAlert from '../components/ABAComponents/ABAAlert'
import {openPdf} from '../../helpers/HelperMethods'
import ReportList from '../components/Reports/ReportList'

export default function BoardPassRates() {
    const [boardPassReports, setBoardPassReports] = useState([])
    const {getBoardPassReports, getPostedDocument, getBoardPassReport} = useReport()
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    useEffect(() => {
        const getReports = async () => {
            setLoading(true)
            const boardPassReports = await getBoardPassReports()
            setBoardPassReports(boardPassReports)
        }
        try {
            getReports()
        } catch (error) {
            console.error(error)
            setErrorMessage('An error occurred while loading your reports')
        } finally {
            setLoading(false)
        }
    }, [])

    const openBoardPassReport = async (report) => {
        setErrorMessage('')
        const {DocumentID, DisplayDate} = report
        try {
            setLoading(true)
            const document = DocumentID
                ? await getPostedDocument(DocumentID)
                : await getBoardPassReport(DisplayDate)
            openPdf(document)
        } catch (error) {
            console.error(error)
            setErrorMessage('An error occurred while loading your report')
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <ABAHeader text="Reports" />
            {errorMessage && (
                <ABAAlert
                    text={errorMessage}
                    style={{
                        width: 400,
                        margin: 'auto',
                    }}
                />
            )}
            <Container title="Board Pass Rate Reports" headerJustify="center">
                <div>
                    <ReportList
                        onOpen={openBoardPassReport}
                        reports={boardPassReports.map((report) => {
                            return {
                                ...report,
                                DisplayName: `Board Pass Rate Report - ${report.DisplayDate}`,
                            }
                        })}
                        width={'400px'}
                    />
                    {loading && <LoadingOverlay fullscreen={false} />}
                </div>
            </Container>
        </div>
    )
}
