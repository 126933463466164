import React, {useEffect, useState} from 'react'
import {Container, Button, StyleVariant, Dropdown, TextInput, LoadingOverlay} from 'aba-ui'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import RTIDAPI from '../../helpers/AxiosInstance'
import ABAHeader from '../components/ABAComponents/ABAHeader'
import ABAAlert from '../components/ABAComponents/ABAAlert'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {GeneralEnums, ColorEnums} from '../../helpers/GeneralEnums'
import {openPdf} from '../../helpers/HelperMethods'

const headerCellStyle = {
    borderColor: '#418FDE',
    fontSize: 14,
    color: ColorEnums.blueGray,
    fontFamily: 'lato',
}

const bodyCellStyle = {
    color: ColorEnums.cloudBurst,
    fontFamily: 'lato',
}

export default function PassFail() {
    const [reports, setReports] = useState([])
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        ;(async () => {
            try {
                setErrorMessage(null)
                const {data} = await RTIDAPI.get('/report/passFail')
                setLoading(null)
                setReports(data)
            } catch (error) {
                setErrorMessage('An error occurred while fetching reports')
                setLoading(null)
            }
        })()
    }, [])

    const getReport = async (report) => {
        setErrorMessage('')
        try {
            setLoading(true)
            const {data} = await RTIDAPI.get(`report/passFail/${report.SessionYear}`, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/pdf',
                },
                params: {
                    productId: report.ProductID,
                    label: report.ExamName,
                },
            })
            openPdf(data)
            setLoading(false)
        } catch (error) {
            setErrorMessage('An error occurred while loading your report')
            setLoading(false)
        }
    }

    return (
        <>
            <ABAHeader text="Reports" />
            {errorMessage && (
                <ABAAlert
                    text={errorMessage}
                    style={{
                        width: 400,
                        margin: 'auto',
                    }}
                />
            )}
            <Container title="Pass / Fail" headerJustify="center">
                <Table sx={{margin: 'auto'}} style={{tableLayout: 'fixed', maxWidth: 700}}>
                    <colgroup>
                        <col />
                        <col />
                        <col style={{width: '40px'}} />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell style={headerCellStyle}>Exam</TableCell>
                            <TableCell style={headerCellStyle} colSpan={2}>
                                Exam Year
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {reports?.length ? (
                        <TableBody>
                            {reports.map((report, i) => {
                                return (
                                    <TableRow
                                        key={i}
                                        onClick={() => {
                                            getReport(report)
                                        }}
                                        hover
                                        style={{cursor: 'pointer'}}
                                    >
                                        <TableCell style={bodyCellStyle}>
                                            {report.ExamName} Exam Results
                                        </TableCell>
                                        <TableCell style={bodyCellStyle}>{report.SessionYear}</TableCell>

                                        <TableCell
                                            style={{
                                                color: ColorEnums.danube,
                                            }}
                                        >
                                            <FontAwesomeIcon icon={'angle-right'} />
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    ) : (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={3} style={{textAlign: 'center', ...bodyCellStyle}}>
                                    No records to display
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
                {loading && <LoadingOverlay fullscreen={false} />}
            </Container>
        </>
    )
}
