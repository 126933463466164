import React from 'react'
import PropTypes from 'prop-types'

export default function ABACheckbox(props) {
    const {isChecked, isDisabled, label, onChangeEvent} = props

    return (
        <div>
            <label htmlFor="abaCheckbox" style={{display: 'flex', flexDirection: 'row'}}>
                <input
                    id="abaCheckbox"
                    type="checkbox"
                    style={{
                        display: 'inline-block',
                        width: '15px',
                        height: '15px',
                    }}
                    checked={isChecked}
                    disabled={isDisabled}
                    onChange={onChangeEvent}
                />
                <span
                    style={{
                        paddingLeft: '10px',
                        wordBreak: 'normal',
                        whiteSpace: 'normal',
                    }}
                >
                    {label}
                </span>
            </label>
        </div>
    )
}

ABACheckbox.defaultProps = {
    isDisabled: false,
}

ABACheckbox.propTypes = {
    isChecked: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onChangeEvent: PropTypes.func.isRequired,
}
