import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import trainingRecordsStyles from '../../screens/TrainingReports/TrainingReports.module.css'
import styles from '../../screens/ResidentListing/ResidentListing.module.css'
import HelperMethods from '../../../helpers/HelperMethods'
import {GeneralEnums, ColorEnums} from '../../../helpers/GeneralEnums'
import ABAActionPopup from '../ABAComponents/ABAActionPopup/ABAActionPopup'
import getResidentListingData from '../../screens/ResidentListing/ResidentListingLogic'
import useTraining from '../../../libs/hooks/useTraining'
import useUser from '../../../libs/hooks/useUser'
import {LoadingOverlay} from 'aba-ui'
import usePDIR from '../../../libs/hooks/usePDIR'
import {
    UpdateTrainingRecord,
    LoadTrainingRecords,
} from '../../../redux/Actions/TrainingRecordsActionCreators'
import {UpdateTrainingSummary} from '../../../redux/Actions/TrainingSummaryActionCreators'
import {LoadResidentListing} from '../../../redux/Actions/ResidentListingActionCreators'
import TrainingAttestationModal from './TrainingAttestationModal'
import {Tooltip} from '@mui/material'

const TrainingRecordTable = ({
    trainingData,
    showName = false,
    previousResident = null,
    previousTrainingRecord = null,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {updateTrainingStatus, approveTraining} = useTraining()
    const {parentTable, headerRow} = styles
    const [pdirId, setPDIRId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [residentName, setResidentName] = useState('')
    const [abaId, setAbaId] = useState('')
    const [trainingPeriod, setTrainingPeriod] = useState('')
    const [trainingId, setTrainingId] = useState('')
    const [programType, setProgramType] = useState('')
    const {CancelledPgm} = GeneralEnums.trainingStatusCodes
    const {userData} = useUser()
    const {UserRole} = userData

    let trainingDataFiltered = trainingData.filter((dataRow) => {
        return dataRow.trainingStatusCodes !== CancelledPgm && dataRow.Status !== 'Cancelled Pgm'
    })

    const ResidentListingList = useSelector(({residentListing}) => residentListing)

    const {checkForExistingPDIR} = usePDIR()

    const sortData = (a, b) => {
        if (a.TrainingPeriod > b.TrainingPeriod) {
            return -1
        } else if (a.TrainingPeriod === b.TrainingPeriod) {
            return -a.StartDate.localeCompare(b.StartDate)
        } else {
            return 1
        }
    }
    trainingDataFiltered = trainingDataFiltered.sort(sortData)

    const applyTrainingRecordsCSS = (stateCode, statusCode, signatureValue) => {
        let css = ''
        const {Active, Inactive} = GeneralEnums.trainingStateCodes
        const {Draft, NeedsAction, Validated} = GeneralEnums.trainingStatusCodes

        if (stateCode === Active) {
            if (statusCode === NeedsAction && signatureValue === 'Received') {
                css = `${trainingRecordsStyles.blueStatus}`
            } else if (statusCode === NeedsAction) {
                css = `${trainingRecordsStyles.redStatus}`
            } else if (statusCode === Validated) {
                css = `${trainingRecordsStyles.yellowStatus}`
            } else if (statusCode === Draft) {
                css = `${trainingRecordsStyles.redStatus}`
            }
        } else if (stateCode === Inactive) {
            css = `${trainingRecordsStyles.greenStatus}`
        } else {
            css = ''
        }
        return css
    }

    const assignStatusValue = (stateCode, statusCode, status, signatureValue) => {
        let text = ''
        const {Active, Inactive} = GeneralEnums.trainingStateCodes
        const {Draft, NeedsAction, Validated, Approved} = GeneralEnums.trainingStatusCodes
        if (stateCode === Active) {
            if (statusCode === NeedsAction && signatureValue === 'Received') {
                text = 'ABA Review'
            } else if (statusCode === NeedsAction) {
                text = 'COVER SHEET NEEDED'
            } else if (statusCode === Validated) {
                text = status
            } else if (statusCode === Draft) {
                text = status
            } else if (statusCode == Approved) {
                text = 'Processing..'
            }
        } else if (stateCode === Inactive) {
            text = 'APPROVED'
        } else {
            text = status
        }
        return text
    }

    const determineTrainingActions = (
        trainingId,
        statuscode,
        statecode,
        gradeValue,
        trainingDuration,
        rotationDuration,
        residentEnrollmentId,
    ) => {
        const {Draft, Validated, CancelledPgm, NeedsAction} = GeneralEnums.trainingStatusCodes

        const edit = {
            name: 'Edit',
            onClick: () => {
                if (statuscode === Validated) {
                    handleTrainingRecordUpdate(trainingId, Draft)
                }

                navigate('/trainingRecords', {
                    state: {
                        trainingId,
                        residentEnrollmentId,
                    },
                })
            },
        }
        const cancel = {
            name: 'Cancel',
            onClick: () => {
                window.confirm('Are you sure you want to Cancel this Training Record?')
                    ? handleTrainingRecordUpdate(trainingId, CancelledPgm)
                    : ontouchcancel('cancel')
            },
        }

        const validate = {
            name: 'Validate',
            onClick: () => {
                handleTrainingRecordUpdate(trainingId, Validated)
            },
        }

        const approve = {
            name: 'Approve',
            onClick: () => {
                approveTrainingRecord(trainingId, residentEnrollmentId)
            },
        }
        const view = {
            name: 'View',
            onClick: () => {
                navigate('/viewCompletedTraining', {
                    state: {
                        residentEnrollmentId,
                        trainingId,
                        previousResident,
                        previousTrainingRecord,
                    },
                })
            },
        }
        if (statuscode === GeneralEnums.trainingStatusCodes.Draft) {
            if (rotationDuration < trainingDuration) {
                return [edit, cancel]
            } else if (rotationDuration === trainingDuration) {
                return [edit, validate, cancel]
            } else {
                return [edit, cancel]
            }
        }

        if (statuscode === Validated) {
            const selectedResident = ResidentListingList.ResidentListing.find(
                (resident) => resident.ResidentEnrollmentId === residentEnrollmentId,
            )

            if (
                gradeValue !== 0 &&
                ((UserRole === 'ProgramDirector' &&
                    selectedResident?.Track &&
                    selectedResident?.Track != 'Alternate Entry Path') ||
                    UserRole === 'SuperUser' ||
                    (UserRole === 'DepartmentChair' &&
                        selectedResident?.Track &&
                        selectedResident?.Track === 'Alternate Entry Path'))
            ) {
                return [edit, approve, cancel]
            } else {
                return [edit, cancel]
            }
        }

        if (statuscode === NeedsAction) {
            return [view]
        }

        if (statecode === GeneralEnums.trainingStateCodes.Inactive) {
            return [view]
        }
        return []
    }

    const handleTrainingRecordUpdate = async (trainingId, statusCode) => {
        setLoading(true)
        try {
            dispatch(LoadTrainingRecords())

            const {data} = await updateTrainingStatus(trainingId, statusCode)
            const {trainingRecord, summary} = data
            dispatch(LoadTrainingRecords(false))
            dispatch(UpdateTrainingRecord(trainingRecord))
            dispatch(UpdateTrainingSummary(summary))
        } catch (error) {
            alert(error.response.data.ExceptionMessage)
        } finally {
            setLoading(false)
        }
    }
    const approveTrainingRecord = async (trainingId, residentEnrollmentId) => {
        try {
            dispatch(LoadTrainingRecords())
            checkForExistingPDIR(residentEnrollmentId).then((result) => {
                setLoading(true)
                if (result !== null) {
                    setPDIRId(result.PDIRId)
                }
                approveTraining(trainingId, result?.PDIRId).then((data) => {
                    //call api to return the updated REF and dispatch ResidentListing redux store
                    dispatch(LoadResidentListing())
                    dispatch(getResidentListingData(userData.ProgramId)).then(() => {
                        dispatch(LoadTrainingRecords())
                        dispatch(UpdateTrainingRecord(data))
                        setLoading(false)
                    })
                })
            })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <table className={parentTable}>
            {loading && <LoadingOverlay title="Loading..." />}
            {show && (
                <TrainingAttestationModal
                    onCancel={setShow}
                    nameDisplay={residentName}
                    abaId={abaId}
                    trainingPeriod={trainingPeriod}
                    programType={programType}
                    trainingId={trainingId}
                />
            )}
            <thead>
                <tr className={headerRow}>
                    {showName && <th>Name</th>}
                    <th>Period</th>
                    <th>Start Date</th>
                    <th>Status</th>
                    <th>Training Months</th>
                    <th>Grade</th>
                    <th>Signature</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {trainingDataFiltered?.length ? (
                    trainingDataFiltered.map((trainingRecord) => {
                        const {
                            TrainingId,
                            TrainingPeriod,
                            StartDate,
                            EndDate,
                            StatusCode,
                            Status,
                            StateCode,
                            MonthsTrained,
                            GradeValue,
                            SignatureValue,
                            TotalTrainingDuration,
                            TotalRotationDuration,
                            ResidentName,
                            ResidentEnrollmentId,
                            ResidentDisplayName,
                        } = trainingRecord
                        const actionRequired = () => {
                            const selectedResident = ResidentListingList.ResidentListing.find(
                                (resident) => resident.ResidentEnrollmentId === ResidentEnrollmentId,
                            )
                            return (
                                <div>
                                    {(UserRole === 'ProgramDirector' &&
                                        selectedResident?.Track !== 'Alternate Entry Path') ||
                                    (UserRole === 'DepartmentChair' &&
                                        selectedResident?.Track === 'Alternate Entry Path') ||
                                    UserRole === 'SuperUser' ? (
                                        <Tooltip title="Click to complete">
                                            <div
                                                onClick={() => {
                                                    setShow(true)
                                                    setTrainingId(TrainingId)
                                                    setResidentName(`${ResidentDisplayName}`)
                                                    setAbaId(`${selectedResident.ABAID}`)
                                                    setTrainingPeriod(
                                                        `${TrainingPeriod} (${HelperMethods.formatDate(
                                                            StartDate,
                                                        )} - ${HelperMethods.formatDate(EndDate)})`,
                                                    )
                                                    setProgramType(`${userData.ProgramType}`)
                                                }}
                                                style={{
                                                    borderBottom: 'solid',
                                                    display: 'inline-block',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                ACTION REQUIRED *
                                            </div>
                                        </Tooltip>
                                    ) : (
                                        <div>ACTION REQUIRED</div>
                                    )}
                                </div>
                            )
                        }
                        const toFixedIfNecessary = (value, dp) => {
                            return +parseFloat(value).toFixed(dp)
                        }
                        return (
                            <tr key={TrainingId}>
                                {showName && <td>{ResidentName}</td>}
                                <td>{TrainingPeriod}</td>
                                <td>{HelperMethods.formatDate(StartDate)}</td>
                                <td
                                    className={applyTrainingRecordsCSS(
                                        StateCode,
                                        StatusCode,
                                        SignatureValue,
                                    )}
                                >
                                    {assignStatusValue(StateCode, StatusCode, Status, SignatureValue)}
                                </td>
                                <td>{toFixedIfNecessary(MonthsTrained, 2)}</td>
                                <td>{GradeValue}</td>
                                <td
                                    className={
                                        StatusCode === GeneralEnums.trainingStatusCodes.NeedsAction &&
                                        SignatureValue === ''
                                            ? trainingRecordsStyles.redStatus
                                            : ''
                                    }
                                >
                                    {StatusCode === GeneralEnums.trainingStatusCodes.NeedsAction &&
                                    SignatureValue === ''
                                        ? actionRequired()
                                        : SignatureValue}
                                </td>
                                <td>
                                    <ABAActionPopup
                                        uniqueId={`${TrainingId}-${TrainingPeriod}`}
                                        actions={determineTrainingActions(
                                            TrainingId,
                                            StatusCode,
                                            StateCode,
                                            GradeValue,
                                            TotalTrainingDuration,
                                            TotalRotationDuration,
                                            ResidentEnrollmentId,
                                        )}
                                    />
                                </td>
                            </tr>
                        )
                    })
                ) : (
                    <tr>
                        <td style={{textAlign: 'center', paddingLeft: 10}} colSpan={7 + showName}>
                            No Training Records to display
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}

export default TrainingRecordTable
