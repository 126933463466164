import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import ABALogo from '../../../images/ABA-RTID-Logo-White-transparent.png'
import useAuthentication from '../../../libs/hooks/useAuthentication'
import useUser from '../../../libs/hooks/useUser'
import AccountHeader from '../AccountHeader/AccountHeader'
import ProgramSwitchModal from '../Modal/ProgramSwitchModal'
import NavAccordion from './NavAccordion'
import styles from './NavShell.module.css'

export default function NavShell({children}) {
    const {sideNavContainer, parentLink, switchProgram} = styles
    const [show, setShow] = useState(false)
    const links = [
        {
            name: 'Reports',
            subLinks: [
                {
                    name: 'ADVANCED Exam',
                    url: '/passFail',
                },
                {
                    name: 'BASIC Exam',
                    url: '/basicReports',
                    role: 'ProgramDirector,DepartmentChair',
                },
                {
                    name: 'Board Pass Rates',
                    url: '/boardPass',
                },
                {
                    name: 'Exam Registration Status',
                    url: '/examRegistrationStatus',
                },
                {
                    name: 'Grad Class',
                    url: '/gradClass',
                },
                {
                    name: 'In-Training Exam',
                    url: '/iteReports',
                    role: 'ProgramDirector,DepartmentChair',
                },
                {
                    name: 'Residents without portal account',
                },
                {
                    name: 'SOE and OSCE Exams',
                    url: '/soeOsceReports',
                },
            ],
        },
        {
            name: 'Utilities',
            subLinks: [
                {
                    name: 'CCC Report Results',
                    url: '/cccReportResults',
                },
                {
                    name: 'Mass Approve Training',
                    url: '/massApproveTraining',
                    role: 'ProgramDirector',
                },
                {
                    name: 'Mass Complete Training',
                    url: '/massCompleteTraining',
                },
            ],
        },
        {
            name: 'Account',
            subLinks: [
                {
                    name: 'Program Information',
                    url: '/programInformation',
                },
                {
                    name: 'Program Contacts',
                    url: '/programContacts',
                },
                {
                    name: 'Invoices',
                    url: '/invoices',
                },
            ],
        },
    ]

    // This is a redux hook to access the redux store. You can view the current store structure in the console.
    const {userData} = useUser()

    const {logout} = useAuthentication()

    const handleHomeRefresh = () => {
        if (window.location.pathname === '/residentListing') {
            window.location.reload(true)
        }
    }

    if (!userData.AccountId) {
        return <>{children}</>
    }

    return (
        <div className="navigationContainer">
            <div className={`no-print ${sideNavContainer}`}>
                <img
                    src={ABALogo}
                    alt="ABA Logo"
                    style={{
                        width: '240px',
                        display: 'block',
                        margin: 'auto',
                        paddingBottom: '25px',
                        paddingTop: '25px',
                    }}
                />
                {userData && !userData.IsCanadianProgram && (
                    <>
                        <Link onClick={handleHomeRefresh} to="/residentListing" className={parentLink}>
                            Home
                        </Link>
                        <Link to="/enroll" className={parentLink}>
                            Enroll
                        </Link>
                        {links.map((link) => {
                            return <NavAccordion links={link} key={link.name} />
                        })}
                    </>
                )}
                {userData?.IsCanadianProgram && (
                    <>
                        <Link to="/inTrainingExam" className={parentLink}>
                            Home (ITE)
                        </Link>
                        <Link to="/invoices" className={parentLink}>
                            Invoices
                        </Link>
                    </>
                )}

                <Link to="/help" className={parentLink}>
                    Help
                </Link>
                <div
                    className={parentLink}
                    onClick={() => logout()}
                    onKeyDown={() => logout()}
                    role="button"
                    tabIndex="-1"
                >
                    Logout
                </div>
                {userData?.UserRole === 'SuperUser' && (
                    <div className={switchProgram}>
                        <Link onClick={() => setShow(true)} className={parentLink}>
                            Switch Program
                        </Link>
                    </div>
                )}
                {show && <ProgramSwitchModal onCancel={setShow} />}
            </div>
            {userData && <AccountHeader />}
            <div className="navChildrenContainer">{children}</div>
        </div>
    )
}

NavShell.propTypes = {
    children: PropTypes.node.isRequired,
}
