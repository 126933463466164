import React, {useEffect, useState} from 'react'
import RTIDAPI from '../../helpers/AxiosInstance'
import useReport from '../../libs/hooks/useReport'
import useUser from '../../libs/hooks/useUser'
import ABAAlert from '../components/ABAComponents/ABAAlert'
import ABAHeader from '../components/ABAComponents/ABAHeader'
import {Container, LoadingOverlay} from 'aba-ui'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import HelperMethods from '../../helpers/HelperMethods'
import {ColorEnums} from '../../helpers/GeneralEnums'

const bodyCellStyle = {
    color: ColorEnums.cloudBurst,
    fontFamily: 'lato',
    textAlign: 'center',
}

export default function GradClass() {
    const [loading, setLoading] = useState(false)
    const [reportDetails, setReportDetails] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    const {getGradClassReportDetails} = useReport()
    const {userData} = useUser()

    useEffect(() => {
        ;(async () => {
            setLoading(true)
            const reportDates = await getGradClassReportDetails()
            setReportDetails(reportDates)
            setLoading(false)
        })()
    }, [])

    const getReport = async (reportDate) => {
        setErrorMessage('')
        try {
            setLoading(true)
            const newReportDate = reportDate.replace('/', '-').replace('/', '-')

            const {data} = await RTIDAPI.get(
                `report/gradClass/${userData.ProgramNumber.toString()}/${userData.ProgramType.toString()}/${newReportDate}`,
                {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/pdf',
                    },
                },
            )
            const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}))
            const link = document.createElement('a')
            link.href = url
            link.target = '_blank'
            link.rel = 'noopener noreferrer'
            document.body.appendChild(link)
            link.click()
            setLoading(false)
        } catch (error) {
            setErrorMessage('An error occurred while loading your report')
            setLoading(false)
        }
    }

    return (
        <>
            <ABAHeader text="Reports" />
            {errorMessage && (
                <ABAAlert
                    text={errorMessage}
                    style={{
                        width: 400,
                        margin: 'auto',
                    }}
                />
            )}
            <Container title="Graduation Class Reports" headerJustify="center">
                <div>
                    <Table style={{marginLeft: 'auto', marginRight: 'auto', width: '500px'}}>
                        {reportDetails?.length ? (
                            <TableBody>
                                {reportDetails.map((detail, i) => {
                                    return (
                                        <TableRow
                                            key={i}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                getReport(detail)
                                            }}
                                        >
                                            <TableCell style={bodyCellStyle}>
                                                Graduation Class Report -{' '}
                                                {HelperMethods.formatDate(detail)}
                                            </TableCell>
                                            <TableCell style={bodyCellStyle}>
                                                <FontAwesomeIcon
                                                    icon="angle-right"
                                                    style={{
                                                        color: ColorEnums.danube,
                                                        marginLeft: 10,
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        colSpan={4}
                                        style={{textAlign: 'center', ...bodyCellStyle}}
                                    >
                                        No records to display
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                    {loading && <LoadingOverlay fullscreen={false} />}
                </div>
            </Container>
        </>
    )
}
