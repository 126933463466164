import React, {useState} from 'react'
import {Modal, Dropdown, TextInput, Button} from 'aba-ui'
import styles from './ProgramSwitchModal.module.css'
import useOptionSets from '../../../libs/hooks/useOptionSets'
import RTIDAPI from '../../../helpers/AxiosInstance'

const ProgramSwitchModal = ({onCancel}) => {
    const {modal, content, titleBlock, input, buttonContainer} = styles
    const [selectedProgramType, setSelectedProgramType] = useState(null)
    const [programId, setProgramId] = useState('')
    const optionSets = useOptionSets()

    const handleOnChange = (e) => {
        setProgramId(e.target.value)
    }

    const programSwitchBool = async () => {
        try {
            return await RTIDAPI.post('/account/programCheck', {
                ProgramId: programId,
                ProgramType: selectedProgramType,
            })
        } catch (error) {
            console.log(
                'There was an issue fetching programCheck information from the AccountController',
            )
        }
    }
    const isCanadianBool = async () => {
        try {
            return await RTIDAPI.post('/account/canadaCheck', {
                ProgramId: programId,
            })
        } catch (error) {
            console.log(
                'There was an issue fetching canadaCheck information from the AccountController',
            )
        }
    }

    const handleSubmit = async () => {
        if (programId === '' || selectedProgramType === null) {
            alert('Please fill in the required field(s)')
            return
        }
        const programExist = (await programSwitchBool()).data
        const isCanadianCheck = (await isCanadianBool()).data

        if (programExist === true && isCanadianCheck === true) {
            location.pathname = `/inTrainingExam/${programId}:${selectedProgramType}`
        } else if (programExist === true && isCanadianCheck !== true) {
            location.pathname = `/residentListing/${programId}:${selectedProgramType}`
        } else {
            alert(
                'One or more of the entries does not match the information in our database. Please try again or contact the ABA.',
            )
        }
    }
    return (
        <Modal>
            <div className={modal}>
                <div className={content}>
                    <div className={titleBlock}>
                        <div
                            style={{
                                color: '#418FDE',
                                fontSize: '30px',
                            }}
                        >
                            Switch Program
                        </div>
                    </div>
                    <div className={input}>
                        <div>
                            <TextInput
                                style={{width: '300px'}}
                                name="ProgramID"
                                placeholder="Program ID"
                                onChange={handleOnChange}
                                required
                                label="Program ID"
                                value={programId}
                                maxLength={20}
                            />
                        </div>
                        <div>
                            <Dropdown
                                options={optionSets.programTypes?.map((programTypes) => {
                                    return {
                                        value: programTypes.Value,
                                        name: programTypes.Label,
                                    }
                                })}
                                required
                                onChange={(value) => {
                                    setSelectedProgramType(value)
                                }}
                                selectedValue={selectedProgramType}
                                placeholder="Specialty"
                            />
                        </div>
                    </div>
                    <div className={buttonContainer}>
                        <Button onClick={() => onCancel(false)}>Close</Button>
                        <Button onClick={handleSubmit}>Submit</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ProgramSwitchModal
