import {useEffect, useRef} from 'react'

const CustomHooks = {
    usePrevious: (value) => {
        const ref = useRef()
        useEffect(() => {
            ref.current = value
        })
        return ref.current
    },
}

export default CustomHooks
