import {GeneralEnums} from '../../helpers/GeneralEnums'
import * as ActionTypes from '../Actions/ActionTypes'

const defaultState = {
    TrainingRecords: [],
    Loading: false,
    ErrorMessage: null,
}

const TrainingRecords = (state = defaultState, {type, payload}) => {
    switch (type) {
        case ActionTypes.TRAININGRECORDS_SUCCESS:
            return {
                TrainingRecords: payload,
                Loading: false,
                ErrorMessage: null,
            }
        case ActionTypes.TRAININGRECORDS_FAILED:
            return {
                ...defaultState,
                Loading: false,
                ErrorMessage: payload.ErrorMessage,
            }
        case ActionTypes.TRAININGRECORDS_LOAD:
            return {
                ...state,
                Loading: payload,
            }
        case ActionTypes.RESET_REDUX:
            return defaultState
        case ActionTypes.TRAININGRECORDS_UPDATE:
            return {
                TrainingRecords: state.TrainingRecords.map((training) => {
                    if (training.TrainingId === payload.TrainingId) {
                        return {...training, ...payload}
                    }
                    return training
                }).filter(
                    (trainingRecord) =>
                        trainingRecord.StatusCode !== null &&
                        trainingRecord.StatusCode !==
                            GeneralEnums.trainingStatusCodes.CancelledPgm,
                ),
                Loading: false,
                ErrorMessage: null,
            }
        default:
            return state
    }
}
export default TrainingRecords
