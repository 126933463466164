import React from 'react'
import ABAContainer from '../../components/ABAComponents/ABAContainer/ABAContainer'
import Scorecard from '../../components/Scorecard/Scorecard'
import styles from './EnrollmentForms.module.css'
import HelperMethods from '../../../helpers/HelperMethods'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import useEnrollment from '../../../libs/hooks/useEnrollment'
import ABAActionPopup from '../../components/ABAComponents/ABAActionPopup/ABAActionPopup'
import {GeneralEnums} from '../../../helpers/GeneralEnums'
import useUser from '../../../libs/hooks/useUser'

const EnrollmentForms = () => {
    const {headerRow, parentTable, textYellow, textRed} = styles

    const navigate = useNavigate()
    const {updateEnrollmentStatus} = useEnrollment()

    const residentListingData = useSelector(
        ({residentListing}) => residentListing.ResidentListing,
    )

    const {Active} = GeneralEnums.residentEnrollmentStateCode
    const {Draft, Validated, CancelledProgram} =
        GeneralEnums.residentEnrollmentStatusCodes

    const residentListingDataFiltered = residentListingData.filter((dataRow) => {
        return (
            dataRow.ResidentEnrollmentStateCode === Active &&
            (dataRow.ResidentEnrollmentStatusCode === Draft ||
                dataRow.ResidentEnrollmentStatusCode === Validated)
        )
    })
    const {
        userData: {UserRole},
    } = useUser()
    const canApprove = UserRole === 'ProgramDirector' || UserRole === 'SuperUser'
    //styling and logic to display Status column

    const enrollmentStatusCSS = (ResidentEnrollmentStatus) => {
        let css = ''

        if (ResidentEnrollmentStatus === 'Draft') {
            css = `${textRed}`
        } else if (ResidentEnrollmentStatus === 'Validated') {
            css = `${textYellow}`
        }
        return css
    }

    const handleResidentListingUpdate = async (
        programId,
        residentEnrollmentId,
        statusCode,
    ) => {
        updateEnrollmentStatus(programId, residentEnrollmentId, statusCode)
    }

    const determineREFActions = (statuscode, resident) => {
        const {ABAID, BirthDate, ResidentEnrollmentId, ProgramId} = resident
        const newABAID = ABAID ? ABAID.replace(/-/g, '') : ''

        const edit = {
            name: 'Edit',
            onClick: () => {
                navigate('/enrollment',{
                    state: {
                        SSN: null,
                        BirthDate,
                        ABAId: newABAID,
                        NPI: null,
                        ResidentEnrollmentId,
                    },
                })
            },
        }
        const cancel = {
            name: 'Cancel',
            onClick: () => {
                handleResidentListingUpdate(
                    ProgramId,
                    ResidentEnrollmentId,
                    CancelledProgram,
                )
            },
        }
        const approve = {
            name: 'Approve',
            onClick: () => {
                navigate('/enrollment',{
                    state: {
                        setIsConfirmation: true,
                        SSN: null,
                        BirthDate,
                        ABAId: newABAID,
                        NPI: null,
                        ResidentEnrollmentId,
                    },
                })
            },
        }
        if (statuscode === Draft) {
            return [edit, cancel]
        }

        if (statuscode === Validated) {
            return canApprove ? [edit, approve, cancel] : [edit, cancel]
        }

        return []
    }

    return (
        <div>
            <Scorecard />
            <ABAContainer label="Enrollment Forms">
                <table style={{width: '100%'}} className={parentTable}>
                    <thead>
                        <tr className={headerRow}>
                            <th>Resident Name</th>
                            <th>SSN</th>
                            <th>ABA ID</th>
                            <th>Start of Training</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {residentListingDataFiltered ? (
                            residentListingDataFiltered.map((residentEnrollment) => {
                                const {
                                    SSN,
                                    ABAID,
                                    TrainingStartDate,
                                    ResidentEnrollmentId,
                                    ResidentDisplayName,
                                    ResidentEnrollmentStatus,
                                    ResidentEnrollmentStatusCode,
                                } = residentEnrollment

                                return (
                                    <tr key={ResidentEnrollmentId}>
                                        <td>{ResidentDisplayName}</td>
                                        <td>{HelperMethods.ssnMaskLast4(SSN)}</td>
                                        <td>{ABAID}</td>
                                        <td>
                                            {HelperMethods.formatDate(TrainingStartDate)}
                                        </td>

                                        <td
                                            className={enrollmentStatusCSS(
                                                ResidentEnrollmentStatus,
                                            )}
                                        >
                                            {ResidentEnrollmentStatus}
                                        </td>

                                        <td>
                                            <ABAActionPopup
                                                actions={determineREFActions(
                                                    ResidentEnrollmentStatusCode,
                                                    residentEnrollment,
                                                )}
                                                uniqueId={
                                                    residentEnrollment.residentEnrollmentId
                                                }
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        ) : (
                            <tr>
                                <td
                                    style={{textAlign: 'center', paddingLeft: 10}}
                                    colSpan={8}
                                >
                                    No enollment forms to display
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </ABAContainer>
        </div>
    )
}

export default EnrollmentForms
