/* eslint-disable no-alert */
import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {GeneralEnums} from '../../helpers/GeneralEnums'
import HelperMethods from '../../helpers/HelperMethods'
import ValidationHelpers from '../../helpers/ValidationHelpers'
import useEnrollment from '../../libs/hooks/useEnrollment'
import useOptionSets from '../../libs/hooks/useOptionSets'
import ABAAlert from '../components/ABAComponents/ABAAlert'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ABAContainer from '../components/ABAComponents/ABAContainer/ABAContainer'
import ABAHeader from '../components/ABAComponents/ABAHeader'
import ABARadio from '../components/ABAComponents/ABARadio'
import ABARequiredField from '../components/ABAComponents/ABARequiredField'
import {TextInput, Button, StyleVariant} from 'aba-ui'
import styles from '../styles/main.module.css'

export default function Enroll() {
    const navigate = useNavigate()
    useOptionSets() // early fetch of optionsets
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const {backgroundGray} = styles
    const {textInputStatus} = GeneralEnums
    // State for Radio Buttons
    const [enrollmentInputs, setEnrollmentInputs] = useState({
        ssn: true,
        abaId: false,
        npiNumber: false,
    })

    // States for input values
    const [ssn, setSSN] = useState(null)
    const [confirmSSN, setConfirmSSN] = useState(null)
    const [abaId, setABAId] = useState(null)
    const [confirmABAId, setConfirmABAId] = useState(null)
    const [npiNumber, setNPINumber] = useState(null)
    const [confirmNPINumber, setConfirmNPINumber] = useState(null)
    const [dateOfBirth, setDateOfBirth] = useState('')
    const {checkEnrollment} = useEnrollment({
        SSN: ssn,
        ABAId: abaId,
        NPI: npiNumber,
        BirthDate: dateOfBirth,
    })
    // States for input statuses
    const [inputStatusSSN, setInputStatusSSN] = useState(textInputStatus.default)
    const [inputStatusConfirmSSN, setInputStatusConfirmSSN] = useState(textInputStatus.default)
    const [inputStatusABAId, setInputStatusABAId] = useState(textInputStatus.default)
    const [inputStatusConfirmABAId, setInputStatusConfirmABAId] = useState(textInputStatus.default)
    const [inputStatusNPINumber, setInputStatusNPINumber] = useState(textInputStatus.default)
    const [inputStatusConfirmNPINumber, setInputStatusConfirmNPINumber] = useState(
        textInputStatus.default,
    )
    const [inputStatusDateOfBirth, setInputStatusDateOfBirth] = useState(textInputStatus.default)

    // States for validation error messages
    const [errorMessageSSN, setErrorMessageSSN] = useState('')
    const [errorMessageConfirmSSN, setErrorMessageConfirmSSN] = useState('')
    const [errorMessageABAId, setErrorMessageABAId] = useState('')
    const [errorMessageConfirmABAId, setErrorMessageConfirmABAId] = useState('')
    const [errorMessageNPINumber, setErrorMessageNPINumber] = useState('')
    const [errorMessageConfirmNPINumber, setErrorMessageConfirmNPINumber] = useState('')
    const [errorMessageDateOfBirth, setErrorMessageDateOfBirth] = useState('')

    const checkEnrollmentAndDOB = (searchType, apiData, navigationInfo) => {
        if (apiData && apiData.WrongDOB) {
            setLoading(false)
            alert(
                `We found a physician with the same ${searchType} but the Birth Date doesn't match our database. Please try again or check with the physician.`,
            )
        } else if (apiData && apiData.EnrolledMessage) {
            setLoading(false)
            setErrorMessage(apiData.EnrolledMessage)
        } else {
            navigate(navigationInfo.pathname, {state: navigationInfo.state})
        }
    }

    const validateSSN = () => {
        if (ssn?.length < 9 || !ValidationHelpers.validateNumbersOnly(ssn)) {
            setInputStatusSSN(textInputStatus.error)
            setErrorMessageSSN('Please enter a valid SSN')
            return false
        }
        if (inputStatusSSN === textInputStatus.error) {
            setInputStatusSSN(textInputStatus.default)
            setErrorMessageSSN('')
        }

        return true
    }

    const validateSSNConfirmation = () => {
        if (
            confirmSSN?.length < 9 ||
            !ValidationHelpers.validateNumbersOnly(confirmSSN) ||
            confirmSSN !== ssn
        ) {
            setInputStatusConfirmSSN(textInputStatus.error)
            setErrorMessageConfirmSSN('Please enter a matching SSN')
            return false
        }

        if (confirmSSN === ssn && inputStatusConfirmSSN === textInputStatus.error) {
            setErrorMessageConfirmSSN('')
            setInputStatusConfirmSSN(textInputStatus.default)
        }

        return true
    }

    const validateABAId = () => {
        if (abaId?.length < 8 || !ValidationHelpers.validateNumbersOnly(abaId)) {
            setInputStatusABAId(textInputStatus.error)
            setErrorMessageABAId('Please enter a valid ABA ID')
            return false
        }
        if (inputStatusABAId === textInputStatus.error) {
            setInputStatusABAId(textInputStatus.default)
            setErrorMessageABAId('')
        }

        return true
    }

    const validateABAIdConfirmation = () => {
        if (
            confirmABAId?.length < 8 ||
            !ValidationHelpers.validateNumbersOnly(confirmABAId) ||
            confirmABAId !== abaId
        ) {
            setInputStatusConfirmABAId(textInputStatus.error)
            setErrorMessageConfirmABAId('Please enter a matching ABA ID')
            return false
        }

        if (confirmABAId === abaId && inputStatusConfirmABAId === textInputStatus.error) {
            setErrorMessageConfirmABAId('')
            setInputStatusConfirmABAId(textInputStatus.default)
        }

        return true
    }

    const validateNPINumber = () => {
        if (confirmNPINumber?.length < 1 || !ValidationHelpers.validateNumbersOnly(confirmNPINumber)) {
            setInputStatusNPINumber(textInputStatus.error)
            setErrorMessageNPINumber('Please enter a valid NPI Number')
            return false
        }
        if (inputStatusNPINumber === textInputStatus.error) {
            setInputStatusNPINumber(textInputStatus.default)
            setErrorMessageNPINumber('')
        }

        return true
    }

    const validateNPINumberConfirmation = () => {
        if (
            confirmNPINumber?.length < 1 ||
            !ValidationHelpers.validateNumbersOnly(confirmNPINumber) ||
            confirmNPINumber !== npiNumber
        ) {
            setInputStatusConfirmNPINumber(textInputStatus.error)
            setErrorMessageConfirmNPINumber('Please enter a matching NPI Number')
            return false
        }

        if (confirmNPINumber === npiNumber && inputStatusConfirmNPINumber === textInputStatus.error) {
            setErrorMessageConfirmNPINumber('')
            setInputStatusConfirmNPINumber(textInputStatus.default)
        }

        return true
    }

    const validateDateOfBirth = () => {
        if (!ValidationHelpers.validateDate(dateOfBirth)) {
            setErrorMessageDateOfBirth('Please enter a valid date')
            setInputStatusDateOfBirth(textInputStatus.error)
            return false
        }

        if (inputStatusDateOfBirth === textInputStatus.error) {
            setErrorMessageDateOfBirth('')
            setInputStatusDateOfBirth(textInputStatus.default)
        }

        return true
    }

    const resetInputs = () => {
        const defaultStatus = textInputStatus.default
        // resetting input values
        setSSN(null)
        setConfirmSSN(null)
        setABAId(null)
        setConfirmABAId(null)
        setNPINumber(null)
        setConfirmNPINumber(null)
        setDateOfBirth('')

        // resetting error messages
        setErrorMessageSSN('')
        setErrorMessageConfirmSSN('')
        setErrorMessageABAId('')
        setErrorMessageConfirmABAId('')
        setErrorMessageNPINumber('')
        setErrorMessageConfirmNPINumber('')
        setErrorMessageDateOfBirth('')

        // resetting input statuses
        setInputStatusSSN(defaultStatus)
        setInputStatusConfirmSSN(defaultStatus)
        setInputStatusABAId(defaultStatus)
        setInputStatusConfirmABAId(defaultStatus)
        setInputStatusNPINumber(defaultStatus)
        setInputStatusConfirmNPINumber(defaultStatus)
        setInputStatusDateOfBirth(defaultStatus)
    }
    return (
        <div className={backgroundGray} style={{height: '100vh'}}>
            <ABAHeader text="Enrollment" />
            <ABAContainer label="ENROLLMENT IDENTIFICATION" loading={loading}>
                <div style={{paddingBottom: 30, paddingTop: 20}}>
                    <div
                        style={{
                            width: 400,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            paddingBottom: 20,
                        }}
                    >
                        {errorMessage && <ABAAlert text={errorMessage} style={{marginBottom: 20}} />}
                        <span style={{marginRight: 40}}>
                            <ABARadio
                                label="SSN"
                                value="ssn"
                                radioId="ssn-enroll"
                                checked={enrollmentInputs.ssn}
                                onChange={() => {
                                    setEnrollmentInputs({
                                        ssn: true,
                                        abaId: false,
                                        npiNumber: false,
                                    })
                                    resetInputs()
                                }}
                            />
                        </span>

                        <span style={{marginRight: 40}}>
                            <ABARadio
                                label="ABA ID"
                                value="abaId"
                                radioId="abaId-enroll"
                                checked={enrollmentInputs.abaId}
                                onChange={() => {
                                    setEnrollmentInputs({
                                        ssn: false,
                                        abaId: true,
                                        npiNumber: false,
                                    })
                                    resetInputs()
                                }}
                            />
                        </span>
                        <span style={{marginRight: 40}}>
                            <ABARadio
                                label="NPI Number"
                                value="npiNumber"
                                radioId="npi-enroll"
                                checked={enrollmentInputs.npiNumber}
                                onChange={() => {
                                    setEnrollmentInputs({
                                        ssn: false,
                                        abaId: false,
                                        npiNumber: true,
                                    })
                                    resetInputs()
                                }}
                            />
                        </span>
                    </div>

                    <div style={{width: 400, marginLeft: 'auto', marginRight: 'auto'}}>
                        {enrollmentInputs.ssn && (
                            <div>
                                <TextInput
                                    placeholder="SSN (no dashes (-) or spaces)"
                                    onChange={(event) => {
                                        if (
                                            inputStatusSSN === textInputStatus.error &&
                                            event.target.value.length === 9
                                        ) {
                                            setInputStatusSSN(textInputStatus.default)
                                        }
                                        setSSN(event.target.value.replace(/\D/, ''))
                                    }}
                                    value={ssn}
                                    name="ssnInput"
                                    label="SSN"
                                    minLength={9}
                                    maxLength={9}
                                    returnKeyType="next"
                                    textInputStatus={inputStatusSSN}
                                    errorMessage={errorMessageSSN}
                                    required
                                />
                                <TextInput
                                    placeholder="Confirm SSN (no dashes (-) or spaces)"
                                    onChange={(event) => {
                                        if (
                                            inputStatusConfirmSSN === textInputStatus.error &&
                                            event.target.value === ssn
                                        ) {
                                            setInputStatusConfirmSSN(textInputStatus.default)
                                        }
                                        setConfirmSSN(event.target.value.replace(/\D/, ''))
                                    }}
                                    value={confirmSSN}
                                    name="confirmSSN"
                                    label="Confirm SSN"
                                    minLength={9}
                                    maxLength={9}
                                    returnKeyType="next"
                                    textInputStatus={inputStatusConfirmSSN}
                                    errorMessage={errorMessageConfirmSSN}
                                    required
                                />
                            </div>
                        )}

                        {enrollmentInputs.abaId && (
                            <div>
                                <TextInput
                                    placeholder="ABA ID (no dashes (-) or spaces)"
                                    onChange={(event) => {
                                        if (
                                            inputStatusABAId === textInputStatus.error &&
                                            event.target.value.length === 8
                                        ) {
                                            setInputStatusABAId(textInputStatus.default)
                                        }
                                        setABAId(event.target.value.replace(/\D/, ''))
                                    }}
                                    value={abaId}
                                    name="abaId"
                                    label="ABA ID"
                                    minLength={8}
                                    maxLength={8}
                                    returnKeyType="next"
                                    textInputStatus={inputStatusABAId}
                                    errorMessage={errorMessageABAId}
                                    required
                                />
                                <TextInput
                                    placeholder="Confirm ABA ID (no dashes (-) or spaces)"
                                    onChange={(event) => {
                                        if (
                                            inputStatusConfirmABAId === textInputStatus.error &&
                                            event.target.value === abaId
                                        ) {
                                            setInputStatusConfirmABAId(textInputStatus.default)
                                        }
                                        setConfirmABAId(event.target.value.replace(/\D/, ''))
                                    }}
                                    value={confirmABAId}
                                    name="confirmABAId"
                                    label="Confirm ABA ID"
                                    minLength={8}
                                    maxLength={8}
                                    returnKeyType="next"
                                    textInputStatus={inputStatusConfirmABAId}
                                    errorMessage={errorMessageConfirmABAId}
                                    required
                                />
                            </div>
                        )}

                        {enrollmentInputs.npiNumber && (
                            <div>
                                <TextInput
                                    placeholder="NPI Number (no dashes (-) or spaces)"
                                    onChange={(event) => {
                                        if (
                                            inputStatusNPINumber === textInputStatus.error &&
                                            event.target.value.length >= 1
                                        ) {
                                            setInputStatusNPINumber(textInputStatus.default)
                                        }
                                        setNPINumber(event.target.value.replace(/\D/, ''))
                                    }}
                                    value={npiNumber}
                                    name="npiNumber"
                                    label="NPI Number"
                                    maxLength={10}
                                    returnKeyType="next"
                                    textInputStatus={inputStatusNPINumber}
                                    errorMessage={errorMessageNPINumber}
                                    required
                                />
                                <TextInput
                                    placeholder="Confirm NPI Number (no dashes (-) or spaces)"
                                    onChange={(event) => {
                                        if (
                                            inputStatusConfirmNPINumber === textInputStatus.error &&
                                            event.target.value === npiNumber
                                        ) {
                                            setInputStatusConfirmNPINumber(textInputStatus.default)
                                        }
                                        setConfirmNPINumber(event.target.value.replace(/\D/, ''))
                                    }}
                                    value={confirmNPINumber}
                                    name="confirmNPINumber"
                                    label="Confirm NPI Number"
                                    maxLength={10}
                                    returnKeyType="next"
                                    textInputStatus={inputStatusConfirmNPINumber}
                                    errorMessage={errorMessageConfirmNPINumber}
                                    required
                                />
                            </div>
                        )}

                        <TextInput
                            placeholder="Date of Birth (mm/dd/yyyy)"
                            onChange={(event) => {
                                if (
                                    inputStatusDateOfBirth === textInputStatus.error &&
                                    event.target.value.length === 10 &&
                                    ValidationHelpers.validateDate(event.target.value)
                                ) {
                                    setInputStatusDateOfBirth(textInputStatus.default)
                                }
                                setDateOfBirth(
                                    HelperMethods.dateInputMask(event.target.value, dateOfBirth),
                                )
                            }}
                            value={dateOfBirth}
                            name="dateOfBirth"
                            label="Date of Birth"
                            maxLength={10}
                            returnKeyType="next"
                            textInputStatus={inputStatusDateOfBirth}
                            errorMessage={errorMessageDateOfBirth}
                            required
                        />
                        <div>
                            <ABARequiredField />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Button
                                    style={{width: 125}}
                                    onClick={() => {
                                        navigate('/residentListing')
                                    }}
                                    variant={StyleVariant.OUTLINED}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{width: 125}}
                                    variant={StyleVariant.FILLED}
                                    rightIcon={
                                        <FontAwesomeIcon
                                            icon={'angle-right'}
                                            style={{
                                                color: 'white',
                                            }}
                                        />
                                    }
                                    onClick={async () => {
                                        const enrollmentInfo = {
                                            SSN: ssn,
                                            BirthDate: dateOfBirth,
                                            ABAId: abaId ? Number(abaId) : null,
                                            NPI: npiNumber,
                                        }

                                        const navigationInfo = {
                                            pathname: '/enrollment',
                                            state: enrollmentInfo,
                                        }
                                        validateDateOfBirth()

                                        if (enrollmentInputs.ssn) {
                                            validateSSN()
                                            validateSSNConfirmation()
                                            if (
                                                validateSSN() &&
                                                validateSSNConfirmation() &&
                                                validateDateOfBirth()
                                            ) {
                                                setLoading(true)
                                                const {data} = await checkEnrollment()
                                                checkEnrollmentAndDOB('SSN', data, navigationInfo)
                                            }
                                        }
                                        if (enrollmentInputs.abaId) {
                                            validateABAId()
                                            validateABAIdConfirmation()
                                            if (
                                                validateABAId() &&
                                                validateABAIdConfirmation() &&
                                                validateDateOfBirth()
                                            ) {
                                                setLoading(true)

                                                const {data} = await checkEnrollment()
                                                if (data) {
                                                    checkEnrollmentAndDOB('ABA ID', data, navigationInfo)
                                                } else {
                                                    setLoading(false)
                                                    alert(
                                                        'We did not find a physician with that ABA ID number in our database. Please try again or check with the physician.',
                                                    )
                                                    setEnrollmentInputs({
                                                        ssn: true,
                                                        abaId: false,
                                                        npiNumber: false,
                                                    })
                                                    resetInputs()
                                                }
                                            }
                                        }
                                        if (enrollmentInputs.npiNumber) {
                                            validateNPINumber()
                                            validateNPINumberConfirmation()
                                            if (
                                                validateNPINumber() &&
                                                validateNPINumberConfirmation() &&
                                                validateDateOfBirth()
                                            ) {
                                                setLoading(true)
                                                const {data} = await checkEnrollment()
                                                if (data) {
                                                    checkEnrollmentAndDOB(
                                                        'NPI Number',
                                                        data,
                                                        navigationInfo,
                                                    )
                                                } else {
                                                    setLoading(false)
                                                    alert(
                                                        'We did not find a physician with that NPI number in our database. Please try again or check with the physician.',
                                                    )
                                                    setEnrollmentInputs({
                                                        ssn: true,
                                                        abaId: false,
                                                        npiNumber: false,
                                                    })
                                                    resetInputs()
                                                }
                                            }
                                        }
                                    }}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </ABAContainer>
        </div>
    )
}
