import axios from 'axios'
import EventBus from '../libs/EventBus'
import {throttle} from 'lodash'
const RTIDAPI = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    Headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
    },
    withCredentials: true,
})

export const setProgramId = (programId) => {
    RTIDAPI.defaults.headers.common.programId = programId
}

export const setProgramHeaderKey = (programHeaderKey) => {
    RTIDAPI.defaults.headers.common.ProgramHeaderKey = programHeaderKey
}

const handleLogout = (error) => {
    const {status} = error.response
    if (status === 401) {
        EventBus.dispatch('logout', 'unauthorized')
    }
    if (status === 503) {
        EventBus.dispatch('logout', error.response.data.Message)
    }
}

const throttledHandleLogout = throttle((err) => handleLogout(err), 5000, {
    leading: true,
    trailing: false,
})

RTIDAPI.interceptors.response.use(
    (response) => response,
    (error) => {
        if (
            error.response?.config?.url !== 'authentication/check' &&
            (error.response?.status === 401 || error.response?.status === 503)
        ) {
            throttledHandleLogout(error)
        }
        return Promise.reject(error)
    },
)

axios.defaults.timeoutErrorMessage = 'timeout'

export default RTIDAPI
