import React, {useState} from 'react'
import {Button, Modal, StyleVariant} from 'aba-ui'
import {ColorEnums} from '../../../helpers/GeneralEnums'
import styles from './PasswordModal.module.css'
import RTIDAPI from '../../../helpers/AxiosInstance'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye} from '@fortawesome/free-solid-svg-icons'

const PasswordModal = ({onCancel, nameDisplay, passwordChangeDate, programRecord}) => {
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [showHidePassword, setShowHidePassord] = useState(false)
    const [showHideConfirmPassword, setShowHideConfirmPassord] = useState(false)
    const [checks, setChecks] = useState({
        upperCheck: false,
        minCheck: false,
        specialCheck: false,
        numCheck: false,
    })

    const {
        valid,
        invalid,
        modal,
        content,
        titleBlock,
        input,
        i,
        buttonContainer,
        errorContainer,
        error,
    } = styles

    const eye = <FontAwesomeIcon icon={faEye} />
    const regex = new RegExp(
        '^(?=.*?[A-Za-z])(?=.*?[0-9]).{7,}$' ||
            '^(?=.*?[A-Z])(?=.*?[!@#$%^&*])(?=.*?[0-9]).{7,}$',
    )

    const togglePassword = () => {
        setShowHidePassord(!showHidePassword)
    }
    const togglePasswordConfirm = () => {
        setShowHideConfirmPassord(!showHideConfirmPassword)
    }

    const handleOnChange = (e) => {
        setPassword(e.target.value)
    }

    const passwordUpdate = async () => {
        const UserName = programRecord
        await RTIDAPI.put('/account/password', {UserName, Password: password})
    }

    const passwordColorChange = (e) => {
        const {value} = e.target
        const upperCheck = /[A-Z]/.test(value)
        const numCheck = /[0-9]/.test(value)
        const minCheck = value.length > 6
        const specialCheck = /[!@#$%^&*]/.test(value) || /[a-z]/.test(value)
        setChecks({
            upperCheck,
            numCheck,
            minCheck,
            specialCheck,
        })
    }

    const handleSubmit = () => {
        if (password === '' || confirmPassword === '') {
            alert('Empty field(s)')
        } else if (!regex.test(password)) {
            alert('The password entered does not match requirements.')
        } else if (password !== confirmPassword) {
            alert('The passwords do not match.')
        } else {
            passwordUpdate()
            alert('Password changed successfully!')
            onCancel(false)
        }
    }

    const MessageList = ({upper, num, min, special}) => {
        return (
            <div className="message">
                <div style={{fontSize: '17px'}}> New Password must:</div>
                <ul style={{marginLeft: '7%', fontSize: '15px'}}>
                    <li className={min}>Be Between 7-20 characters</li>
                    <li className={num}>Include at least one number</li>
                    <li className={upper}>Have at least one upper case letter</li>
                    <li className={special}>
                        Have at least one lower case letter or special character
                    </li>
                </ul>
            </div>
        )
    }
    return (
        <Modal>
            {passwordChangeDate !== 'null' ? (
                <div className={modal}>
                    <div className={content}>
                        <div className={titleBlock}>
                            <div
                                style={{
                                    fontWeight: 'bold',
                                }}
                            >
                                Change Password for {nameDisplay}
                            </div>
                            <div>Password was last changed on {passwordChangeDate}</div>
                        </div>
                        <MessageList
                            min={checks.minCheck ? valid : invalid}
                            num={checks.numCheck ? valid : invalid}
                            upper={checks.upperCheck ? valid : invalid}
                            special={checks.specialCheck ? valid : invalid}
                        />
                        <div style={{marginTop: '4%'}}>
                            <div style={{display: 'flex'}}>
                                <input
                                    name="password"
                                    required
                                    type={showHidePassword ? 'text' : 'password'}
                                    label="New Password"
                                    onChange={handleOnChange}
                                    onKeyUp={passwordColorChange}
                                    value={password}
                                    id="password"
                                    maxLength={20}
                                    className={input}
                                    placeholder="New Password"
                                />
                                <i className={i} onClick={togglePassword}>
                                    {eye}
                                </i>
                            </div>
                            <div style={{display: 'flex'}}>
                                <input
                                    name="programConfirm"
                                    placeholder="Confirm Password"
                                    id="passwordConfirm"
                                    required
                                    type={showHideConfirmPassword ? 'text' : 'password'}
                                    value={confirmPassword}
                                    maxLength={20}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className={input}
                                />
                                <i className={i} onClick={togglePasswordConfirm}>
                                    {eye}
                                </i>
                            </div>
                        </div>

                        <div className={buttonContainer}>
                            <div style={{marginRight: '25%'}}>
                                <Button
                                    variant={StyleVariant.FILLED}
                                    onClick={() => onCancel(false)}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div style={{marginLeft: '25%'}}>
                                <Button
                                    variant={StyleVariant.FILLED}
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={errorContainer}>
                    <div className={error}>
                        You can not change the password for this program contact because
                        there is an issue with their login data. Please contact the ABA.
                    </div>
                    <Button onClick={() => onCancel(false)}>Close</Button>
                </div>
            )}
        </Modal>
    )
}

export default PasswordModal
