import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import getResidentListingData from './ResidentListingLogic'
import ABAContainer from '../../components/ABAComponents/ABAContainer/ABAContainer'
import ResidentListingTableGrid from './ResidentListingTableGrid'
import Scorecard from '../../components/Scorecard/Scorecard'
import useUser from '../../../libs/hooks/useUser'
import useTraining from '../../../libs/hooks/useTraining'
import {LoadingOverlay} from 'aba-ui'
import {LoadResidentListing} from '../../../redux/Actions/ResidentListingActionCreators'

export default function ResidentListing(props) {
    const dispatch = useDispatch()
    const {userData} = useUser()
    const {TrainingRecordsList, TrainingSummaryList} = useTraining({
        loadSummary: true,
        loadTraining: true,
    })
    const ResidentListingList = useSelector(({residentListing}) => residentListing)
    const location = useLocation()
    useEffect(() => {
        const fetchResidentListingData = async () => {
            dispatch(getResidentListingData(userData.ProgramId))
        }
        dispatch(LoadResidentListing())
        fetchResidentListingData()
    }, [userData.ProgramId, dispatch])

    return (
        <div>
            {ResidentListingList.Loading && <LoadingOverlay />}
            <Scorecard />
            <ABAContainer label="Current Listing">
                <ResidentListingTableGrid
                    dataset={ResidentListingList.ResidentListing}
                    loading={ResidentListingList.Loading}
                    trainingRecords={TrainingRecordsList.TrainingRecords}
                    trainingSummary={TrainingSummaryList.TrainingSummary}
                    residentEnrollmentId={
                        location.state && location.state.residentEnrollmentId
                            ? location.state.residentEnrollmentId
                            : null
                    }
                />
            </ABAContainer>
        </div>
    )
}
