import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import '../styles/ite.css'
import ABAHeader from '../components/ABAComponents/ABAHeader'
import ABAContainer from '../components/ABAComponents/ABAContainer/ABAContainer'
import ProctorModal from '../components/Modal/ProctorModal'
import RTIDAPI from '../../helpers/AxiosInstance'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {GeneralEnums, ColorEnums} from '../../helpers/GeneralEnums'
import {Button, StyleVariant} from 'aba-ui'
import useUser from '../../libs/hooks/useUser'
import {LoadingOverlay} from 'aba-ui'
import HelperMethods from '../../helpers/HelperMethods'

export default function InTrainingExam() {
    const {userData} = useUser()
    const navigate = useNavigate()
    const {Draft, Invoiced, Paid} = GeneralEnums.rosterStatusCodes

    const [loading, setLoading] = useState(true)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [lateStartDate, setLateStartDate] = useState(null)
    const [lateEndDate, setLateEndDate] = useState(null)
    const [sessionName, setSessionName] = useState(null)
    const [eventName, setEventName] = useState(null)
    const [eventId, setEventId] = useState(null)
    const [rosters, setRosters] = useState([])
    const [sessionId, setSessionId] = useState(null)
    const [showNewProctorModal, setShowNewProctorModal] = useState(false)
    const [showUpdateProctorModal, setShowUpdateProctorModal] = useState(false)
    const [noExamScheduled, setNoExamScheduled] = useState(false)
    const [activeProctor, setActiveProctor] = useState(null)

    const getExamSession = (programId) => {
        return new Promise((resolve) => {
            RTIDAPI.get(`ite/getExamSession/${programId}`, {})
                .then((response) => resolve(response.data))
                .catch((err) => err)
        })
    }

    const getRosters = (sessionId) => {
        return new Promise((resolve) => {
            RTIDAPI.get(`ite/rosters/${sessionId}`)
                .then((response) => resolve(response.data))
                .catch((err) => err)
        })
    }

    const getProctor = () => {
        return new Promise((resolve) => {
            RTIDAPI.get('ite/proctor')
                .then((response) => resolve(response.data))
                .catch((err) => err)
        })
    }

    const createProctor = async (proctor) => {
        try {
            setLoading('Saving Proctor')
            setShowNewProctorModal(false)
            const newProctor = await RTIDAPI.post('ite/proctor', proctor)

            setActiveProctor(newProctor.data)
            setLoading(null)
        } catch (err) {
            alert(err)
        }
    }

    const updateProctor = async (proctor) => {
        try {
            setLoading('Saving Proctor')
            setShowUpdateProctorModal(false)
            await RTIDAPI.put('ite/proctor', proctor)

            setActiveProctor(proctor)
            setLoading(null)
        } catch (err) {
            alert(err)
        }
    }

    const createRoster = async () => {
        try {
            if (rosters.some((r) => r.StatusCode === Draft)) {
                return
            }

            setLoading('Creating roster')
            const config = {
                params: {
                    eventId,
                    programId: userData.ProgramId,
                    sessionId,
                    isCanadian: userData.IsCanadianProgram,
                    canadianRosterExist: userData.IsCanadianProgram && rosters?.length > 0,
                },
            }

            const {data} = await RTIDAPI.post('ite/createRoster', {}, config)
            setRosters(data)
            setLoading(null)
        } catch (error) {
            alert(error)
        }
    }

    useEffect(() => {
        const getData = async () => {
            const sessionData = await getExamSession(userData.ProgramId)
            if (!sessionData) {
                setNoExamScheduled(true)
                setLoading(false)
            } else {
                setStartDate(sessionData.RegistrationStartDate)
                setEndDate(sessionData.RegistrationEndDate)
                setLateStartDate(sessionData.LateRegistrationStartDate)
                setLateEndDate(sessionData.LateRegistrationEndDate)
                setSessionName(sessionData.SessionName)
                setEventName(sessionData.EventName)
                setEventId(sessionData.EventId)
                setSessionId(sessionData.SessionId)

                const rosterData = await getRosters(sessionData.SessionId)
                setRosters(rosterData)
            }

            const proctor = await getProctor()
            setActiveProctor(proctor)

            setLoading(false)
        }

        getData()
    }, [])

    const rosterStatus = (rs) => {
        switch (rs) {
            case Draft:
                return 'Draft'
            case Invoiced:
                return 'Invoiced'
            case Paid:
                return 'Paid'
        }
    }

    const renderSession = () => {
        if (noExamScheduled) {
            return (
                <div style={{paddingBottom: '20px'}}>
                    <span style={{fontWeight: 'bold'}}>No ITE Exam Scheduled</span>
                </div>
            )
        }

        return (
            <div>
                <span style={{fontWeight: 'bold', paddingRight: '20px'}}>Session: </span>
                <span>{sessionName}</span>
            </div>
        )
    }
    const renderRosters = () => {
        if (rosters?.length > 0) {
            return rosters.map((roster) => {
                return (
                    <tr
                        className="rosterTableRow"
                        key={roster.RosterId}
                        onClick={() => {
                            navigate('/iteRoster', {
                                state: {
                                    rosterId: roster?.RosterId,
                                    sessionId,
                                    rosterStatusCode: roster?.StatusCode,
                                    eventName,
                                    sessionName,
                                    eventId,
                                    invoiceId: roster?.InvoiceId,
                                },
                            })
                        }}
                    >
                        <td>{roster.SessionName}</td>
                        <td>{roster.Count}</td>
                        <td>{roster.Capacity ? roster.Capacity : '-'}</td>
                        <td>{rosterStatus(roster.StatusCode)}</td>
                        <td>
                            <FontAwesomeIcon icon="angle-right" style={{color: ColorEnums.danube}} />
                        </td>
                    </tr>
                )
            })
        } else {
            return (
                <tr>
                    <td style={{textAlign: 'center', paddingLeft: 0}} colSpan={5}>
                        No Rosters have been created for this session
                    </td>
                </tr>
            )
        }
    }

    const renderCreateRosterButton = () => {
        const today = HelperMethods.formatDate(new Date())

        // Check if any rosters exist
        if (rosters?.length > 0) {
            // Check if any draft rosters exist
            if (rosters.some((r) => r.StatusCode === Draft)) {
                return false // Draft roster already exists
            }
        }

        if (
            (startDate &&
                new Date(today) >= new Date(startDate) &&
                new Date(today) <= new Date(endDate)) ||
            (lateStartDate &&
                new Date(today) >= new Date(lateStartDate) &&
                new Date(today) <= new Date(lateEndDate))
        ) {
            return true
        }

        return false
    }

    return (
        <div>
            {loading && <LoadingOverlay title={loading} />}
            {showNewProctorModal && (
                <ProctorModal toggleModal={setShowNewProctorModal} saveFunction={createProctor} />
            )}
            {showUpdateProctorModal && (
                <ProctorModal
                    toggleModal={setShowUpdateProctorModal}
                    existingProctor={activeProctor}
                    saveFunction={updateProctor}
                />
            )}
            <ABAHeader text="In-Training Exam" />
            <ABAContainer label={eventName}>
                {!noExamScheduled && (
                    <div style={{textAlign: 'right', marginRight: 65}}>
                        <div>
                            <div className="proctorButton" onClick={() => setShowNewProctorModal(true)}>
                                New Proctor
                            </div>
                        </div>
                        {activeProctor && (
                            <div>
                                <div
                                    className="proctorButton"
                                    onClick={() => setShowUpdateProctorModal(true)}
                                >
                                    Update Proctor
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <div style={{display: 'flex', justifyContent: 'center', gap: '80px'}}>
                    <div className="registrationContainer">
                        <div>Registration</div>
                        <div>
                            {startDate} - {endDate}
                        </div>
                    </div>
                    <div className="registrationContainer">
                        <div>Late Registration</div>
                        <div>
                            {lateStartDate} - {lateEndDate}
                        </div>
                    </div>
                </div>
                <section
                    style={{
                        marginTop: '40px',
                        textAlign: 'center',
                    }}
                >
                    {renderSession()}
                </section>
                {!noExamScheduled && (
                    <section
                        style={{
                            marginTop: '40px',
                            textAlign: 'center',
                            paddingBottom: '30px',
                        }}
                    >
                        <h2>Rosters</h2>
                        <table className="rosterTable">
                            <thead>
                                <tr className="rosterTableHeader">
                                    <th>Session Name</th>
                                    <th>In Roster</th>
                                    <th>Capacity</th>
                                    <th>Status</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>{renderRosters()}</tbody>
                        </table>
                        {renderCreateRosterButton() && (
                            <Button
                                variant={StyleVariant.FILLED}
                                style={{width: 160}}
                                onClick={createRoster}
                                disabled={rosters.some((r) => r.StatusCode === Draft)}
                                disabledText="A draft roster already exists"
                            >
                                Create Roster
                            </Button>
                        )}
                    </section>
                )}
            </ABAContainer>
        </div>
    )
}
