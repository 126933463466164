import React, {useEffect, useState} from 'react'
import AddRemoveTable from '../components/AddRemoveTable/AddRemoveTable'
import ABAHeader from '../components/ABAComponents/ABAHeader'
import ABAAlert from '../components/ABAComponents/ABAAlert'
import {LoadingOverlay} from 'aba-ui'
import RTIDAPI from '../../helpers/AxiosInstance'
import {ColorEnums} from '../../helpers/GeneralEnums'

export default function MassCompleteTraining() {
    const [loading, setLoading] = useState('Loading Training Records')
    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)
    const [dataObjects, setDataObjects] = useState(null)
    const [topTableData, setTopTableData] = useState(null)
    const [bottomTableData, setBottomTableData] = useState(null)

    useEffect(() => {
        fetchTrainingRecords()
    }, [])

    const fetchTrainingRecords = async () => {
        try {
            setLoading('Loading Training Records')
            const {data} = await RTIDAPI.get('utilities/massCompleteTrainingDetails')
            setDataObjects(data)
            const dataArray = data.map((trainingRecord) => {
                const {ABAID, Last4SSN, FullName, ProgramYear} = trainingRecord
                return [ABAID, Last4SSN, FullName, ProgramYear]
            })
            setTopTableData(dataArray)
            setBottomTableData([])
            setLoading(null)
            setError(null)
        } catch (error) {
            setLoading(null)
            setError('An error occurred while fetching roster')
        }
    }
    const completeTrainingRecords = async (trainingRecords) => {
        try {
            setLoading('Completing your roster')
            const approvedAbaIDs = trainingRecords.map((record) => {
                return record[0]
            })
            const recordsToComplete = dataObjects.filter((trainingRecord) => {
                return approvedAbaIDs.includes(trainingRecord.ABAID)
            })
            const {data} = await RTIDAPI.post(
                'utilities/massCompleteTrainingDetails',
                recordsToComplete,
            )
            setMessage(data)
            fetchTrainingRecords()
        } catch (error) {
            setLoading(null)
            setError('An error occurred while completing roster')
        }
    }

    return (
        <>
            {loading && <LoadingOverlay title={loading} />}
            <ABAHeader text="Training Mass Complete" />
            {error && <ABAAlert text={error} style={{width: 400, margin: 'auto'}} />}
            {message && (
                <ABAAlert
                    text={message}
                    style={{
                        width: 400,
                        margin: 'auto',
                        backgroundColor: ColorEnums.aquaHaze,
                        border: `1px solid ${ColorEnums.cloudBurst}`,
                        color: ColorEnums.cloudBurst,
                    }}
                />
            )}
            <AddRemoveTable
                headers={['ABA ID', 'Last 4 SSN', 'Doctor Name', 'Program Year']}
                colWidths={['20%', '20%', '40%', '20%']}
                topTableLabel="RECORDS TO BE COMPLETED"
                bottomTableLabel="RECORDS NOT TO BE COMPLETED"
                initialTopData={topTableData}
                initialBottomData={bottomTableData}
                sortColumn={2}
                onSubmit={completeTrainingRecords}
                submitLabel="Complete Training"
                sortableColumns={[2, 3]}
                filterIndex={3}
            />
        </>
    )
}
