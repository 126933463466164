import * as ActionTypes from './ActionTypes'

export const TrainingSummarySuccess = (trainingSummary) => {
    return {
        type: ActionTypes.TRAININGSUMMARY_SUCCESS,
        payload: trainingSummary,
    }
}

export const TrainingSummaryFailed = (errorMessage) => {
    return {
        type: ActionTypes.TRAININGSUMMARY_FAILED,
        payload: {errorMessage},
    }
}

export const UpdateTrainingSummary = (trainingSummary) => {
    return {
        type: ActionTypes.TRAININGSUMMARY_UPDATE,
        payload: trainingSummary,
    }
}
