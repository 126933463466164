import RTIDAPI from '../../helpers/AxiosInstance'
import * as ActionTypes from './ActionTypes'

export const addOptionSets = (optionSets) => ({
    type: ActionTypes.ADD_OPTION_SETS,
    payload: optionSets,
})

export const setWindowFocused = (isWindowFocused) => ({
    type: ActionTypes.WINDOW_FOCUSED,
    payload: isWindowFocused,
})

export const getOptionSets = () => async (dispatch) => {
    try {
        const response = await Promise.all([
            RTIDAPI.get(`lookups/isCertifying`),
            RTIDAPI.get(`lookups/countries`),
            RTIDAPI.get(`lookups/genders`),
            RTIDAPI.get(`lookups/medicalDegrees`),
            RTIDAPI.get(`enrollment/getMedicalSchools/1`),
            RTIDAPI.get(`enrollment/getMedicalSchools/2`),
            RTIDAPI.get(`lookups/medicalSchoolCountries`),
            RTIDAPI.get(`lookups/primarySpecialties`),
            RTIDAPI.get(`lookups/suffixes`),
            RTIDAPI.get(`lookups/trackTypes`),
            RTIDAPI.get(`lookups/priorCBProgramTypes`),
            RTIDAPI.get('lookups/willcontinue'),
            RTIDAPI.get('lookups/priortrainings'),
            RTIDAPI.get('lookups/trainingTypes'),
            RTIDAPI.get('lookups/rotationTypes'),
            RTIDAPI.get(`lookups/states`),
            RTIDAPI.get(`lookups/programYears`),
            RTIDAPI.get(`lookups/loaRelatedToEssentialAttribute`),
            RTIDAPI.get('lookups/programTypes'),
        ])

        const optionSets = {
            isCertifying: response[0].data,
            countries: response[1].data,
            genders: response[2].data,
            medicalDegrees: response[3].data,
            usMedicalSchools: response[4].data,
            canadianMedicalSchools: response[5].data,
            medicalSchoolCountries: response[6].data,
            primarySpecialties: response[7].data,
            suffixes: response[8].data,
            trackTypes: response[9].data,
            priorCBProgramTypes: response[10].data,
            willContinue: response[11].data,
            priorTrainings: response[12].data,
            trainingTypes: response[13].data,
            rotationTypes: response[14].data,
            states: response[15].data,
            programYears: response[16].data,
            loaRelatedEssential: response[17].data,
            programTypes: response[18].data,
        }

        dispatch(addOptionSets(optionSets))
    } catch (error) {
        console.log(error)
    }
}
