import {legacy_createStore as createStore, applyMiddleware} from 'redux'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // default to localStorage for web
import {thunk} from 'redux-thunk'
import logger from 'redux-logger'
import user from '../reducers/user'
import expandedResident from '../reducers/expandedResident'
import scorecard from '../reducers/scorecard'
import optionSets from '../reducers/optionSets'
import residentListing from '../reducers/residentListing'
import trainingRecords from '../reducers/trainingRecords'
import trainingSummary from '../reducers/trainingSummary'
import encryptor from './transforms'
import global from '../reducers/global'
import {combineReducers} from 'redux'

const rootReducer = combineReducers({
    expandedResident,
    user,
    optionSets,
    scorecard,
    residentListing,
    trainingRecords,
    trainingSummary,
    global,
})

const persistConfig = {
    key: 'root',
    storage,
    debug: true,
    transforms: [encryptor],
    blacklist: ['global'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const ConfigureStore = () => {
    const store = createStore(persistedReducer, applyMiddleware(thunk))
    const persistor = persistStore(store)
    return {store, persistor}
}

export default ConfigureStore
