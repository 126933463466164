const GeneralEnums = {
    roleEnums: {
        dilomateInMOCA: 1,
        nonDiplomate: 2,
        diplomateNotInMOCA: 3,
        retiredRevoked: 4,
    },

    cert_countryIds: {
        canada: '274dbeb6-99d0-de11-8b2b-001f29d17639',
        usa: 'e94dbeb6-99d0-de11-8b2b-001f29d17639',
    },

    eventStatus: {
        scheduled: 914110001,
    },

    productIds: {
        basic: '462b5ad6-3325-e411-a7d3-005056ab0010',
        advanced: '442b5ad6-3325-e411-a7d3-005056ab0010',
    },

    medicalSchoolCountryIds: {
        usa: 1,
        canada: 2,
        international: 3,
        unspecified: 4,
    },

    residentEnrollmentStatusCodes: {
        Approved: 167340004,
        CancelledABA: 167340007,
        CancelledProgram: 167340001,
        Draft: 1,
        Enrolled: 167340003,
        Future008: 167340008,
        Graduated: 2,
        LeftTraining: 167340000,
        Registered: 167340002,
        Released: 167340005,
        Validated: 167340009,
    },

    feedbackStatusCodes: {
        InReview: 167340002,
        Resolved: 167340005,
        Responded: 167340004,
    },

    programTypes: {
        primary: 513,
    },

    residentEnrollmentStateCode: {
        Active: 0,
        Inactive: 1,
    },

    trainingStatusCodes: {
        Approved: 167340000,
        CancelledABA: 167340003,
        CancelledPgm: 2,
        Credited: 167340004,
        Draft: 1,
        NeedsAction: 167340010,
        NoCredit: 167340011,
        Prospective: 167340012,
        Recovered: 167340008,
        Released: 167340001,
        Reviewed: 167340002,
        UnsatRecoverable: 167340007,
        Unsatisfactory: 167340005,
        Validated: 167340009,
    },

    trainingPeriodStatusCodes: {
        Active: 167340001,
        Cancelled: 167340003,
        Complete: 2,
        Draft: 1,
        Processing: 167340002,
        Verified: 167340000,
    },

    trainingStateCodes: {
        Active: 0,
        Inactive: 1,
    },

    pdirStatusCodes: {
        Acceptable: 167340003,
        Approved: 167340000,
        CancelledABA: 167340005,
        CancelledPgm: 2,
        Draft: 1,
        NeedsAction: 167340007,
        Released: 167340001,
        Reviewed: 167340002,
        Unacceptable: 167340004,
        Validated: 167340006,
    },
    pdirStateCodes: {
        Active: 0,
        Inactive: 1,
    },
    primarySpecialties: {
        anesthesiologyABA: 0,
    },
    rosterStatusCodes: {
        Draft: 1,
        Invoiced: 914110003,
        Paid: 914110004,
    },
    textInputStatus: {
        default: 1,
        standard: 1,
        disabled: 2,
        error: 3,
        success: 4,
    },
    willContinueTrainingOptions: {
        yes: 1,
        noGraduation: 2,
        leftProgram: 3,
    },
    programRoles: {
        DepartmentChair: 1,
        ProgramDirector: 2,
        CCCChair: 3,
        DelegateA: 7,
        DelegateB: 8,
        DelegateC: 9,
        SuperUser: 100,
    },
    trackTypes: {
        AlternateEntryPath: 4,
        Combined: 3,
        Innovative: 2,
        Recertification: 5,
        Standard: 1,
    },
}

// Important!  If anything is added, removed, or changed in ColorEnums, it should also be added/removed/changed in App.css
const ColorEnums = {
    aquaHaze: '#F1F4F5',
    backgroundGray: '#F4F7F8',
    blueGray: '#8DABBC',
    citrus: '#FFC262',
    citrusDark: '#D88400',
    citrusLight: '#FFF0D8',
    cloudBurst: '#002F6C',
    danube: '#418FDE',
    darkGray: '#262626',
    exDarkGray: '#131313',
    exLightGray: '#DCE0E1',
    gray: '#999999',
    indigo: '#6A67CF',
    lightBlue: '#66CCFF',
    lightGray: '#BFC1C2',
    lime: '#3AC67E',
    mdDarkGray: '#5A5B5C',
    midnight: '#16223F',
    pomegranate: '#FB6C7A',
    pomegranateDark: '#B20516',
    pomegranateLight: '#FEE2E4',
    surfGreen: '#75D7A5',
    surfGreenDark: '#308A5C',
    surfGreenLight: '#DCF5E8',
}

export {GeneralEnums, ColorEnums}
