import React, {useState, useEffect} from 'react'
import {ColorEnums} from '../../helpers/GeneralEnums'
import RTIDAPI from '../../helpers/AxiosInstance'
import {Table, TableHead, TableRow, TableCell, TableBody, Button} from '@mui/material'
import ABAAlert from '../components/ABAComponents/ABAAlert'
import useUser from '../../libs/hooks/useUser'
import ABAContainer from '../components/ABAComponents/ABAContainer/ABAContainer'
import PasswordModal from '../components/Modal/PasswordModal'

const headerCellStyle = {
    borderColor: '#418FDE',
    fontSize: 14,
    color: ColorEnums.blueGray,
    fontFamily: 'lato',
}
const bodyCellStyle = {
    color: ColorEnums.cloudBurst,
    fontFamily: 'lato',
}
const RoleStyle = {
    fontSize: 11,
    color: ColorEnums.cloudBurst,
    fontFamily: 'lato',
    fontStyle: 'italic',
}
export default function ProgramContacts() {
    const [programContact, setProgramContact] = useState()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [residentName, setResidentName] = useState('')
    const [programRecord, setProgramRecord] = useState('')
    const [passwordChangeDate, setPasswordChangeDate] = useState('')
    const {
        userData: {UserRole, ProgramId, UserName},
    } = useUser()
    useEffect(() => {
        fetchProgramContacts()
    }, [])
    const fetchProgramContacts = async () => {
        setLoading(true)
        try {
            const response = await RTIDAPI.get(`account/getProgramContacts/${ProgramId}`)
            setProgramContact(response.data)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setError('An error occurred while fetching')
        }
    }
    const canChangePassword = UserRole === 'ProgramDirector' || UserRole === 'SuperUser'
    return (
        <div>
            {error && <ABAAlert text={error} style={{width: 400, margin: 'auto'}} />}
            <ABAContainer label="Program Contact" loading={loading}>
                {show && (
                    <PasswordModal
                        nameDisplay={residentName}
                        onCancel={setShow}
                        passwordChangeDate={passwordChangeDate}
                        programRecord={programRecord}
                    />
                )}
                <Table sx={{margin: 'auto'}} style={{tableLayout: 'fixed'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={headerCellStyle}>Name</TableCell>
                            <TableCell style={headerCellStyle}>Email</TableCell>
                            <TableCell style={headerCellStyle}>Phone Number</TableCell>
                            <TableCell style={headerCellStyle}>Fax</TableCell>
                            {canChangePassword && (
                                <>
                                    <TableCell style={headerCellStyle}>
                                        Login ID
                                    </TableCell>
                                    <TableCell style={headerCellStyle}></TableCell>
                                </>
                            )}
                        </TableRow>
                    </TableHead>

                    {programContact?.length ? (
                        <TableBody>
                            {programContact.map((programContactRecord, i) => {
                                const {
                                    FirstName,
                                    LastName,
                                    RoleName,
                                    Email,
                                    Phone,
                                    Fax,
                                    LastPasswordChangedDate,
                                    LoginRoleValue,
                                    ProgramNumber,
                                    LoginValue,
                                } = programContactRecord

                                return (
                                    <TableRow key={i}>
                                        <TableCell style={bodyCellStyle}>
                                            <div>
                                                {FirstName} {LastName}
                                            </div>

                                            <div style={RoleStyle}>{RoleName}</div>
                                        </TableCell>

                                        <TableCell
                                            style={
                                                (bodyCellStyle,
                                                {overflowWrap: 'break-word'})
                                            }
                                        >
                                            {Email}
                                        </TableCell>

                                        <TableCell style={bodyCellStyle}>
                                            {Phone ? Phone : '--'}
                                        </TableCell>
                                        <TableCell style={bodyCellStyle}>
                                            {Fax ? Fax : '--'}
                                        </TableCell>
                                        {canChangePassword && (
                                            <>
                                                <TableCell style={bodyCellStyle}>
                                                    {LoginValue}
                                                    {ProgramNumber}
                                                    {LoginRoleValue
                                                        ? `-${LoginRoleValue}`
                                                        : ''}
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        color: ColorEnums.danube,
                                                        fontFamily: 'lato',
                                                    }}
                                                >
                                                    <Button
                                                        onClick={() => {
                                                            setShow(true)
                                                            setResidentName(
                                                                `${FirstName} ${LastName}`,
                                                            )
                                                            setPasswordChangeDate(
                                                                `${LastPasswordChangedDate}`,
                                                            )
                                                            setProgramRecord(
                                                                `${LoginValue}${ProgramNumber}${
                                                                    LoginRoleValue
                                                                        ? `-${LoginRoleValue}`
                                                                        : ''
                                                                }`,
                                                            )
                                                        }}
                                                    >
                                                        Change Password
                                                    </Button>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    ) : (
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    colSpan={4}
                                    style={{
                                        textAlign: 'center',
                                        ...bodyCellStyle,
                                    }}
                                >
                                    No records to display
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </ABAContainer>
        </div>
    )
}
