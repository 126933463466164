/* eslint-disable no-console */
// eslint-disable-next-line
/* eslint-disable react/jsx-indent */
import React, {useEffect, useCallback, useState} from 'react'
import {BrowserRouter as Router, Navigate, Route, Routes, useLocation} from 'react-router-dom'
import useUser from './libs/hooks/useUser'
import useAuthentication from './libs/hooks/useAuthentication'
import Login from './presentation/screens/Login'
import NavShell from './presentation/components/Navigation/NavShell'
import AEPInformationAndGuidelines from './presentation/screens/AEPInformationAndGuidelines'
import BASICExamDateAssignments from './presentation/screens/BASICExamDateAssignments'
import BoardPass from './presentation/screens/BoardPass'
import CCCReportResults from './presentation/screens/CCCReportResults'
import CombinedTrainingRequirements from './presentation/screens/CombinedTrainingRequirements'
import Enroll from './presentation/screens/Enroll'
import Enrollment from './presentation/screens/Enrollment'
import EnrollmentForms from './presentation/screens/EnrollmentForms/EnrollmentForms'
import ExamRegistrationStatus from './presentation/screens/ExamRegistrationStatus'
import GradClass from './presentation/screens/GradClass'
import InTrainingExam from './presentation/screens/InTrainingExam'
import InvoiceDetails from './presentation/screens/InvoiceDetails'
import Invoices from './presentation/screens/Invoices'
import ITE from './presentation/screens/ITE/ITE'
import ITEAdministrationManual from './presentation/screens/ITEAdministrationManual'
import ITEInvoice from './presentation/screens/ITEInvoice'
import ITERoster from './presentation/screens/ITERoster'
import Landing from './presentation/screens/Landing'
import MassApproveTraining from './presentation/screens/MassApproveTraining'
import MassCompleteTraining from './presentation/screens/MassCompleteTraining'
import PassFail from './presentation/screens/PassFail'
import PDIR from './presentation/screens/PDIR'
import ProgramContacts from './presentation/screens/ProgramContacts'
import ProgramInformation from './presentation/screens/ProgramInformation'
import ProgramSwitchModal from './presentation/components/Modal/ProgramSwitchModal'
import ResidentListing from './presentation/screens/ResidentListing/ResidentListing'
import ResidentOptionsForResearch from './presentation/screens/ResidentOptionsForResearch'
import RTIDTrainingManual from './presentation/screens/RTIDTrainingManual'
import SoeOsceReports from './presentation/screens/SoeOsceReports'
import SpecialTrainingGuidelines from './presentation/screens/SpecialTrainingGuidelines'
import Training from './presentation/screens/Training'
import TrainingConfirmation from './presentation/screens/TrainingConfirmation'
import TrainingFeedback from './presentation/screens/TrainingFeedback'
import TrainingRecords from './presentation/screens/TrainingRecords'
import TrainingReports from './presentation/screens/TrainingReports/TrainingReports'
import PrivateRoute from './PrivateRoute'
import ViewCompletedTraining from './presentation/screens/ViewCompletedTraining'
import {useBrowserWindow} from './libs/hooks/useBrowserWindow'
import EventBus from './libs/EventBus'
import Help from './presentation/screens/Help'
import ITEReports from './presentation/screens/ITEReports'
import BasicReports from './presentation/screens/BasicReports'

const Main = () => {
    const {userData, fetchuserData} = useUser()
    const {windowIsFocused} = useBrowserWindow()
    const location = useLocation()
    const {logout} = useAuthentication()
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (windowIsFocused && location.pathname !== '/login' && location.pathname !== '/' && loaded) {
            fetchuserData()
        } else if (!loaded) {
            setTimeout(() => setLoaded(true), 1000)
        }
    }, [windowIsFocused])

    useEffect(() => {
        EventBus.remove('logout')
        EventBus.on('logout', (message) => {
            if (location.pathname !== '/login') {
                logout(message)
            }
        })
        return () => {
            EventBus.remove('logout')
        }
    }, [location.pathname])

    return (
        <NavShell>
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/login" element={<Login />} />
                <Route
                    path="/aepInformationAndGuidelines"
                    element={<PrivateRoute component={AEPInformationAndGuidelines} />}
                />
                <Route
                    path="/basicExamDateAssignments"
                    element={<PrivateRoute component={BASICExamDateAssignments} />}
                />
                <Route path="/basicReports" element={<PrivateRoute component={BasicReports} />} />
                <Route path="/boardPass" element={<PrivateRoute component={BoardPass} />} />
                <Route
                    path="/cccReportResults"
                    element={<PrivateRoute component={CCCReportResults} />}
                />
                <Route
                    path="/combinedTrainingRequirements"
                    element={<PrivateRoute component={CombinedTrainingRequirements} />}
                />
                <Route path="/enroll" element={<PrivateRoute component={Enroll} />} />
                <Route path="/enrollment" element={<PrivateRoute component={Enrollment} />} />
                <Route path="/enrollmentForms" element={<PrivateRoute component={EnrollmentForms} />} />
                <Route
                    path="/examRegistrationStatus"
                    element={<PrivateRoute component={ExamRegistrationStatus} />}
                />
                <Route path="/gradClass" element={<PrivateRoute component={GradClass} />} />
                <Route path="/help" element={<PrivateRoute component={Help} />} />
                <Route
                    path="/inTrainingExam/:program?"
                    element={<PrivateRoute component={InTrainingExam} />}
                />
                <Route path="/invoiceDetails" element={<PrivateRoute component={InvoiceDetails} />} />
                <Route path="/invoices" element={<PrivateRoute component={Invoices} />} />
                <Route path="/ite" element={<PrivateRoute component={ITE} />} />
                <Route
                    path="/iteAdministrationManual"
                    element={<PrivateRoute component={ITEAdministrationManual} />}
                />
                <Route path="/iteReports" element={<PrivateRoute component={ITEReports} />} />
                <Route path="/invoice/:invoiceId?" element={<PrivateRoute component={ITEInvoice} />} />
                <Route path="/iteRoster" element={<PrivateRoute component={ITERoster} />} />
                <Route
                    path="/massApproveTraining"
                    element={<PrivateRoute component={MassApproveTraining} />}
                />
                <Route
                    path="/massCompleteTraining"
                    element={<PrivateRoute component={MassCompleteTraining} />}
                />
                <Route path="/passFail" element={<PrivateRoute component={PassFail} />} />
                <Route path="/pdir" element={<PrivateRoute component={PDIR} />} />
                <Route path="/programContacts" element={<PrivateRoute component={ProgramContacts} />} />
                <Route
                    path="/programInformation"
                    element={<PrivateRoute component={ProgramInformation} />}
                />
                <Route
                    path="/programSwitchModal"
                    element={<PrivateRoute component={ProgramSwitchModal} />}
                />
                <Route
                    path="/residentListing/:program?"
                    element={<PrivateRoute component={ResidentListing} />}
                />
                <Route
                    path="/residentOptionsForResearch"
                    element={<PrivateRoute component={ResidentOptionsForResearch} />}
                />
                <Route
                    path="/rtidTrainingManual"
                    element={<PrivateRoute component={RTIDTrainingManual} />}
                />
                <Route
                    path="/specialTrainingGuidelines"
                    element={<PrivateRoute component={SpecialTrainingGuidelines} />}
                />
                <Route path="/training" element={<PrivateRoute component={Training} />} />
                <Route
                    path="/trainingConfirmation"
                    element={<PrivateRoute component={TrainingConfirmation} />}
                />
                <Route
                    path="/trainingFeedback"
                    element={<PrivateRoute component={TrainingFeedback} />}
                />
                <Route path="/trainingRecords" element={<PrivateRoute component={TrainingRecords} />} />
                <Route path="/trainingReports" element={<PrivateRoute component={TrainingReports} />} />
                <Route path="/soeOsceReports" element={<PrivateRoute component={SoeOsceReports} />} />
                <Route
                    path="/viewCompletedTraining"
                    element={<PrivateRoute component={ViewCompletedTraining} />}
                />
            </Routes>
        </NavShell>
    )
}

export default Main
