import {useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {setWindowFocused} from '../../redux/Actions/GenericActionCreators'
import {windowIsFocusedSelector} from '../../redux/reducers/global'

export const useBrowserWindow = () => {
    const windowIsFocused = useSelector(windowIsFocusedSelector)
    const windowFocusRef = useRef()
    const dispatch = useDispatch()

    function handleActivity(forcedFlag) {
        const windowIsFocused = windowFocusRef.current
        let newWindowFocusValue
        if (typeof forcedFlag === 'boolean') {
            newWindowFocusValue = !!forcedFlag
        } else {
            newWindowFocusValue = !!document.hidden
        }
        if (newWindowFocusValue !== windowIsFocused) {
            dispatch(setWindowFocused(newWindowFocusValue))
        }
    }

    useEffect(() => {
        windowFocusRef.current = windowIsFocused
    }, [windowIsFocused])

    useEffect(() => {
        const handleActivityFalse = () => handleActivity(false)
        const handleActivityTrue = () => handleActivity(true)

        document.addEventListener('visibilitychange', handleActivity)
        window.addEventListener('blur', handleActivityFalse)
        document.addEventListener('blur', handleActivityFalse)
        window.addEventListener('focus', handleActivityTrue)
        document.addEventListener('focus', handleActivityTrue)

        return () => {
            document.removeEventListener('visibilitychange', handleActivity)
            window.removeEventListener('blur', handleActivityFalse)
            document.removeEventListener('blur', handleActivityFalse)
            window.removeEventListener('focus', handleActivityTrue)
            document.removeEventListener('focus', handleActivityTrue)
        }
    }, [])

    return {windowIsFocused}
}
