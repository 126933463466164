import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {ColorEnums, GeneralEnums} from '../../../helpers/GeneralEnums'
import {TextInput, Button, Modal} from 'aba-ui'
import HelperMethods from '../../../helpers/HelperMethods'
import ValidationHelpers from '../../../helpers/ValidationHelpers'

function ProctorModal(props) {
    const {existingProctor, saveFunction, toggleModal} = props
    const {textInputStatus} = GeneralEnums
    const [firstName, setFirstName] = useState(
        existingProctor?.FirstName ? existingProctor.FirstName : '',
    )
    const [lastName, setLastName] = useState(
        existingProctor?.LastName ? existingProctor.LastName : '',
    )
    const [email, setEmail] = useState(
        existingProctor?.EmailAddress ? existingProctor.EmailAddress : '',
    )
    const [phone, setPhone] = useState(
        existingProctor?.PhoneNumber
            ? HelperMethods.formatFaxAndPhoneNumber(existingProctor.PhoneNumber)
            : '',
    )

    const [inputStatus, setInputStatus] = useState({
        firstName: textInputStatus.default,
        lastName: textInputStatus.default,
        email: textInputStatus.default,
        phone: textInputStatus.default,
    })

    const handleInputStatusChange = (e) => {
        const {name} = e.target
        setInputStatus((prevState) => ({
            ...prevState,
            [name]: textInputStatus.default,
        }))
    }

    const validateForm = () => {
        let validatedStatus = {...inputStatus}
        const statusErr = textInputStatus.error

        // setting everything to default
        Object.keys(validatedStatus).forEach(
            (value) => (validatedStatus[value] = textInputStatus.default),
        )

        if (!firstName) {
            validatedStatus.firstName = statusErr
        }

        if (!lastName) {
            validatedStatus.lastName = statusErr
        }

        if (!ValidationHelpers.validateEmail(email)) {
            validatedStatus.email = statusErr
        }

        if (!phone || phone.length < 14) {
            validatedStatus.phone = statusErr
        }

        // if an error exists, set the new input status and return false
        if (Object.values(validatedStatus).includes(statusErr)) {
            setInputStatus(validatedStatus)
            return false
        }

        return true
    }

    return (
        <Modal>
            <div
                style={{
                    backgroundColor: 'white',
                    width: '550px',
                    padding: '15px',
                    border: `1px solid ${ColorEnums.cloudBurst}`,
                    borderRadius: '10px',
                }}
            >
                <h4 style={{textAlign: 'center', marginBottom: 20}}>
                    In Training Exam Proctor Details
                </h4>
                <TextInput
                    name="firstName"
                    onChange={(event) => {
                        const {value} = event.target

                        if (
                            inputStatus.firstName === textInputStatus.error &&
                            value.length > 0
                        ) {
                            handleInputStatusChange(event)
                        }

                        setFirstName(value)
                    }}
                    required
                    value={firstName}
                    label="First Name"
                    disabled={existingProctor ? true : false}
                    textInputStatus={inputStatus.firstName}
                    maxLength={50}
                    placeholder="First Name"
                />
                <TextInput
                    name="lastName"
                    onChange={(event) => {
                        const {value} = event.target

                        if (
                            inputStatus.lastName === textInputStatus.error &&
                            value.length > 0
                        ) {
                            handleInputStatusChange(event)
                        }

                        setLastName(value)
                    }}
                    required
                    value={lastName}
                    label="Last Name"
                    disabled={existingProctor ? true : false}
                    textInputStatus={inputStatus.lastName}
                    maxLength={50}
                    placeholder="Last Name"
                />
                <TextInput
                    name="email"
                    onChange={(event) => {
                        const {value} = event.target

                        if (
                            inputStatus.email === textInputStatus.error &&
                            ValidationHelpers.validateEmail(value)
                        ) {
                            handleInputStatusChange(event)
                        }

                        setEmail(value)
                    }}
                    required
                    value={email}
                    label="Email Address"
                    textInputStatus={inputStatus.email}
                    maxLength={100}
                    placeholder="Email Address"
                    errorMessage="Please enter a valid email address"
                />
                <TextInput
                    name="phone"
                    onChange={(event) => {
                        const {value} = event.target

                        if (
                            inputStatus.phone === textInputStatus.error &&
                            value.length >= 14
                        ) {
                            handleInputStatusChange(event)
                        }

                        setPhone(
                            HelperMethods.formatFaxAndPhoneNumber(event.target.value),
                        )
                    }}
                    required
                    value={phone}
                    label="Phone"
                    textInputStatus={inputStatus.phone}
                    maxLength={50}
                    placeholder="Phone"
                    errorMessage="Please enter a valid phone number"
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Button
                        variant="outlined"
                        onClick={() => toggleModal(false)}
                        style={{width: '200px'}}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="filled"
                        style={{width: '200px'}}
                        onClick={() => {
                            if (!validateForm()) {
                                return
                            }

                            const proctor = {
                                FirstName: firstName,
                                LastName: lastName,
                                EmailAddress: email,
                                PhoneNumber: phone,
                                ProgramRoleId: existingProctor?.ProgramRoleId
                                    ? existingProctor.ProgramRoleId
                                    : null,
                            }
                            saveFunction(proctor)
                        }}
                    >
                        Save / Close
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

ProctorModal.defaultProps = {
    existingProctor: null,
    saveFunction: () => {},
    toggleModal: () => {},
}

ProctorModal.propTypes = {
    existingProctor: PropTypes.shape({
        EmailAddress: PropTypes.string,
        FirstName: PropTypes.string,
        LastName: PropTypes.string,
        PhoneNumber: PropTypes.string,
        ProgramRoleId: PropTypes.string,
    }),
    saveFunction: PropTypes.func,
    toggleModal: PropTypes.func,
}

export default ProctorModal
