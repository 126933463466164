/* eslint-disable eqeqeq */
import {Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {GeneralEnums} from '../../helpers/GeneralEnums'
import HelperMethods from '../../helpers/HelperMethods'
import {enrollmentValidationSchema} from '../../helpers/ValidationHelpers'
import useEnrollment from '../../libs/hooks/useEnrollment'
import useOptionSets from '../../libs/hooks/useOptionSets'
import useUser from '../../libs/hooks/useUser'
import useProgram from '../../libs/hooks/useProgram'
import PriorCBTrainingForm from './PriorCBTrainingForm'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ABACheckbox from '../components/ABAComponents/ABACheckbox'
import ABAContainer from '../components/ABAComponents/ABAContainer/ABAContainer'
import ABAHeader from '../components/ABAComponents/ABAHeader'
import ABARequiredField from '../components/ABAComponents/ABARequiredField'
import styles from '../components/ABAComponents/ABATextInput/ABATextInput.module.css'
import {TextInput, Button, Dropdown, StyleVariant, LoadingOverlay} from 'aba-ui'

const initialValues = {
    ResidentEnrollmentId: null,
    ProgramPeriodId: null,
    TrainingId: null,
    TrainingPeriodId: null,
    ContactId: null,
    SSN: '',
    LastName: '',
    FirstName: '',
    MiddleName: '',
    BirthDate: '',
    CountryOfBirthId: GeneralEnums.cert_countryIds.usa,
    SuffixId: null,
    GenderId: '',
    EmailAddress: '',
    MedicalSchoolCountryId: null,
    MedicalSchoolId: null,
    InternationalMedicalSchoolName: '',
    MedicalDegreeId: null,
    DateResidentBeganTraining: null,
    MedicalDegreeConferredDate: null,
    IsCertifying: null,
    PriorCBTraining: null,
    PriorCBTrainingDuration: '',
    DatePriorCBTrainingStarted: null,
    PriorCBTrainingProgramName: '',
    PriorCBTrainingProgramCity: '',
    PriorCBTrainingProgramState: '',
    PriorCBTrainingProgramDirector: '',
    PriorCBTrainingProgramType: null,
    TrackType: '',
    DatePrimaryResidencyCompleted: null,
    PrimarySpecialty: 0,
    ReQualification: false,
    Attestation: false,
    ProgramRoleId: null,
}

export default function Enrollment(props) {
    const [formData, setFormData] = useState(initialValues)
    const optionSets = useOptionSets()
    const {userData} = useUser()
    const {getProgramInformation} = useProgram()
    const [disabledInputs, setDisabledInputs] = useState([])
    const [isConfirmation, setIsConfirmation] = useState(false)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const location = useLocation()
    const {getEnrollmentIdentification, submitEnrollment} = useEnrollment(location.state)
    const {abaTextInputErrorMessage} = styles
    const getIsCertifyingForFellowship = (value) =>
        value == GeneralEnums.primarySpecialties.anesthesiologyABA ? '1' : '0'
    const [programInfo, setProgramInfo] = useState(null)

    useEffect(() => {
        if (location.state?.setIsConfirmation || location.state?.mode === 'view') {
            setIsConfirmation(true)
        }
        const getData = async () => {
            const result = await getEnrollmentIdentification()
            const results = result.data || {}

            if (location.state?.SSN) {
                results.SSN = location.state.SSN
            }

            if (location.state?.BirthDate) {
                results.BirthDate = location.state.BirthDate
            }

            results.BirthDate = HelperMethods.formatDate(results.BirthDate)
            results.MedicalDegreeConferredDate = HelperMethods.formatDate(
                results.MedicalDegreeConferredDate,
            )

            results.CountryOfBirthId = results.CountryOfBirthId || GeneralEnums.cert_countryIds.usa

            results.PriorCBTraining = results.PriorCBTraining || '0'
            results.PriorCBTrainingProgramName = results.PriorCBTrainingProgramName || '0'
            results.PriorCBTrainingProgramCity = results.PriorCBTrainingProgramCity || '0'
            results.PriorCBTrainingProgramState = results.PriorCBTrainingProgramState || '0'
            results.PriorCBTrainingProgramDirector = results.PriorCBTrainingProgramDirector || '0'

            results.PrimarySpecialty = results.PrimarySpecialty || '0'
            results.TrackType = results.TrackType || '1'
            results.ReQualification = results.ReQualification || '0'
            result.IsCertifying = results.IsCertifying || '0'

            if (userData.ProgramType !== GeneralEnums.programTypes.primary) {
                results.IsCertifying = getIsCertifyingForFellowship(results.PrimarySpecialty)
            }

            await getProgramInformation(userData.AccountId).then((data) => {
                setProgramInfo(data)
            })
            setFormData({...formData, ...result.data})

            const o = Object.fromEntries(
                Object.entries(results).filter(([_, v]) => {
                    if (v === '') return false
                    return v != null
                }),
            )

            setDisabledInputs(Object.keys(o))
            setLoading(false)
        }
        getData()
    }, [])

    const getInputStatus = (formik, fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return GeneralEnums.textInputStatus.error
        }
    }

    const renderMedicalSchoolField = (formik) => {
        if (Number(formik.values.MedicalSchoolCountryId) === GeneralEnums.medicalSchoolCountryIds.usa) {
            return (
                <Dropdown
                    options={optionSets.usMedicalSchools.map((medSchoolOption) => {
                        return {
                            value: medSchoolOption.MedicalSchoolId,
                            name: medSchoolOption.MedicalSchoolName,
                        }
                    })}
                    onChange={(value) => formik.setFieldValue('MedicalSchoolId', value)}
                    errorMessage={'Please select a school.'}
                    selectedValue={formik.values.MedicalSchoolId}
                    disabled={formData?.ContactId || isConfirmation}
                    dropdownStatus={getInputStatus(formik, 'MedicalSchoolId')}
                    placeholder="Medical School"
                    required
                    style={{width: '100%'}}
                />
            )
        }
        if (
            Number(formik.values.MedicalSchoolCountryId) === GeneralEnums.medicalSchoolCountryIds.canada
        ) {
            return (
                <Dropdown
                    options={optionSets.canadianMedicalSchools.map((medSchoolOption) => {
                        return {
                            value: medSchoolOption.MedicalSchoolId,
                            name: medSchoolOption.MedicalSchoolName,
                        }
                    })}
                    errorMessage={'Please select a school.'}
                    onChange={(value) => formik.setFieldValue('MedicalSchoolId', value)}
                    selectedValue={formik.values.MedicalSchoolId}
                    dropdownStatus={getInputStatus(formik, 'MedicalSchoolId')}
                    disabled={formData?.ContactId || isConfirmation}
                    placeholder="Medical School"
                    required={
                        Number(formik.values.MedicalSchoolCountryId) ===
                            GeneralEnums.medicalSchoolCountryIds.usa ||
                        Number(formik.values.MedicalSchoolCountryId) ===
                            GeneralEnums.medicalSchoolCountryIds.canada
                    }
                    style={{width: '100%'}}
                />
            )
        }

        if (
            Number(formik.values.MedicalSchoolCountryId) ===
                GeneralEnums.medicalSchoolCountryIds.international ||
            Number(formik.values.MedicalSchoolCountryId) ===
                GeneralEnums.medicalSchoolCountryIds.unspecified
        ) {
            return (
                <TextInput
                    label="Medical School"
                    maxLength={100}
                    errorMessage={'Please enter a value.'}
                    textInputStatus={getInputStatus(formik, 'InternationalMedicalSchoolName')}
                    {...formik.getFieldProps('InternationalMedicalSchoolName')}
                    placeholder="Medical School"
                    required
                    disabled={formData?.ContactId || isConfirmation}
                />
            )
        }
    }

    const renderResidentFields = (formik) => {
        return (
            <div>
                <section
                    style={{
                        paddingTop: 30,
                        paddingBottom: 20,
                        width: 450,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    <h4>Training</h4>
                    <TextInput
                        label="Date Resident began training in your Program"
                        maxLength={10}
                        onChange={(event) => {
                            formik.setFieldValue(
                                'DateResidentBeganTraining',
                                HelperMethods.dateInputMask(
                                    event.target.value,
                                    formik.values.DateResidentBeganTraining,
                                ),
                            )
                        }}
                        placeholder="Date Resident began training in your Program (mm/dd/yyyy)"
                        errorMessage="Please enter a valid date"
                        textInputStatus={getInputStatus(formik, 'DateResidentBeganTraining')}
                        disabled={isConfirmation}
                        required
                        value={formik.values.DateResidentBeganTraining}
                        name="DateResidentBeganTraining"
                    />
                    <Dropdown
                        dropdownStatus={getInputStatus(formik, 'IsCertifying')}
                        onChange={(value) => Number(formik.setFieldValue('IsCertifying', value))}
                        options={optionSets.isCertifying.map((certOption) => {
                            return {
                                value: certOption.Value,
                                name: certOption.Label,
                            }
                        })}
                        placeholder="Is Certifying"
                        required
                        disabled={isConfirmation}
                        selectedValue={
                            formik.values.IsCertifying === 1 || formik.values.IsCertifying === 0
                                ? String(formik.values.IsCertifying)
                                : formik.values.IsCertifying
                        }
                        style={{width: '100%'}}
                        errorMessage={'Please select a value.'}
                    />
                    <Dropdown
                        dropdownStatus={getInputStatus(formik, 'TrackType')}
                        onChange={(value) => formik.setFieldValue('TrackType', value)}
                        options={optionSets.trackTypes
                            .filter((trackType) => {
                                if (UserRole === 'SuperUser' || location.state?.mode === 'view') {
                                    return (
                                        trackType.Label === 'Standard' ||
                                        (trackType.Label === 'Alternate Entry Path' &&
                                            programInfo?.AEPTrack) ||
                                        (trackType.Label === 'Combined' && programInfo?.CombinedTrack) ||
                                        (trackType.Label === 'Innovative' &&
                                            programInfo?.InnovativeTrack)
                                    )
                                } else {
                                    return (
                                        trackType.Label === 'Standard' ||
                                        (trackType.Label === 'Combined' && programInfo?.CombinedTrack) ||
                                        (trackType.Label === 'Innovative' &&
                                            programInfo?.InnovativeTrack)
                                    )
                                }
                            })
                            .map((trackTypeOption) => {
                                return {
                                    value: trackTypeOption.Value,
                                    name: trackTypeOption.Label,
                                }
                            })}
                        placeholder="Track Type"
                        selectedValue={formik.values.TrackType}
                        required
                        disabled={isConfirmation}
                        style={{width: '100%'}}
                        errorMessage={'Please select a value.'}
                    />
                </section>
                <section
                    style={{
                        paddingTop: 30,
                        paddingBottom: 20,
                        width: 450,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    <h4>Prior Clinical Base Training</h4>
                    <Dropdown
                        dropdownStatus={getInputStatus(formik, 'PriorCBTraining')}
                        onChange={(value) => {
                            if (value == 1) {
                                let inputs = disabledInputs.filter((input) => {
                                    return (
                                        input !== 'PriorCBTrainingProgramCity' &&
                                        input !== 'PriorCBTrainingProgramState' &&
                                        input !== 'PriorCBTrainingProgramDirector' &&
                                        input !== 'PriorCBTrainingProgramName'
                                    )
                                })

                                setDisabledInputs(inputs)
                            } else if (
                                value == 0 &&
                                !disabledInputs.includes('PriorCBTrainingProgramCity')
                            ) {
                                let inputs = [...disabledInputs]
                                inputs.push(
                                    'PriorCBTrainingProgramCity',
                                    'PriorCBTrainingProgramState',
                                    'PriorCBTrainingProgramDirector',
                                    'PriorCBTrainingProgramName',
                                )

                                setDisabledInputs(inputs)
                            }

                            formik.values.PriorCBTraining == 0 &&
                                formik.setFieldValue('DatePriorCBTrainingStarted', ''),
                                formik.setFieldValue('PriorCBTrainingDuration', ''),
                                formik.setFieldValue('PriorCBTrainingProgramName', ''),
                                formik.setFieldValue('PriorCBTrainingProgramCity', ''),
                                formik.setFieldValue('PriorCBTrainingProgramState', ''),
                                formik.setFieldValue('PriorCBTrainingProgramDirector', ''),
                                formik.setFieldValue('PriorCBTrainingProgramType', null),
                                formik.setFieldValue('PriorCBTraining', value)
                        }}
                        options={[
                            {value: 1, name: 'Yes'},
                            {value: 0, name: 'No'},
                        ]}
                        placeholder="Prior Clinical Base Training"
                        selectedValue={formik.values.PriorCBTraining}
                        style={{width: '100%'}}
                        required
                        disabled={isConfirmation}
                        errorMessage={'Please select a value'}
                    />
                    {formik.values.PriorCBTraining == 1 && (
                        <PriorCBTrainingForm
                            formik={formik}
                            getInputStatus={getInputStatus}
                            isConfirmation={isConfirmation}
                            disabledInputs={disabledInputs}
                        />
                    )}
                    <div />
                </section>
            </div>
        )
    }

    const renderFellowFields = (formik) => {
        return (
            <section
                style={{
                    paddingTop: 30,
                    paddingBottom: 20,
                    width: 450,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <h4>Training</h4>
                <TextInput
                    label="Date Primary Residency Completed"
                    maxLength={10}
                    onChange={(event) => {
                        formik.setFieldValue(
                            'DatePrimaryResidencyCompleted',
                            HelperMethods.dateInputMask(
                                event.target.value,
                                formik.values.DatePrimaryResidencyCompleted,
                            ),
                        )
                    }}
                    placeholder="Date Primary Residency Completed (mm/dd/yyyy)"
                    errorMessage="Please enter a valid date"
                    textInputStatus={getInputStatus(formik, 'DatePrimaryResidencyCompleted')}
                    required
                    disabled={isConfirmation}
                    value={formik.values.DatePrimaryResidencyCompleted}
                />
                <Dropdown
                    dropdownStatus={getInputStatus(formik, 'PrimarySpecialty')}
                    onChange={(value) => {
                        Number(formik.setFieldValue('PrimarySpecialty', value))
                        value !== GeneralEnums.primarySpecialties.anesthesiologyABA.toString()
                            ? Number(formik.setFieldValue('IsCertifying', '0'))
                            : Number(formik.setFieldValue('IsCertifying', '1'))
                    }}
                    hideFirstOption
                    options={optionSets.primarySpecialties.map((option) => {
                        return {value: option.Value, name: option.Label}
                    })}
                    placeholder="Primary Specialty"
                    required
                    disabled={isConfirmation}
                    selectedValue={formik.values.PrimarySpecialty}
                    style={{width: '100%'}}
                />
                <TextInput
                    label="Date Fellow began training in your program"
                    maxLength={10}
                    onChange={(event) => {
                        formik.setFieldValue(
                            'DateResidentBeganTraining',
                            HelperMethods.dateInputMask(
                                event.target.value,
                                formik.values.DateResidentBeganTraining,
                            ),
                        )
                    }}
                    placeholder="Date Fellow began training in your program (mm/dd/yyyy)"
                    errorMessage="Please enter a valid date"
                    textInputStatus={getInputStatus(formik, 'DateResidentBeganTraining')}
                    required
                    disabled={isConfirmation}
                    value={formik.values.DateResidentBeganTraining}
                />
                <Dropdown
                    onChange={(value) => Number(formik.setFieldValue('ReQualification', value))}
                    options={[
                        {value: 1, name: 'Yes'},
                        {value: 0, name: 'No'},
                    ]}
                    placeholder="Reestablishing admission qualifications"
                    hideFirstOption
                    disabled={isConfirmation}
                    selectedValue={formik.values.ReQualification}
                    style={{width: '100%'}}
                />
                <Dropdown
                    dropdownStatus={getInputStatus(formik, 'IsCertifying')}
                    onChange={(value) => Number(formik.setFieldValue('IsCertifying', value))}
                    options={optionSets.isCertifying.map((certOption) => {
                        return {
                            value: certOption.Value,
                            name: certOption.Label,
                        }
                    })}
                    placeholder="Is Certifying"
                    required
                    disabled={
                        formik.values.PrimarySpecialty !==
                            GeneralEnums.primarySpecialties.anesthesiologyABA.toString() ||
                        isConfirmation
                    }
                    selectedValue={String(formik.values.IsCertifying)}
                    style={{width: '100%'}}
                />
                <Dropdown
                    dropdownStatus={getInputStatus(formik, 'TrackType')}
                    onChange={(value) => formik.setFieldValue('TrackType', value)}
                    options={optionSets.trackTypes
                        .filter((trackType) => {
                            return (
                                trackType.Label === 'Standard' ||
                                (trackType.Label === 'Alternate Entry Path' &&
                                    programInfo?.AEPTrack &&
                                    UserRole === 'SuperUser') ||
                                (trackType.Label === 'Combined' && programInfo?.CombinedTrack) ||
                                (trackType.Label === 'Innovative' && programInfo?.InnovativeTrack)
                            )
                        })
                        .map((trackTypeOption) => {
                            return {
                                value: trackTypeOption.Value,
                                name: trackTypeOption.Label,
                            }
                        })}
                    placeholder="Track Type"
                    required
                    disabled={isConfirmation}
                    selectedValue={formik.values.TrackType}
                    style={{width: '100%'}}
                />
            </section>
        )
    }

    const {UserRole} = userData

    const editOnClick = (formik) => {
        formik.setFieldValue('action', 'edit')
        setIsConfirmation(false)
        window.scrollTo(0, 0)
    }

    const buildButtonDisplay = (formik) => {
        if (location.state?.mode === 'view') {
            return (
                <Button
                    variant={StyleVariant.OUTLINED}
                    style={{width: 125, marginLeft: 'auto', marginRight: 'auto'}}
                    inverse
                    onClick={() => {
                        navigate('/residentListing', {
                            state: {
                                residentEnrollmentId: location.state?.ResidentEnrollmentId,
                            },
                        })
                    }}
                >
                    Return
                </Button>
            )
        }

        if (isConfirmation) {
            return (
                <Button
                    variant={StyleVariant.OUTLINED}
                    style={{width: 125}}
                    inverse
                    onClick={() => {
                        editOnClick(formik)
                    }}
                >
                    Edit
                </Button>
            )
        } else {
            return (
                <Button
                    type="button"
                    variant={StyleVariant.OUTLINED}
                    style={{width: 125}}
                    onClick={() => {
                        navigate('/enroll')
                    }}
                >
                    Cancel
                </Button>
            )
        }
    }

    return (
        <div>
            <ABAHeader text="Enrollment" />
            {loading && <LoadingOverlay title="Loading..." />}
            <ABAContainer
                label={`ENROLLMENT ${isConfirmation ? 'CONFIRMATION' : 'INFORMATION'}`}
                textAlign="center"
                loading={loading}
            >
                <Formik
                    initialValues={formData}
                    enableReinitialize={true}
                    validationSchema={() => {
                        // Remove any fields that are from the server from validation
                        const isPrimary = userData.ProgramType === GeneralEnums.programTypes.primary
                        const schema = enrollmentValidationSchema(isPrimary, isConfirmation)

                        disabledInputs.map((input) => {
                            delete schema.fields[input]
                        })
                        return schema
                    }}
                    onSubmit={async (values, actions) => {
                        values.ProgramId = userData.ProgramId
                        values.ProgramType = userData.ProgramType
                        values.ProgramPeriodId = userData.ProgramPeriodId
                        values.ProgramRoleId = userData.ProgramRoleId
                        values.TrainingPeriodId = userData.TrainingPeriodId
                        values.ActivePeriodEndDate = userData.TrainingEndDate
                        if (values.action === 'edit') {
                            values.action = ''
                            return
                        }

                        if (isNaN(values.SSN)) {
                            values.SSN = Number(values.SSN.replace(/-/g, ''))
                        }
                        setLoading(true)
                        const {data} = await submitEnrollment(values, isConfirmation)
                        if (isConfirmation) {
                            navigate('/residentListing')
                        } else {
                            window.scrollTo(0, 0)
                            setIsConfirmation(true)
                            setLoading(false)
                            actions.setValues({
                                ...values,
                                ResidentEnrollmentId: data.enrollmentId,
                                TrainingId: data.trainingId,
                            })
                        }
                    }}
                >
                    {(formik) => {
                        const {values, setFieldValue, getFieldProps} = formik
                        return (
                            <Form>
                                <section
                                    style={{
                                        paddingTop: 30,
                                        paddingBottom: 20,
                                        width: 450,
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}
                                >
                                    <h4>Personal Information</h4>
                                    <TextInput
                                        label="SSN"
                                        placeholder="SSN"
                                        name="SSN"
                                        required
                                        value={
                                            disabledInputs.includes('SSN') && values.SSNLast4
                                                ? `***-**-${values.SSNLast4}`
                                                : values.SSN
                                                  ? `***-**-${values.SSN.substring(9, 5)}`
                                                  : ''
                                        }
                                        disabled={true}
                                    />
                                    <TextInput
                                        label="Last Name"
                                        maxLength={50}
                                        name="LastName"
                                        placeholder="Last Name"
                                        textInputStatus={getInputStatus(formik, 'LastName')}
                                        {...getFieldProps('LastName')}
                                        required
                                        disabled={formData?.ContactId || isConfirmation}
                                    />
                                    <TextInput
                                        label="First Name"
                                        maxLength={50}
                                        placeholder="First Name"
                                        required
                                        textInputStatus={getInputStatus(formik, 'FirstName')}
                                        disabled={formData?.ContactId || isConfirmation}
                                        {...getFieldProps('FirstName')}
                                    />
                                    <TextInput
                                        label="Middle Name"
                                        maxLength={50}
                                        placeholder="Middle Name"
                                        textInputStatus={getInputStatus(formik, 'MiddleName')}
                                        disabled={formData?.ContactId || isConfirmation}
                                        {...getFieldProps('MiddleName')}
                                    />
                                    <Dropdown
                                        placeholder="Suffix"
                                        onChange={(value) => {
                                            setFieldValue('SuffixId', value)
                                            formik.setTouched({fields: {SuffixId: false}})
                                        }}
                                        options={optionSets.suffixes.map((suffixOption) => {
                                            return {
                                                value: suffixOption.Value,
                                                name: suffixOption.Label,
                                            }
                                        })}
                                        selectedValue={values.SuffixId}
                                        dropdownStatus={getInputStatus(formik, 'SuffixId')}
                                        disabled={formData?.ContactId || isConfirmation}
                                        style={{width: '100%'}}
                                    />
                                    <TextInput
                                        label="Date of Birth"
                                        maxLength={10}
                                        placeholder="Date of Birth (mm/dd/yyyy)"
                                        {...getFieldProps('BirthDate')}
                                        errorMessage="Please enter a valid date"
                                        //value={values.BirthDate}
                                        textInputStatus={getInputStatus(formik, 'BirthDate')}
                                        onChange={(event) => {
                                            setFieldValue(
                                                'BirthDate',
                                                HelperMethods.dateInputMask(
                                                    event.target.value,
                                                    values.BirthDate,
                                                ),
                                            )
                                        }}
                                        name="BirthDate"
                                        disabled={true}
                                        required
                                    />
                                    <Dropdown
                                        hideFirstOption
                                        placeholder="Country of Birth"
                                        onChange={(value) => {
                                            setFieldValue('CountryOfBirthId', value)
                                            formik.setTouched({
                                                fields: {CountryOfBirthId: false},
                                            })
                                        }}
                                        dropdownStatus={getInputStatus(formik, 'CountryOfBirthId')}
                                        errorMessage={formik.errors.CountryOfBirthId}
                                        options={optionSets.countries.map((countryOption) => {
                                            return {
                                                value: countryOption.Value,
                                                name: countryOption.Label,
                                            }
                                        })}
                                        disabled={formData?.ContactId || isConfirmation}
                                        selectedValue={values.CountryOfBirthId}
                                        required
                                        style={{width: '100%'}}
                                    />
                                    <Dropdown
                                        placeholder="Gender"
                                        required
                                        options={optionSets.genders.map((genderOption) => {
                                            return {
                                                value: genderOption.Value,
                                                name: genderOption.Label,
                                            }
                                        })}
                                        onChange={(value) => {
                                            setFieldValue('GenderId', value)
                                            formik.setTouched({
                                                fields: {GenderId: false},
                                            })
                                        }}
                                        dropdownStatus={getInputStatus(formik, 'GenderId')}
                                        errorMessage={'Please select a value.'}
                                        disabled={formData?.ContactId || isConfirmation}
                                        selectedValue={values.GenderId}
                                        style={{width: '100%'}}
                                    />
                                    <TextInput
                                        label="Email"
                                        maxLength={100}
                                        placeholder="Email"
                                        {...getFieldProps('EmailAddress')}
                                        errorMessage={'Please enter a valid email'}
                                        required
                                        textInputStatus={getInputStatus(formik, 'EmailAddress')}
                                        disabled={formData?.ContactId || isConfirmation}
                                        value={values.EmailAddress}
                                    />
                                </section>
                                <section
                                    style={{
                                        paddingTop: 30,
                                        paddingBottom: 20,
                                        width: 450,
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}
                                >
                                    <h4>Education</h4>

                                    <Dropdown
                                        placeholder="Medical School Country"
                                        onChange={(value) => {
                                            formik.setFieldValue('MedicalSchoolCountryId', value)
                                            formik.setFieldValue('MedicalSchoolId', null)
                                            formik.setFieldValue('InternationalMedicalSchoolName', '')
                                            formik.setTouched({
                                                fields: {
                                                    MedicalSchoolCountryId: false,
                                                },
                                            })
                                        }}
                                        options={optionSets.medicalSchoolCountries.map(
                                            (medSchoolCountryOption) => {
                                                if (medSchoolCountryOption.Label === 'Unspecified') {
                                                    return ''
                                                } else {
                                                    return {
                                                        value: medSchoolCountryOption.Value,
                                                        name: medSchoolCountryOption.Label,
                                                    }
                                                }
                                            },
                                        )}
                                        dropdownStatus={getInputStatus(formik, 'MedicalSchoolCountryId')}
                                        errorMessage={'Please select a value.'}
                                        selectedValue={formik.values.MedicalSchoolCountryId}
                                        disabled={formData?.ContactId || isConfirmation}
                                        required
                                        style={{width: '100%'}}
                                    />
                                    {renderMedicalSchoolField(formik)}
                                    <Dropdown
                                        placeholder="Medical Degree"
                                        onChange={(value) => {
                                            formik.setFieldValue('MedicalDegreeId', value)
                                            formik.setTouched({
                                                fields: {MedicalDegreeId: false},
                                            })
                                        }}
                                        options={optionSets.medicalDegrees.map((medSchoolDegree) => {
                                            return {
                                                value: medSchoolDegree.Value,
                                                name: medSchoolDegree.Label,
                                            }
                                        })}
                                        selectedValue={formik.values.MedicalDegreeId}
                                        dropdownStatus={getInputStatus(formik, 'MedicalDegreeId')}
                                        errorMessage={'Please select a value.'}
                                        disabled={formData?.ContactId || isConfirmation}
                                        required
                                        style={{width: '100%'}}
                                    />
                                    <TextInput
                                        label="Date Medical Degree Conferred"
                                        maxLength={10}
                                        placeholder="Date Medical Degree Conferred (mm/dd/yyyy)"
                                        errorMessage="Please enter a valid date"
                                        onChange={(event) =>
                                            formik.setFieldValue(
                                                'MedicalDegreeConferredDate',
                                                HelperMethods.dateInputMask(
                                                    event.target.value,
                                                    formik.values.MedicalDegreeConferredDate,
                                                ),
                                            )
                                        }
                                        value={formik.values.MedicalDegreeConferredDate}
                                        textInputStatus={getInputStatus(
                                            formik,
                                            'MedicalDegreeConferredDate',
                                        )}
                                        disabled={formData?.ContactId || isConfirmation}
                                        required
                                    />
                                </section>
                                {userData.ProgramType === GeneralEnums.programTypes.primary
                                    ? renderResidentFields(formik)
                                    : renderFellowFields(formik)}
                                <section
                                    style={{
                                        paddingBottom: 30,
                                        width: 450,
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}
                                >
                                    {isConfirmation &&
                                        (UserRole === 'ProgramDirector' || UserRole === 'SuperUser') &&
                                        location.state?.mode !== 'view' && (
                                            <>
                                                <div
                                                    style={{
                                                        paddingBottom: '15px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    <ABACheckbox
                                                        isChecked={values.Attestation}
                                                        label="I attest to the accuracy of the information provided on this form."
                                                        onChangeEvent={() => {
                                                            setFieldValue(
                                                                'Attestation',
                                                                !values.Attestation,
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className={abaTextInputErrorMessage}>
                                                    {formik.touched.Attestation &&
                                                    formik.errors.Attestation
                                                        ? formik.errors.Attestation
                                                        : null}
                                                </div>
                                            </>
                                        )}
                                    <ABARequiredField />
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        {buildButtonDisplay(formik)}

                                        {location.state?.mode !== 'view' && formik && (
                                            <Button
                                                variant={StyleVariant.FILLED}
                                                style={{width: 125}}
                                                formik
                                                type="submit"
                                                rightIcon={
                                                    <FontAwesomeIcon
                                                        icon={'angle-right'}
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                    />
                                                }
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </div>
                                </section>
                            </Form>
                        )
                    }}
                </Formik>
            </ABAContainer>
        </div>
    )
}

// Enrollment.propTypes = {
//     location: PropTypes.shape({pathname: PropTypes.string, state: {}}).isRequired,
// }
