import React from 'react'
import ABAContainer from '../components/ABAComponents/ABAContainer/ABAContainer'
import styles from '../styles/helpStyle.css'
import useUser from '../../libs/hooks/useUser'
import {GeneralEnums} from '../../helpers/GeneralEnums'
import HelpAccordion from './HelpAccordion/HelpAccordion'

const Help = () => {
    const title = 'SUPPORT TOPICS'

    const {userData} = useUser()

    const links = [
        {
            name: 'View Here >',
            subLinks: [
                {
                    name: '2019 Program Director Meeting Slides',
                    url: '/HelpDocs/2019-Program-Director-Meeting-Slides.pptx',
                },
                {
                    name: '2021 Program Director Update Webinar',
                    url: 'https://player.vimeo.com/external/524920805.hd.mp4?s=ff068cc7437f6859e273bfa389dfc7b20f65392e&profile_id=175',
                },
                {
                    name: '2021 Program Director Update Slides',
                    url: '/HelpDocs/2021 Program Director Update.pdf',
                },
            ],
        },
    ]

    const helpLinks = [
        {
            name: 'ABA Practice Exam',
            url: '/HelpDocs/ABAPracticeExam3_22.pdf',
            mustBePrimary: true,
        },
        {
            name: 'Absence from Training',
            url: '/HelpDocs/AFT_Request_Form.docx',
            mustBePrimary: true,
        },
        {
            name: 'Alternate Entry Path (AEP)',
            url: 'https://theaba.org/alternate%20entry%20pathway.html',
            mustBePrimary: true,
        },
        {
            name: 'Credit for Prior Training in Another Specialty (Conversion Factor Request Form)',
            url: '/HelpDocs/Request Form-ConversionFactor 7.30.24.pdf',
            mustBePrimary: false,
        },
        {
            name: 'How to Create a Portal Account',
            url: 'https://theaba.org/pdfs/Create_Portal.pdf',
            mustBePrimary: true,
        },
        {
            name: "In-Training Administrator's Manual",
            url: 'https://www.theaba.org/wp-content/uploads/2023/01/ITE-Admin-Manual.pdf',
            mustBePrimary: false,
        },
        {
            name: 'In-Training Examination Registration Guide',
            url: 'https://www.theaba.org/pdfs/iteregistrationguide.pdf',
            mustBePrimary: false,
        },
        {
            name: 'Program Director Meetings',
            url: [
                {
                    name: '2019 Program Director Meeting Slides',
                    url: '/HelpDocs/2019-Program-Director-Meeting-Slides.pptx',
                },
                {
                    name: '2021 Program Director Update Webinar',
                    url: 'https://player.vimeo.com/external/524920805.hd.mp4?s=ff068cc7437f6859e273bfa389dfc7b20f65392e&profile_id=175',
                },
                {
                    name: '2021 Program Director Update Slides',
                    url: '/HelpDocs/2021 Program Director Update.pdf',
                },
                {
                    name: '2023 Program Director Meeting Slides',
                    url: '/HelpDocs/2023-Program-Director-Meeting-Slides_20230218.pptx',
                },
            ],
            mustBePrimary: false,
        },
        {
            name: 'Resident Options for Research',
            url: 'https://theaba.org/pdfs/OPTIONS%20FOR%20RESEARCH%20DURING%20RESIDENCY%20TRAINING.pdf',
            mustBePrimary: true,
        },
        {
            name: 'RTID Manual',
            url: 'https://www.theaba.org/pdfs/rtidguide.pdf',
            mustBePrimary: false,
        },
        {
            name: 'Training Away',
            url: 'https://www.theaba.org/wp-content/uploads/2023/02/TrainingAwayFromAccreditedProgramRequestForm.docx',
            mustBePrimary: false,
        },
    ]

    return (
        <div className="container">
            <div className="title">RTID Help Center</div>
            <ABAContainer label={title}>
                <div className="linkContainer">
                    {helpLinks.map((helperLink, index) => {
                        if (
                            userData.ProgramType !== GeneralEnums.programTypes.primary &&
                            helperLink.mustBePrimary
                        ) {
                            return null
                        }
                        if (Array.isArray(helperLink.url)) {
                            return (
                                <div key={`${helperLink.name}-${index}`}>
                                    <div className="linkTitle">{helperLink.name}</div>
                                    <HelpAccordion
                                        links={helperLink.url}
                                        key={`${helperLink.name}-${index}`}
                                    />
                                </div>
                            )
                        }
                        return (
                            <div key={`${helperLink.name}-${index}`}>
                                <div className="linkTitle">{helperLink.name}</div>
                                <a
                                    className="link"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    download
                                    href={helperLink.url}
                                >
                                    {`View Here >`}
                                </a>
                            </div>
                        )
                    })}
                </div>
            </ABAContainer>
        </div>
    )
}

export default Help
