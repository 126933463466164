import React, {useState, useEffect} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import RTIDAPI from '../../helpers/AxiosInstance'
import useUser from '../../libs/hooks/useUser'
import AddRemoveTable from '../components/AddRemoveTable/AddRemoveTable'
import {GeneralEnums} from '../../helpers/GeneralEnums'
import {LoadingOverlay, Modal, Button, StyleVariant, TextInput, Dropdown} from 'aba-ui'
import useOptionSets from '../../libs/hooks/useOptionSets'
import '../styles/ite.css'
import {ColorEnums} from '../../helpers/GeneralEnums'
import ApproveModal from '../components/ITE/ApproveModal'
import VenueModal from '../components/ITE/VenueModal'


export default function ITERoster(props) {
    const {userData} = useUser()
    const location = useLocation()
    const {IsCanadianProgram} = userData
    const navigate = useNavigate()
    const optionSets = useOptionSets()
    const {rosterId, sessionId, rosterStatusCode, eventName, sessionName, eventId, invoiceId} =
        location.state
    
    const {Draft} = GeneralEnums.rosterStatusCodes

    const [loading, setLoading] = useState('Loading Roster')
    const [inRoster, setInRoster] = useState([])
    const [inRosterArr, setInRosterArr] = useState([])
    const [modalRosterDetailId, setModalRosterDetailId] = useState(null)
    const [modalFirstName, setModalFirstName] = useState('')
    const [modalLastName, setModalLastName] = useState('')
    const [modalYear, setModalYear] = useState('')
    const [notInRoster, setNotInRoster] = useState([])
    const [notInRosterArr, setNotInRosterArr] = useState([])
    const [showEditModal, setShowEditModal] = useState(false)
    const [modalInputStatus, setModalInputStatus] = useState({
        modalFirstName: GeneralEnums.textInputStatus.default,
        modalLastName: GeneralEnums.textInputStatus.default,
        modalYear: GeneralEnums.textInputStatus.default,
    })
    const {standard} = GeneralEnums.textInputStatus
    const [invoiceConfirmation, setInvoiceConfirmation] = useState(null)
    const [showApproveModal, setShowApproveModal] = useState(false)
    const [showApproveConfirmModal, setShowApproveConfirmModal] = useState(false)
    const [venueModalContact, setVenueModalContact] = useState(null)

    const createRosterArr = (arr, canadianNotInRosterArr = false) => {
        if (arr.length < 1) {
            return []
        }

        if (canadianNotInRosterArr) {
            return arr.map((data) => {
                const {ContactFullName, ProgramYear} = data
                return [ContactFullName, null, 'No REF', ProgramYear]
            })
        }

        return arr.map((data) => {
            const {ContactFullName, VenueName, REFStatusCodeLabel, ProgramYear} = data
            return [ContactFullName, VenueName, REFStatusCodeLabel, ProgramYear]
        })
    }

    const fetchRosterData = async () => {
        try {
            const notInRosterRoute = IsCanadianProgram
                ? `ite/notInRosterCanadian/${userData.ProgramId}/${eventId}/${rosterId}`
                : `ite/notInRoster/${userData.ProgramId}/${sessionId}/${rosterId}`

            const response = await Promise.all([
                RTIDAPI.get(`ite/inRoster/${rosterId}`),
                RTIDAPI.get(notInRosterRoute),
            ])

            setInRoster(response[0].data)
            setInRosterArr(createRosterArr(response[0].data))
            setNotInRoster(response[1].data)
            setNotInRosterArr(createRosterArr(response[1].data, IsCanadianProgram))
            setLoading(null)
        } catch (error) {
            setLoading(null)
            alert(error)
        }
    }

    const getInvoiceConfirm = async () => {
        try {
            setLoading('Loading confirmation...')
            const {data} = await RTIDAPI.get(`/invoice/rosterConfirm/${rosterId}`)
            setInvoiceConfirmation(data)
            setShowApproveModal(true)
        } catch (error) {
            alert('Failed to approve your roster')
            throw error
        } finally {
            setLoading(null)
        }
    }

    useEffect(() => {
        fetchRosterData()
    }, [])

    const approveRoster = async () => {
        try {
            setLoading('Approving roster')
            setShowApproveConfirmModal(false)
            const {data} = await RTIDAPI.post(`ite/rosterApproval/${rosterId}`)
            navigate(`/invoice/${data}`)
        } catch (error) {
            alert(error)
        } finally {
            setLoading(null)
        }
    }

    const addDoctorFromModal = async () => {
        try {
            setLoading('Adding doctors to roster')
            const config = {
                params: {
                    programId: userData.ProgramId,
                },
            }

            const doctor = {
                NonAmericanFirstName: modalFirstName,
                NonAmericanLastName: modalLastName,
                NonAmericanId: `${userData.ProgramNumber}${userData.ProgramType}`,
                ProgramYearValue: modalYear,
                RosterId: rosterId,
            }

            await RTIDAPI.post('ite/rosterDetailCanadian', doctor, config)

            fetchRosterData()
        } catch (error) {
            setLoading(null)
            alert(error)
        }
    }

    const addDoctorsToRoster = async (selectedDoctors) => {
        try {
            setLoading('Adding doctors to roster')
            const config = {
                params: {
                    programId: userData.ProgramId,
                },
            }

            let doctors = []

            const selectedDoctorNames = selectedDoctors.map((sd) => {
                return sd[0]
            })

            doctors = notInRoster.filter((doctor) => {
                return selectedDoctorNames.includes(doctor.ContactFullName)
            })

            if (IsCanadianProgram) {
                config.params.rosterId = rosterId

                await RTIDAPI.post('ite/rosterDetailsCanadian', doctors, config)
            } else {
                await RTIDAPI.post('ite/rosterDetails', doctors, config)
            }

            await fetchRosterData()
            window.scrollTo(0, 0)
        } catch (error) {
            setLoading(null)
            alert(error)
        }
    }

    const deleteDoctorFromRoster = async (selectedDoctor) => {
        try {
            setLoading('Deleting doctor from roster')

            const selectedDoctorName = selectedDoctor[0]

            const doctorData = inRoster.find((doctor) => {
                return selectedDoctorName.includes(doctor.ContactFullName)
            })

            await RTIDAPI.delete(`ite/rosterDetail/${doctorData.RosterDetailId}`)
            fetchRosterData()
            setLoading(null)
        } catch (error) {
            setLoading(null)
            alert(error)
        }
    }

    const submitRosterEdit = async (closeModal = false) => {
        try {
            setLoading('Editing roster')

            const params = {
                RosterDetailId: modalRosterDetailId,
                RosterId: rosterId,
                NonAmericanFirstName: modalFirstName,
                NonAmericanLastName: modalLastName,
                ProgramYearValue: modalYear,
            }

            const {data} = await RTIDAPI.put(`ite/rosterDetail`, params)
            setInRoster(data)
            setInRosterArr(createRosterArr(data))
            setLoading(null)
            if (closeModal) {
                resetModalStates()
                setShowEditModal(null)
            }
        } catch (error) {
            alert(error)
            setLoading(null)
        }
    }

    const updateVenue = async (venueId) => {
        try {
            const params = {
                RosterDetailId: inRoster[venueModalContact].RosterDetailId,
                RosterId: rosterId,
                VenueId: venueId,
            }
            setLoading('Updating venue')
            setVenueModalContact(null)
            const {data} = await RTIDAPI.put(`ite/rosterDetail`, params)
            setInRoster(data)
            setInRosterArr(createRosterArr(data))
            setLoading(null)
        } catch (error) {
            alert(error)
            setLoading(null)
        }
    }

    const resetModalStates = () => {
        setModalFirstName('')
        setModalLastName('')
        setModalYear(null)
        setModalRosterDetailId(null)
    }

    const validateModal = () => {
        let status = {...modalInputStatus}
        const statusErr = GeneralEnums.textInputStatus.error

        Object.keys(status).forEach((value) => (status[value] = GeneralEnums.textInputStatus.default))

        if (!modalFirstName) {
            status.modalFirstName = statusErr
        }

        if (!modalLastName) {
            status.modalLastName = statusErr
        }

        if (!modalYear) {
            status.modalYear = statusErr
        }

        setModalInputStatus(status)
        if (Object.values(status).includes(statusErr)) {
            return false
        }

        return true
    }

    const renderEditModal = () => {
        return (
            <Modal>
                {loading && <LoadingOverlay title={loading} />}
                <div className="modal">
                    <h3 style={{textAlign: 'center', paddingBottom: 15}}>
                        {showEditModal === 'add' ? 'Add Doctor' : 'Edit Doctor'}
                    </h3>
                    <TextInput
                        label="First Name"
                        placeholder="First Name"
                        maxLength={100}
                        onChange={(event) => {
                            setModalFirstName(event.target.value)
                        }}
                        value={modalFirstName}
                        textInputStatus={modalInputStatus.modalFirstName}
                        required
                    />
                    <TextInput
                        label="Last Name"
                        placeholder="Last Name"
                        maxLength={100}
                        onChange={(event) => {
                            setModalLastName(event.target.value)
                        }}
                        value={modalLastName}
                        textInputStatus={modalInputStatus.modalLastName}
                        required
                    />
                    <Dropdown
                        required
                        placeholder="Program Year "
                        onChange={(value) => {
                            setModalYear(value)
                        }}
                        options={optionSets.programYears.map((programYear) => {
                            return {
                                value: programYear.Value,
                                name: programYear.Label,
                            }
                        })}
                        errorMessage={'Please select a value'}
                        dropdownStatus={modalInputStatus.modalYear}
                        selectedValue={modalYear}
                    />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Button
                            variant={StyleVariant.OUTLINED}
                            style={{width: '180px', fontSize: '14px'}}
                            onClick={() => {
                                setShowEditModal(null)
                                resetModalStates()
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            variant={StyleVariant.FILLED}
                            style={{width: '180px', fontSize: '14px'}}
                            onClick={() => {
                                if (validateModal()) {
                                    if (showEditModal === 'add') {
                                        addDoctorFromModal().then(() => {
                                            resetModalStates()
                                        })
                                    } else {
                                        submitRosterEdit()
                                    }
                                }
                            }}
                        >
                            {showEditModal === 'add' ? 'Save / Add Another' : 'Save'}
                        </Button>
                        <Button
                            variant={StyleVariant.FILLED}
                            style={{width: '180px', fontSize: '14px'}}
                            onClick={() => {
                                if (validateModal()) {
                                    if (showEditModal === 'add') {
                                        addDoctorFromModal().then(() => {
                                            setShowEditModal(null)
                                        })
                                    } else {
                                        submitRosterEdit(true)
                                    }
                                }
                            }}
                        >
                            Save / Close
                        </Button>
                    </div>
                </div>
            </Modal>
        )
    }

    const renderApproveConfirmModal = () => {
        return (
            <Modal>
                <div
                    style={{
                        backgroundColor: 'white',
                        width: '550px',
                        padding: '15px',
                        border: `1px solid ${ColorEnums.cloudBurst}`,
                        borderRadius: '10px',
                        textAlign: 'center',
                    }}
                >
                    <h4>Are you sure you want to approve this roster?</h4>
                    <div style={{width: '100%', flexDirection: 'row', display: 'flex'}}>
                        <div style={{flex: 1}}>
                            <div
                                style={{
                                    width: 170,
                                    margin: 'auto',
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        setShowApproveConfirmModal(false)
                                        setShowApproveModal(true)
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                        <div style={{flex: 1}}>
                            <div
                                style={{
                                    width: 170,
                                    margin: 'auto',
                                }}
                            >
                                <Button variant="filled" onClick={approveRoster}>
                                    Approve
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

    const isRosterDraft = rosterStatusCode === Draft
    return (
        <div>
            {loading && <LoadingOverlay title={loading} />}
            {showEditModal && renderEditModal()}
            <AddRemoveTable
                headers={['Name', 'Venue', 'REF Status', 'Current Year']}
                colWidths={['25%', '25%', '25%', '10%']}
                bottomColWidths={['80%', '10%', '10%']}
                initialTopData={inRosterArr}
                initialBottomData={notInRosterArr}
                topTableLabel="Doctors in Roster"
                topTableLink={[1]}
                topTableLinkFunc={(i) => {
                    setVenueModalContact(i)
                }}
                bottomTableLabel="Add to Roster"
                hideBottomTable={!isRosterDraft}
                hideBottomColumns={[1]}
                hideRemove={!isRosterDraft}
                onAdd={isRosterDraft ? addDoctorsToRoster : () => {}}
                onEdit={(i) => {
                    setShowEditModal('edit')
                    setModalRosterDetailId(inRoster[i].RosterDetailId)
                    setModalFirstName(inRoster[i].NonAmericanFirstName)
                    setModalLastName(inRoster[i].NonAmericanLastName)
                    setModalInputStatus({
                        modalFirstName: standard,
                        modalLastName: standard,
                        modalYear: standard,
                    })
                    const programYearValue = optionSets.programYears.find(
                        (py) => inRoster[i].ProgramYear === py.Label,
                    )

                    setModalYear(programYearValue.Value)
                }}
                onDelete={deleteDoctorFromRoster}
                onTopButtonClick={() => {
                    setModalInputStatus({
                        modalFirstName: standard,
                        modalLastName: standard,
                        modalYear: standard,
                    })
                    setModalRosterDetailId(null)
                    setShowEditModal('add')
                }}
                showEdit={IsCanadianProgram}
                showTopButton={IsCanadianProgram && isRosterDraft}
                topButtonLabel="Add Doctor"
                onSubmit={
                    isRosterDraft
                        ? getInvoiceConfirm
                        : () => {
                              navigate(`/invoice/${invoiceId}`)
                          }
                }
                submitLabel={isRosterDraft ? 'Approve Roster' : 'View Invoice'}
                directorOnly={isRosterDraft}
            />
            {showApproveModal && (
                <ApproveModal
                    title={sessionName}
                    lineItems={invoiceConfirmation}
                    onApprove={() => {
                        setShowApproveConfirmModal(true)
                        setShowApproveModal(false)
                    }}
                    onCancel={() => {
                        setShowApproveModal(false)
                    }}
                />
            )}
            {showApproveConfirmModal && renderApproveConfirmModal()}
            {venueModalContact !== null && (
                <VenueModal
                    rosterId={rosterId}
                    name={inRoster[venueModalContact].ContactFullName}
                    onSave={(venueId) => {
                        updateVenue(venueId)
                    }}
                    onCancel={() => {
                        setVenueModalContact(null)
                    }}
                />
            )}
        </div>
    )
}
