import React, {useState, useEffect} from 'react'
import {Button, LoadingOverlay, Modal, TextInput} from 'aba-ui'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import {ColorEnums} from '../../../helpers/GeneralEnums'
import RTIDAPI from '../../../helpers/AxiosInstance'

const bodyCellStyle = {
    color: ColorEnums.cloudBurst,
    fontFamily: 'lato',
}

const VenueModal = ({onSave, onCancel, rosterId, name}) => {
    const [venues, setVenues] = useState(null)
    const [filteredVenues, setFilteredVenues] = useState(null)
    const [selectedVenue, setSelectedVenue] = useState(null)
    const [searchText, setSearchText] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        ;(async () => {
            try {
                const {data} = await RTIDAPI.get(`ite/rosters/${rosterId}/venues`)
                setVenues(
                    data.sort((a, b) => {
                        return a.Label > b.Label ? 1 : -1
                    }),
                )
            } catch (error) {
                alert('Failed to load venues')
            } finally {
                setLoading(false)
            }
        })()
    }, [])

    useEffect(() => {
        if (!venues?.length) {
            return
        }
        const newFilteredVenues = venues.filter((venue) => {
            return venue.Label.toLowerCase().includes(searchText.toLowerCase())
        })

        newFilteredVenues.sort((a, b) => {
            return a.Label > b.Label ? 1 : -1
        })
        setFilteredVenues(newFilteredVenues)
    }, [searchText, venues])

    return (
        <Modal>
            <div
                style={{
                    backgroundColor: 'white',
                    width: '550px',
                    maxHeight: '550px',
                    padding: '15px',
                    border: `1px solid ${ColorEnums.cloudBurst}`,
                    borderRadius: '10px',
                }}
            >
                {loading && <LoadingOverlay fullscreen={false} title={'Loading venues'} />}
                <h4 style={{textAlign: 'center', paddingBottom: 20}}>Select a venue for {name}</h4>
                <TextInput
                    placeholder="Search"
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                />
                <div style={{maxHeight: '350px', overflow: 'auto'}}>
                    <Table sx={{margin: 'auto', overflow: 'scroll'}} style={{tableLayout: 'fixed'}}>
                        <colgroup>
                            <col width={50} />
                            <col />
                        </colgroup>
                        {filteredVenues?.length ? (
                            <TableBody>
                                {filteredVenues.map((venue) => {
                                    return (
                                        <TableRow
                                            key={venue.Value}
                                            onClick={() => {
                                                setSelectedVenue(venue.Value)
                                            }}
                                            style={{cursor: 'pointer'}}
                                            hover
                                        >
                                            <TableCell>
                                                <input
                                                    type="checkbox"
                                                    style={{margin: 'auto'}}
                                                    checked={venue.Value === selectedVenue}
                                                    onChange={() => {}}
                                                />
                                            </TableCell>
                                            <TableCell style={bodyCellStyle}>{venue.Label}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        colSpan={2}
                                        style={{
                                            textAlign: 'center',
                                            ...bodyCellStyle,
                                        }}
                                    >
                                        No records to display
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </div>
                <div style={{width: '100%', flexDirection: 'row', display: 'flex'}}>
                    <div style={{flex: 1}}>
                        <div
                            style={{
                                width: 170,
                                margin: 'auto',
                            }}
                        >
                            <Button variant="outlined" onClick={onCancel}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                    <div style={{flex: 1}}>
                        <div
                            style={{
                                width: 170,
                                margin: 'auto',
                            }}
                        >
                            <Button
                                variant="filled"
                                onClick={() => {
                                    onSave(selectedVenue)
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default VenueModal
