import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import HelperMethods from '../../../helpers/HelperMethods'
import ResidentListingDetailsHeader from './ResidentListingDetailsHeader'
import ABAActionPopup from '../../components/ABAComponents/ABAActionPopup/ABAActionPopup'
import ABAStatusLegend from '../../components/ABAComponents/ABAStatusLegend/ABAStatusLegend'
import {Dropdown, TextInput, Button, StyleVariant, LoadingOverlay} from 'aba-ui'
import CustomHooks from '../../../helpers/CustomHooks'
import useEnrollment from '../../../libs/hooks/useEnrollment'
import useUser from '../../../libs/hooks/useUser'
import {useDispatch, useSelector} from 'react-redux'
import {ExpandedResidentSuccess} from '../../../redux/Actions/ExpandedResidentActionCreator'
import {ColorEnums, GeneralEnums} from '../../../helpers/GeneralEnums'
import styles from './ResidentListing.module.css'
import TrainingRecordTable from '../../components/Training/TrainingRecordTable'
import RTIDAPI from '../../../helpers/AxiosInstance'

const ResidentListingTableGrid = (props) => {
    const navigate = useNavigate()
    const [loadingSummary, setLoadingSummary] = useState(false)
    const {dataset, loading, trainingRecords, trainingSummary, residentEnrollmentId} =
        props

    const previousDataset = CustomHooks.usePrevious(dataset)
    const {updateEnrollmentStatus} = useEnrollment()
    const {Draft, Validated} = GeneralEnums.residentEnrollmentStatusCodes

    const {
        userData: {UserRole},
    } = useUser()

    const dispatch = useDispatch()
    const canApprove = UserRole === 'ProgramDirector' || UserRole === 'SuperUser'

    // setting current enrollment and previous enrollment variables so that the filters specifically for these groups residents have a consistent dataset to go through
    const [currentEnrollmentResidents, setCurrentEnrollmentResidents] = useState(
        dataset.filter(
            (data) =>
                data.ResidentEnrollmentStateCode ===
                GeneralEnums.residentEnrollmentStateCode.Active,
        ),
    )

    let expandedResidentRow = useSelector(({expandedResident}) => expandedResident)
    const setExpandedResidentRow = (residentRow) => {
        dispatch(ExpandedResidentSuccess(residentRow))
    }

    let scrollValue = expandedResidentRow.scrollValue

    const [trackTypeOptions, setTrackTypeOptions] = useState(
        [...new Set(currentEnrollmentResidents.map((resident) => resident.Track))].sort(),
    )

    const [currentYearOptions, setCurrentYearOptions] = useState(
        [
            ...new Set(
                currentEnrollmentResidents.map((resident) => resident.ProgramYear),
            ),
        ].sort(),
    )

    // setting current enrollment as the default table data
    const [tableData, setTableData] = useState(currentEnrollmentResidents)
    const [enrollmentFilter, setEnrollmentFilter] = useState({
        current: true,
        previous: false,
    })

    const [trainingRecordsData, setTrainingRecordsData] = useState(trainingRecords)
    const [trainingSummaryData, setTrainingSummaryData] = useState(trainingSummary)

    // Default for the active column name is Resident Name and is in ascending order.  This is how we originally get the dataset
    const [activeColumn, setActiveColumn] = useState({
        columnName: 'Resident Name',
        sortOrder: 'ascending',
    })

    const [trackTypeFilter, setTrackTypeFilter] = useState('')
    const [currentYearFilter, setCurrentYearFilter] = useState('')
    const [nameSearch, setNameSearch] = useState('')
    const [ssnInput, setSsnInput] = useState('')
    const [previousResident, setPreviousResident] = useState(null)
    const [previousTrainingRecord, setPreviousTrainingRecord] = useState(null)
    const [previousTrainingSummary, setPreviousTrainingSummary] = useState(null)
    const [findingResident, setFindingResident] = useState(false)

    const {
        tableContainer,
        headerRow,
        parentTable,
        statusContainer,
        greenStatus,
        yellowStatus,
        redStatus,
        noStatus,
        sortable,
        toggleRecordDisplay,
        filterContainer,
        hoverPointer,
    } = styles

    const getInactiveResident = () => {
        const ssnNoDashes = ssnInput.replace(/\D/g, '')
        return new Promise((resolve) => {
            RTIDAPI.get(`residentListing/inactiveResident/${ssnNoDashes}/`, {})
                .then((response) => resolve(response.data))
                .catch((err) => console.error(err))
        })
    }

    const getInactiveTrainingRecords = (inactiveResidentEnrollmentId) => {
        return new Promise((resolve) => {
            RTIDAPI.get(
                `training/inactiveTrainingRecords/${inactiveResidentEnrollmentId}`,
                {},
            )
                .then((response) => resolve(response.data))
                .catch((err) => console.error(err))
        })
    }

    const getInactiveTrainingSummary = (inactiveResidentEnrollmentId) => {
        return new Promise((resolve) => {
            RTIDAPI.get(
                `training/inactiveTrainingSummary/${inactiveResidentEnrollmentId}`,
                {},
            )
                .then((response) => resolve(response.data))
                .catch((err) => console.error(err))
        })
    }

    useEffect(() => {
        if (!loading) {
            window.scrollTo({top: scrollValue})
        }
    }, [loading])

    useEffect(() => {
        if (previousDataset !== dataset) {
            const currentResidents = dataset.filter(
                (data) =>
                    data.ResidentEnrollmentStateCode ===
                    GeneralEnums.residentEnrollmentStateCode.Active,
            )
            setTableData(currentResidents)
            setCurrentEnrollmentResidents(currentResidents)
            setTrackTypeOptions(
                [...new Set(currentResidents.map((resident) => resident.Track))].sort(),
            )
            setCurrentYearOptions(
                [
                    ...new Set(currentResidents.map((resident) => resident.ProgramYear)),
                ].sort(),
            )
            setTrainingRecordsData(trainingRecordsData)
            setTrainingSummaryData(trainingSummary)

            if (residentEnrollmentId) {
                const selectedResident = dataset.find(
                    (resident) => resident.ResidentEnrollmentId === residentEnrollmentId,
                )
                setExpandedResidentRow({residentEnrollmentId, data: selectedResident})
            }
        }
    }, [previousDataset, dataset, trainingRecordsData, trainingSummaryData])

    const sortData = (columnName, property) => {
        const data = [...tableData]
        const activeColumnChange = {columnName}

        const ascOrder = (arr) => {
            arr.sort((a, b) => {
                if (a[property] < b[property]) {
                    return -1
                }
                if (a[property] > b[property]) {
                    return 1
                }

                return 0
            })

            return arr
        }

        const descOrder = (arr) => {
            arr.sort((a, b) => {
                if (b[property] < a[property]) {
                    return -1
                }
                if (b[property] > a[property]) {
                    return 1
                }

                return 0
            })

            return arr
        }

        if (
            activeColumn.columnName === columnName &&
            activeColumn.sortOrder === 'ascending'
        ) {
            activeColumnChange.sortOrder = 'descending'
            setTableData(descOrder(data))
        } else if (
            activeColumn.columnName === columnName &&
            activeColumn.sortOrder === 'descending'
        ) {
            activeColumnChange.sortOrder = 'ascending'
            setTableData(ascOrder(data))
        } else {
            activeColumnChange.sortOrder = 'ascending'
            setTableData(ascOrder(data))
        }

        setActiveColumn(activeColumnChange)
    }

    const applyCSS = (type, statusCode, stateCode) => {
        let cssToApply = ''

        switch (type) {
            case 'enrollment':
                if (statusCode === GeneralEnums.residentEnrollmentStatusCodes.Draft) {
                    cssToApply = `${statusContainer} ${redStatus}`
                } else if (
                    statusCode === GeneralEnums.residentEnrollmentStatusCodes.Validated
                ) {
                    cssToApply = `${statusContainer} ${yellowStatus}`
                } else {
                    cssToApply = `${statusContainer} ${greenStatus}`
                }
                break
            case 'training':
                if (statusCode === GeneralEnums.trainingStatusCodes.Draft) {
                    cssToApply = `${statusContainer} ${redStatus}`
                } else if (
                    statusCode === GeneralEnums.trainingStatusCodes.Validated ||
                    statusCode === GeneralEnums.trainingStatusCodes.NeedsAction
                ) {
                    cssToApply = `${statusContainer} ${yellowStatus}`
                } else if (
                    (stateCode &&
                        stateCode === GeneralEnums.trainingStateCodes.Inactive) ||
                    statusCode === GeneralEnums.trainingStatusCodes.Approved
                ) {
                    cssToApply = `${statusContainer} ${greenStatus}`
                } else {
                    cssToApply = `${statusContainer} ${noStatus}`
                }
                break
            case 'pdir':
                if (statusCode === GeneralEnums.pdirStatusCodes.Draft) {
                    cssToApply = `${statusContainer} ${redStatus}`
                } else if (
                    statusCode === GeneralEnums.pdirStatusCodes.Validated ||
                    statusCode === GeneralEnums.pdirStatusCodes.NeedsAction
                ) {
                    cssToApply = `${statusContainer} ${yellowStatus}`
                } else if (
                    stateCode &&
                    stateCode === GeneralEnums.pdirStateCodes.Inactive
                ) {
                    cssToApply = `${statusContainer} ${greenStatus}`
                } else {
                    cssToApply = `${statusContainer} ${noStatus}`
                }
                break
            default:
                break
        }
        return cssToApply
    }
    const renderTrainingSummaryData = () => {
        let selectedTrainingSummary
        if (enrollmentFilter.current) {
            selectedTrainingSummary = trainingSummary.find(
                (ts) =>
                    ts.ResidentEnrollmentId === expandedResidentRow.residentEnrollmentId,
            )
        } else {
            selectedTrainingSummary = previousTrainingSummary
        }

        if (selectedTrainingSummary) {
            const {
                CreditedCA,
                CreditedCB,
                CreditedResearch,
                CreditedSpecialty,
                MonthsCredit,
                ProgramType,
                RequiredCAMonths,
                RequiredCBMonths,
                RequiredSpecialtyMonths,
                TrackType,
                TrainingType,
            } = selectedTrainingSummary
            if (ProgramType === 513 && TrackType !== 4 && selectedTrainingSummary) {
                const remainingCA = RequiredCAMonths - CreditedCA - CreditedResearch
                const remainingCB = RequiredCBMonths - CreditedCB

                return (
                    <tbody>
                        <tr>
                            <td>Clinical Anesthesia</td>
                            <td>{CreditedCA.toFixed(2)}</td>
                            <td>{RequiredCAMonths.toFixed(2)}</td>
                            <td>{remainingCA.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>Clinical Base</td>
                            <td>{CreditedCB.toFixed(2)}</td>
                            <td>{RequiredCBMonths.toFixed(2)}</td>
                            <td>{remainingCB.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>Research</td>
                            <td>{CreditedResearch.toFixed(2)}</td>
                            <td />
                            <td />
                        </tr>
                    </tbody>
                )
            }

            if (ProgramType === 513 && TrackType === 4) {
                const remaining = RequiredSpecialtyMonths - MonthsCredit
                return (
                    <tbody>
                        <tr>
                            <td>{TrainingType}</td>
                            <td>{MonthsCredit.toFixed(2)}</td>
                            <td>{RequiredSpecialtyMonths.toFixed(2)}</td>
                            <td>{remaining.toFixed(2)}</td>
                        </tr>
                    </tbody>
                )
            }

            const remainingSpecialty =
                RequiredSpecialtyMonths - CreditedSpecialty - CreditedResearch

            return (
                <tbody>
                    <tr>
                        <td>{TrainingType}</td>
                        <td>{CreditedSpecialty.toFixed(2)}</td>
                        <td>{RequiredSpecialtyMonths.toFixed(2)}</td>
                        <td>{remainingSpecialty.toFixed(2)}</td>
                    </tr>
                </tbody>
            )
        } else {
            return (
                <tbody>
                    <tr>
                        <td colSpan={4} style={{textAlign: 'center'}}>
                            No Training Summary to display
                        </td>
                    </tr>
                </tbody>
            )
        }
    }

    const handleResidentListingUpdate = async (
        programId,
        residentEnrollmentId,
        statusCode,
    ) => {
        updateEnrollmentStatus(programId, residentEnrollmentId, statusCode)
    }

    const determineREFActions = (statuscode, statecode) => {
        const {BirthDate, ResidentEnrollmentId, ProgramId} = expandedResidentRow.data

        const edit = {
            name: 'Edit',
            onClick: () => {
                navigate('/enrollment',{
                    state: {
                        BirthDate,
                        ResidentEnrollmentId,
                        mode: '',
                    },
                })
            },
        }
        const cancel = {
            name: 'Cancel',
            onClick: () => {
                handleResidentListingUpdate(
                    ProgramId,
                    ResidentEnrollmentId,
                    GeneralEnums.residentEnrollmentStatusCodes.CancelledProgram,
                )
            },
        }
        const approve = {
            name: 'Approve',
            onClick: () => {
                navigate('/enrollment',{
                    state: {
                        setIsConfirmation: true,
                        SSN: null,
                        BirthDate,
                        NPI: null,
                        ResidentEnrollmentId,
                    },
                })
            },
        }
        if (statuscode === Draft) {
            return [edit, cancel]
        }

        if (statuscode === Validated) {
            return canApprove ? [edit, approve, cancel] : [edit, cancel]
        }

        if (
            statuscode === GeneralEnums.residentEnrollmentStatusCodes.Enrolled ||
            statuscode === GeneralEnums.residentEnrollmentStatusCodes.Registered ||
            statecode === GeneralEnums.residentEnrollmentStateCode.Inactive
        ) {
            return [
                {
                    name: 'View',
                    onClick: () => {
                        navigate('/enrollment',{
                            state: {
                                ResidentEnrollmentId,
                                mode: 'view',
                                previousResident: enrollmentFilter.previous
                                    ? [previousResident]
                                    : null,
                                previousTrainingRecord: enrollmentFilter.previous
                                    ? [previousTrainingRecord]
                                    : null,
                            },
                        })
                    },
                },
            ]
        }
        return []
    }

    const renderTrainingRecordsData = () => {
        let filteredTrainingRecords = []
        if (enrollmentFilter.current) {
            filteredTrainingRecords = [...trainingRecords].filter(
                (data) =>
                    data.ResidentEnrollmentId.toLowerCase() ===
                    expandedResidentRow.residentEnrollmentId.toLowerCase(),
            )
        } else {
            filteredTrainingRecords = previousTrainingRecord
        }

        return (
            <TrainingRecordTable
                trainingData={filteredTrainingRecords}
                previousResident={enrollmentFilter.previous ? previousResident : null}
                previousTrainingRecord={
                    enrollmentFilter.previous ? previousTrainingRecord : null
                }
            />
        )
    }

    const renderResidentDetails = () => {
        const {
            TrainingStartDate,
            Track,
            ApprovedDate,
            ProgramYear,
            GraduationDate,
            ResidentEnrollmentStatusCode,
            ResidentEnrollmentStateCode,
        } = expandedResidentRow.data

        return (
            <tr key={`${expandedResidentRow.residentEnrollmentId}-details`}>
                <td colSpan="8" style={{padding: 0, height: '100%'}}>
                    <ResidentListingDetailsHeader label="Resident Enrollment">
                        <table className={parentTable}>
                            <thead>
                                <tr className={headerRow}>
                                    <th>Start of Training</th>
                                    <th>Track</th>
                                    <th>Approved Date</th>
                                    <th>Current Year</th>
                                    <th>Graduation Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{HelperMethods.formatDate(TrainingStartDate)}</td>
                                    <td>{Track}</td>
                                    <td>{HelperMethods.formatDate(ApprovedDate)}</td>
                                    <td>{ProgramYear}</td>
                                    <td>{HelperMethods.formatDate(GraduationDate)}</td>
                                    <td>
                                        <ABAActionPopup
                                            actions={determineREFActions(
                                                ResidentEnrollmentStatusCode,
                                                ResidentEnrollmentStateCode,
                                            )}
                                            uniqueId={
                                                expandedResidentRow.residentEnrollmentId
                                            }
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ResidentListingDetailsHeader>
                    <ResidentListingDetailsHeader
                        label="Training Records"
                        linkName="Add New Training Record"
                        linkUrl="/trainingRecords"
                        residentEnrollmentId={expandedResidentRow?.residentEnrollmentId}
                    >
                        {renderTrainingRecordsData()}
                    </ResidentListingDetailsHeader>
                    <ResidentListingDetailsHeader label="Training Summary">
                        {loadingSummary && <LoadingOverlay title="Loading..." />}
                        <table className={parentTable}>
                            <thead>
                                <tr className={headerRow}>
                                    <th style={{width: '25%'}}>Training Type</th>
                                    <th>Credited</th>
                                    <th>Required</th>
                                    <th>Remaining</th>
                                </tr>
                            </thead>
                            {trainingSummary.length > 0 && renderTrainingSummaryData()}
                        </table>
                    </ResidentListingDetailsHeader>
                </td>
            </tr>
        )
    }

    const renderSortIcon = (columnName) => {
        if (columnName === activeColumn.columnName) {
            return activeColumn.sortOrder === 'ascending' ? 'sort-up' : 'sort-down'
        }

        return 'sort'
    }

    const renderFeedbackIcon = (row) => {
        const {InReview, Resolved, Responded} = GeneralEnums.feedbackStatusCodes
        const {FeedbackId, FeedbackStatusCode, ResidentEnrollmentId} = row
        let feedbackColor = null
        const trackDisplayName = row.TrackDisplayName
        const handleTrackCheck = () => {
            if (UserRole === 'DepartmentChair') {
                if (trackDisplayName === 'AEP') {
                    return navigate('/trainingFeedback',{
                        state: {
                            residentEnrollmentId: ResidentEnrollmentId,
                        },
                    })
                } else {
                    return navigate('/residentListing',{
                    })
                }
            } else if (UserRole === 'ProgramDirector') {
                if (trackDisplayName === 'AEP') {
                    return navigate('/residentListing',{
                    })
                } else {
                    return navigate('/trainingFeedback',{
                        state: {
                            residentEnrollmentId: ResidentEnrollmentId,
                        },
                    })
                }
            } else if (UserRole === 'SuperUser') {
                return navigate('/trainingFeedback',{
                    state: {
                        residentEnrollmentId: ResidentEnrollmentId,
                    },
                })
            } else {
                return navigate('/residentListing',{
                })
            }
        }

        if (!FeedbackId) {
            return (
                <FontAwesomeIcon
                    icon="edit"
                    color={ColorEnums.midnight}
                    className={hoverPointer}
                    style={{fontSize: '1rem', alignSelf: 'center'}}
                    onClick={handleTrackCheck}
                />
            )
        }
        if (FeedbackStatusCode === InReview) {
            feedbackColor = ColorEnums.citrusLight
        } else if (FeedbackStatusCode === Resolved || FeedbackStatusCode === Responded) {
            feedbackColor = ColorEnums.surfGreenLight
        }
        if (
            FeedbackStatusCode === InReview ||
            FeedbackStatusCode === Resolved ||
            FeedbackStatusCode === Responded
        ) {
            return (
                <div
                    onClick={handleTrackCheck}
                    style={{
                        backgroundColor: feedbackColor,
                        cursor: 'pointer',
                        paddingTop: 11,
                        paddingBottom: 11,
                        width: 40,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        borderRadius: '50%',
                        position: 'relative',
                    }}
                >
                    {FeedbackStatusCode === Responded && (
                        <div
                            style={{
                                backgroundColor: ColorEnums.pomegranateDark,
                                height: '12px',
                                width: '12px',
                                borderRadius: '50%',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                color: 'white',
                                fontSize: '9px',
                            }}
                        >
                            1
                        </div>
                    )}
                    <FontAwesomeIcon
                        icon="edit"
                        color={ColorEnums.midnight}
                        style={{fontSize: '1rem', alignSelf: 'center'}}
                    />
                </div>
            )
        }
    }

    const renderTableData = () => {
        if (loading) {
            return (
                <tr align="center" colSpan={4}>
                    <td>
                        Loading
                        <FontAwesomeIcon
                            color={ColorEnums.danube}
                            icon="spinner"
                            style={{marginLeft: '10px'}}
                            spin
                        />
                    </td>
                </tr>
            )
        }

        if (tableData.length > 0) {
            const expandedRowIndex = tableData.findIndex(
                (data) =>
                    data.ResidentEnrollmentId ===
                    expandedResidentRow.residentEnrollmentId,
            )
            const tableRows = [...tableData]

            if (expandedRowIndex !== -1) {
                tableRows.splice(expandedRowIndex + 1, 0, null)
            }
            return tableRows.map((data, index) => {
                if (!data) {
                    return renderResidentDetails(index)
                }

                return (
                    <tr
                        key={`${data.ResidentEnrollmentId}-${data.ResidentEnrollmentStatusCode}`}
                    >
                        <td
                            className={toggleRecordDisplay}
                            style={{width: 200, textAlign: 'left'}}
                            role="gridcell"
                            onClick={() => {
                                if (
                                    expandedResidentRow.residentEnrollmentId ===
                                    data.ResidentEnrollmentId
                                ) {
                                    setExpandedResidentRow({
                                        residentEnrollmentId: null,
                                        scrollValue: 0,
                                    })
                                } else {
                                    setExpandedResidentRow({
                                        residentEnrollmentId: data.ResidentEnrollmentId,
                                        data,
                                        scrollValue: window.scrollY,
                                    })
                                }
                            }}
                        >
                            <FontAwesomeIcon
                                icon={
                                    expandedResidentRow.residentEnrollmentId ===
                                    data.ResidentEnrollmentId
                                        ? 'angle-down'
                                        : 'angle-right'
                                }
                                color={ColorEnums.danube}
                                style={{marginRight: 10}}
                            />{' '}
                            {data.ResidentDisplayName} {!data.HasABALogin && ' *'}
                        </td>
                        <td>{data.ABAID}</td>
                        <td style={{minWidth: 95}}>{data.TrackDisplayName}</td>
                        <td style={{minWidth: 95}}>{data.ProgramYear}</td>
                        <td>{renderFeedbackIcon(data)}</td>
                        <td>
                            <div
                                className={applyCSS(
                                    'enrollment',
                                    data.ResidentEnrollmentStatusCode,
                                )}
                                style={{marginLeft: 'auto', marginRight: 'auto'}}
                            >
                                {data.ResidentEnrollmentStatus}
                            </div>
                        </td>
                        <td>
                            <div
                                className={applyCSS(
                                    'training',
                                    data.TrainingStatusCode,
                                    data.TrainingStateCode,
                                )}
                                style={{marginLeft: 'auto', marginRight: 'auto'}}
                            >
                                Training
                            </div>
                        </td>
                        <td>
                            <div
                                className={applyCSS(
                                    'pdir',
                                    data.PDIRStatusCode,
                                    data.PDIRStateCode,
                                )}
                                style={{marginLeft: 'auto', marginRight: 'auto'}}
                            >
                                PDIR
                            </div>
                        </td>
                    </tr>
                )
            })
        }
        return (
            <tr>
                <td align="left" colSpan={8}>
                    No Residents to display
                </td>
            </tr>
        )
    }

    const filterCurrentResidents = (currentYear, trackType, name) => {
        let filteredTableData = [...currentEnrollmentResidents]
        if (currentYear) {
            filteredTableData = filteredTableData.filter(
                (data) => data.ProgramYear === currentYear,
            )
        }

        if (trackType) {
            filteredTableData = filteredTableData.filter(
                (data) => data.Track === trackType,
            )
        }

        if (name) {
            filteredTableData = filteredTableData.filter((data) =>
                `${data.FirstName.toLowerCase()} ${data.LastName.toLowerCase()} `.includes(
                    name.toLowerCase(),
                ),
            )
        }

        setTableData(filteredTableData)
    }

    const filterTrackType = (trackType) => {
        setTrackTypeFilter(trackType)
        filterCurrentResidents(currentYearFilter, trackType, nameSearch)
    }

    const filterCurrentYear = (currentYear) => {
        setCurrentYearFilter(currentYear)
        filterCurrentResidents(currentYear, trackTypeFilter, nameSearch)
    }

    return (
        <div>
            {findingResident && <LoadingOverlay />}
            <ABAStatusLegend />
            <div style={{margin: 20}} className={filterContainer}>
                <label htmlFor="currentEnrollment">
                    <input
                        type="radio"
                        id="currentEnrollment"
                        checked={enrollmentFilter.current}
                        onChange={() => {
                            if (enrollmentFilter.current) {
                                return
                            }
                            setEnrollmentFilter({current: true, previous: false})
                            setTrackTypeFilter('')
                            setCurrentYearFilter('')
                            setNameSearch('')
                            setActiveColumn({
                                columnName: 'Resident Name',
                                sortOrder: 'ascending',
                            })
                            setTableData(currentEnrollmentResidents)
                        }}
                    />
                    Current Enrollment
                </label>

                <label htmlFor="previousEnrollment" style={{marginLeft: '150px'}}>
                    <input
                        type="radio"
                        id="previousEnrollment"
                        checked={enrollmentFilter.previous}
                        onChange={() => {
                            if (enrollmentFilter.previous) {
                                return
                            }
                            setEnrollmentFilter({current: false, previous: true})
                            setSsnInput('')
                            setActiveColumn({
                                columnName: 'Resident Name',
                                sortOrder: 'ascending',
                            })
                            setTableData([])
                        }}
                    />
                    Previous Enrollment
                </label>

                <div
                    style={{
                        marginTop: 30,
                    }}
                >
                    {enrollmentFilter.current ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                alignContent: 'center',
                                width: '100%',
                            }}
                        >
                            <div style={{width: '10vw'}}>
                                <Dropdown
                                    placeholder="Track Type"
                                    selectedValue={trackTypeFilter}
                                    options={trackTypeOptions.map((trackTypeOption) => {
                                        return {
                                            value: trackTypeOption,
                                            name: trackTypeOption,
                                        }
                                    })}
                                    onChange={filterTrackType}
                                    style={{
                                        marginRight: 100,
                                        width: '200px',
                                    }}
                                />
                            </div>
                            <div style={{width: '10vw'}}>
                                <Dropdown
                                    placeholder="Current Year"
                                    selectedValue={currentYearFilter}
                                    options={currentYearOptions.map(
                                        (currentYearOption) => {
                                            return {
                                                value: currentYearOption,
                                                name: currentYearOption,
                                            }
                                        },
                                    )}
                                    onChange={(value) => filterCurrentYear(value)}
                                    style={{
                                        marginRight: 100,
                                        width: '300px',
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'inline-block',
                                    width: '10vw',
                                    marginTop: 10,
                                }}
                            >
                                <TextInput
                                    placeholder="Name Search"
                                    onChange={(event) => {
                                        setNameSearch(event.target.value)
                                        filterCurrentResidents(
                                            currentYearFilter,
                                            trackTypeFilter,
                                            event.target.value,
                                        )
                                    }}
                                    value={nameSearch}
                                    name="Name Search"
                                />
                            </div>

                            <Button
                                text="Reset Filters"
                                variant={StyleVariant.FILLED}
                                style={{marginTop: 0, marginRight: '30vw', width: '8vw'}}
                                onClick={() => {
                                    setTrackTypeFilter('')
                                    setCurrentYearFilter('')
                                    setNameSearch('')
                                    setActiveColumn({
                                        columnName: 'Resident Name',
                                        sortOrder: 'ascending',
                                    })
                                    setTableData(currentEnrollmentResidents)
                                }}
                            >
                                Reset Filters
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    height: '45px',
                                }}
                            >
                                <TextInput
                                    placeholder="SSN Search"
                                    onChange={(event) => {
                                        let val = event.target.value.replace(/\D/g, '')
                                        val = val.replace(/^(\d{3})(\d{1,2})/, '$1-$2')
                                        val = val.replace(
                                            /^(\d{3})-(\d{2})(.+)/,
                                            '$1-$2-$3',
                                        )
                                        setSsnInput(val)
                                    }}
                                    maxLength={11}
                                    value={ssnInput}
                                    name="SSN Search"
                                    style={{height: '15px'}}
                                />

                                <Button
                                    type="button"
                                    onClick={async () => {
                                        setFindingResident(true)
                                        setExpandedResidentRow({
                                            residentEnrollmentId: null,
                                            scrollValue: 0,
                                        })
                                        const resident = await getInactiveResident()
                                        if (!resident.ResidentEnrollmentId) {
                                            setTableData([])
                                            setFindingResident(false)
                                            return
                                        }
                                        const inactiveTrainingRecord =
                                            await getInactiveTrainingRecords(
                                                resident.ResidentEnrollmentId,
                                            )
                                        const inactiveTrainingSummary =
                                            await getInactiveTrainingSummary(
                                                resident.ResidentEnrollmentId,
                                            )

                                        setPreviousResident(resident)
                                        setPreviousTrainingRecord(inactiveTrainingRecord)
                                        setPreviousTrainingSummary(
                                            inactiveTrainingSummary,
                                        )
                                        setTableData([resident])
                                        setFindingResident(false)
                                    }}
                                    style={{
                                        border: 'none',
                                        background: 'none',
                                        cursor: 'pointer',
                                        fontSize: 20,
                                        marginTop: 0,
                                        marginBottom: 15,
                                        width: '5%',
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon="search"
                                        style={{color: ColorEnums.danube}}
                                    />
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className={tableContainer} style={{textAlign: 'justify'}}>
                <table className={parentTable} role="grid">
                    <thead>
                        <tr className={headerRow} style={{marginBottom: 50}}>
                            <th
                                className={sortable}
                                style={{textAlign: 'left'}}
                                onClick={() => sortData('Resident Name', 'LastName')}
                            >
                                Resident Name
                                <FontAwesomeIcon
                                    icon={renderSortIcon('Resident Name')}
                                    style={{marginLeft: 10}}
                                />
                            </th>
                            <th>ABA ID</th>
                            <th
                                className={sortable}
                                onClick={() => sortData('Track', 'TrackDisplayName')}
                            >
                                Track
                                <FontAwesomeIcon
                                    icon={renderSortIcon('Track')}
                                    style={{marginLeft: 10}}
                                />
                            </th>
                            <th
                                className={sortable}
                                style={{paddingTop: 5}}
                                onClick={() => sortData('Current Year', 'ProgramYear')}
                            >
                                <div
                                    style={{
                                        display: 'inline-block',
                                    }}
                                >
                                    Current
                                    <br />
                                    Year
                                </div>
                                <FontAwesomeIcon
                                    icon={renderSortIcon('Current Year')}
                                    style={{
                                        marginLeft: 15,
                                        display: 'inline-block',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </th>
                            <th>Feedback</th>
                            <th>Enrollment Status</th>
                            <th>Training Status</th>
                            <th
                                onClick={() => {
                                    throw new Error('test')
                                }}
                            >
                                PDIR Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>{renderTableData()}</tbody>
                </table>
            </div>
        </div>
    )
}

ResidentListingTableGrid.defaultProps = {
    dataset: [],
    loading: true,
    trainingRecords: [],
    trainingSummary: [],
    residentEnrollmentId: null,
}

ResidentListingTableGrid.propTypes = {
    dataset: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    trainingRecords: PropTypes.arrayOf(PropTypes.object),
    trainingSummary: PropTypes.arrayOf(PropTypes.object),
    residentEnrollmentId: PropTypes.string,
}
export default ResidentListingTableGrid
