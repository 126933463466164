import React from 'react'
import styles from './ABAStatusLegend.module.css'

export default function ABAStatusLegend() {
    const {green, red, yellow, box, label, labelBoxContainer, parent, child} = styles
    return (
        <div className={parent}>
            <div className={child}>
                <div className={labelBoxContainer}>
                    <div className={label}>No Portal Account - *</div>
                </div>

                <div className={labelBoxContainer}>
                    <div className={label} style={{marginRight: 3}}>
                        Not Applicable -
                    </div>
                    <div className={box} />
                </div>

                <div className={labelBoxContainer}>
                    <div className={label} style={{marginRight: 3}}>
                        Draft -
                    </div>
                    <div className={`${box} + '' + ${red}`} />
                </div>

                <div className={labelBoxContainer}>
                    <div className={label} style={{marginRight: 3}}>
                        Validate -
                    </div>
                    <div className={`${box} + '' + ${yellow}`} />
                </div>

                <div className={labelBoxContainer}>
                    <div className={label} style={{marginRight: 3}}>
                        Approved -
                    </div>
                    <div className={`${box} + '' + ${green}`} />
                </div>
            </div>
        </div>
    )
}
