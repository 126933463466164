import * as ActionTypes from '../Actions/ActionTypes'
import {GeneralEnums} from '../../helpers/GeneralEnums'

const defaultState = {
    ResidentListing: [],
    Loading: false,
    ErrorMessage: null,
}

const ResidentListing = (state = defaultState, {type, payload}) => {
    switch (type) {
        case ActionTypes.RESIDENTLISTING_SUCCESS:
            return {
                ...state,
                ResidentListing: payload,
                Loading: false,
                ErrorMessage: null,
            }
        case ActionTypes.RESIDENTLISTING_FAILED:
            return {
                ...defaultState,
                Loading: false,
                ErrorMessage: payload.ErrorMessage,
            }
        case ActionTypes.RESIDENTLISTING_LOAD:
            return {
                ...state,
                Loading: payload,
            }
        case ActionTypes.RESET_REDUX:
            return defaultState
        case ActionTypes.RESIDENTLISTING_UPDATE: {
            const newResListing = state.ResidentListing.map((enrollment) => {
                if (enrollment.ResidentEnrollmentId === payload.ResidentEnrollmentId) {
                    return payload
                }
                return enrollment
            })

            const filteredRes = newResListing.filter((enrollmentRecord) => {
                if (
                    enrollmentRecord.ResidentEnrollmentStatusCode !==
                        GeneralEnums.residentEnrollmentStatusCodes.CancelledABA ||
                    enrollmentRecord.ResidentEnrollmentStatusCode !==
                        GeneralEnums.residentEnrollmentStatusCodes.CancelledProgram
                ) {
                    return true
                }
                return false
            })
            return {
                ResidentListing: filteredRes,
                Loading: false,
                ErrorMessage: null,
            }
        }
        default:
            return state
    }
}
export default ResidentListing
