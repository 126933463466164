import React, {useState, useEffect} from 'react'
import ABAHeader from '../components/ABAComponents/ABAHeader'
import ABAContainer from '../components/ABAComponents/ABAContainer/ABAContainer'
import RTIDAPI from '../../helpers/AxiosInstance'
import {LoadingOverlay, Dropdown, Button, StyleVariant} from 'aba-ui'
import {GeneralEnums} from '../../helpers/GeneralEnums'
import '../styles/table.css'

export default function ExamRegistrationStatus() {
    const {basic, advanced} = GeneralEnums.productIds

    const examOptions = [
        {value: '0', name: 'All'},
        {value: advanced, name: 'ADV'},
        {value: basic, name: 'BASIC'},
    ]
    const statusOptions = [
        {value: '0', name: 'All'},
        {value: '1', name: 'Registered'},
        {value: '2', name: 'Not Registered'},
    ]

    const [loading, setLoading] = useState(true)
    const [examFilter, setExamFilter] = useState('0')
    const [statusFilter, setStatusFilter] = useState('0')
    const [residents, setResidents] = useState([])
    const [residentsInTable, setResidentsInTable] = useState([])

    const getRegisteredResidents = (productId) => {
        const config = {
            params: {
                productId,
            },
        }
        return new Promise((resolve) => {
            RTIDAPI.get(`report/examEligibleResidents`, config)
                .then((response) => resolve(response.data))
                .catch((err) => err)
        })
    }

    useEffect(() => {
        const getData = async () => {
            const registeredForBasic = await getRegisteredResidents(basic)
            const registeredForAdvanced = await getRegisteredResidents(advanced)

            let allResidents = registeredForBasic.concat(registeredForAdvanced)

            setResidents(allResidents)
            setResidentsInTable(allResidents)
            setLoading(false)
        }

        getData()
    }, [])

    const filterTable = () => {
        if (examFilter == 0 && statusFilter == 0) {
            setResidentsInTable(residents)
            return
        }
        let filteredResidents = [...residents]

        filteredResidents = filteredResidents.filter((resident) => {
            if (examFilter == 0) {
                return resident
            }

            return resident.ProductID === examFilter
        })

        filteredResidents = filteredResidents.filter((resident) => {
            if (statusFilter == 0) {
                return resident
            } else if (statusFilter == 1 && resident.RegistrationId) {
                return resident
            } else if (statusFilter == 2 && !resident.RegistrationId) {
                return resident
            }
        })

        setResidentsInTable(filteredResidents)
    }

    const renderResidents = () => {
        if (residentsInTable.length < 1) {
            return (
                <tr>
                    <td style={{textAlign: 'center', paddingLeft: 0}} colSpan={4}>
                        No residents to display
                    </td>
                </tr>
            )
        }

        return residentsInTable.map((resident) => {
            return (
                <tr key={resident.ABAID} style={{textAlign: 'center'}}>
                    <td style={{textAlign: 'left', paddingLeft: 35}}>{resident.Name}</td>
                    <td>{resident.ABAID}</td>
                    <td>{resident.ProductID === basic ? 'BASIC' : 'ADV'}</td>
                    <td>{resident.RegistrationId ? 'Registered' : 'Not Registered'}</td>
                </tr>
            )
        })
    }

    return (
        <div>
            {loading && <LoadingOverlay />}
            <ABAHeader text="Reports" />
            <ABAContainer label="Exam Registration Status">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '700px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        justifyContent: 'space-between',
                    }}
                >
                    <Dropdown
                        required
                        placeholder="Exam"
                        onChange={(value) => {
                            setExamFilter(value)
                        }}
                        hideFirstOption
                        options={examOptions.map((option) => {
                            return {
                                value: option.value,
                                name: option.name,
                            }
                        })}
                        style={{width: '200px'}}
                        errorMessage={'Please select a value'}
                        selectedValue={examFilter}
                    />
                    <Dropdown
                        required
                        placeholder="Status"
                        onChange={(value) => {
                            setStatusFilter(value)
                        }}
                        hideFirstOption
                        options={statusOptions.map((option) => {
                            return {
                                value: option.value,
                                name: option.name,
                            }
                        })}
                        style={{width: '200px'}}
                        errorMessage={'Please select a value'}
                        selectedValue={statusFilter}
                    />
                    <Button
                        variant={StyleVariant.FILLED}
                        style={{width: 160}}
                        onClick={() => filterTable()}
                    >
                        Search
                    </Button>
                </div>
                <table className="table" style={{marginTop: '50px'}}>
                    <thead>
                        <tr className="tableHeader">
                            <th style={{textAlign: 'left', paddingLeft: 35}}>Name</th>
                            <th>ABA ID</th>
                            <th>Exam</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>{renderResidents()}</tbody>
                </table>
            </ABAContainer>
        </div>
    )
}
