import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import RTIDAPI from '../../helpers/AxiosInstance'
import {
    TrainingRecordsSuccess,
    TrainingRecordsFailed,
    LoadTrainingRecords,
} from '../../redux/Actions/TrainingRecordsActionCreators'
import {
    TrainingSummarySuccess,
    TrainingSummaryFailed,
} from '../../redux/Actions/TrainingSummaryActionCreators'
import useUser from './useUser'

const getTrainingRecords = (programId) => async (dispatch) => {
    try {
        dispatch(LoadTrainingRecords())
        const trainingRecords = await RTIDAPI.get(
            `training/trainingRecords/${programId}`,
            {},
        )
        dispatch(TrainingRecordsSuccess(trainingRecords.data))
    } catch (err) {
        dispatch(
            TrainingRecordsFailed('An error occurred while loading Training Records.'),
        )
    }
}
const getTrainingSummary = (programId) => async (dispatch) => {
    try {
        const trainingSummary = await RTIDAPI.get(
            `training/trainingSummary/${programId}`,
            {},
        )

        dispatch(TrainingSummarySuccess(trainingSummary.data))
    } catch (err) {
        dispatch(
            TrainingSummaryFailed(
                'An error occurred while loading Training Summary data.',
            ),
        )
    }
}

const createNewTrainingRecord = async (trainingInfo) => {
    try {
        return await RTIDAPI.post('training/submitTraining', trainingInfo)
    } catch (err) {
        alert(err.response.data.ExceptionMessage)
    }
}

const submitTrainingRotation = async (rotation) => {
    return await RTIDAPI.post('training/submitRotation', rotation)
}

const updateTrainingStatus = async (trainingId, statusCode) => {
    const training = {
        TrainingId: trainingId,
        StatusCode: statusCode,
    }
    return await RTIDAPI.put(`training/trainingStatus`, training)
}

const deleteTrainingRotation = async (rotationId) => {
    return await new Promise((resolve) => {
        RTIDAPI.get(`training/deleteRotation/${rotationId}`, {})
            .then((response) => resolve(response.data))
            .catch((err) => err.response.data.ExceptionMessage)
    })
}

const getTrainingRotations = async (trainingId) => {
    const trainingRotations = await RTIDAPI.get(
        `training/trainingRotations/${trainingId}`,
        {},
    )

    return trainingRotations
}

const checkForExistingRotationRecords = async (trainingId) => {
    const rotationCount = await RTIDAPI.get(`training/rotationCheck/${trainingId}`, {})
    return rotationCount.data
}

const submitAutoCompleteTraining = async (trainingInfo) => {
    try {
        return await RTIDAPI.post(`training/autoCompleteTraining`, trainingInfo)
    } catch (err) {
        alert(err.response.data.ExceptionMessage)
    }
}

const submitClinicalCompetence = async (trainingId) => {
    try {
        return await RTIDAPI.put(`training/clinicalCompetence/${trainingId}`, {})
    } catch (err) {
        alert(err.response.data.ExceptionMessage)
    }
}

const approveTraining = async (trainingId, pdirId) => {
    try {
        const response = await RTIDAPI.put(
            `training/approveTraining/${trainingId}/${pdirId}`,
            {},
        )
        return response.data
    } catch (err) {
        alert(err.response.data.ExceptionMessage)
    }
}

const getTrainingEssentials = async (trainingId) => {
    const trainingEssentials = await RTIDAPI.get(
        `training/trainingEssentials/${trainingId}`,
        {},
    )

    return trainingEssentials
}

const cancelPriorCBTrainingRecord = async (residentEnrollmentId) => {
    try {
        return await RTIDAPI.put(
            `training/cancelPriorCBTraining/${residentEnrollmentId}`,
            {},
        )
    } catch (err) {
        alert(err.response.data.ExceptionMessage)
    }
}

const useTraining = ({loadSummary = false, loadTraining = false} = {}) => {
    const dispatch = useDispatch()
    const {userData} = useUser()
    const TrainingRecordsList = useSelector(({trainingRecords}) => trainingRecords)
    const TrainingSummaryList = useSelector(({trainingSummary}) => trainingSummary)

    useEffect(() => {
        if (loadTraining) {
            dispatch(getTrainingRecords(userData.ProgramId))
        }
        if (loadSummary) {
            dispatch(getTrainingSummary(userData.ProgramId))
        }
    }, [dispatch, userData.ProgramId])

    if (loadSummary || loadTraining) {
        return {TrainingRecordsList, TrainingSummaryList}
    }

    return {
        submitAutoCompleteTraining,
        submitTrainingRotation,
        getTrainingRotations,
        updateTrainingStatus,
        deleteTrainingRotation,
        createNewTrainingRecord,
        submitClinicalCompetence,
        getTrainingEssentials,
        checkForExistingRotationRecords,
        approveTraining,
        cancelPriorCBTrainingRecord,
    }
}

export default useTraining
