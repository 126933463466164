import * as ActionTypes from '../Actions/ActionTypes'

const defaultState = {
    UserName: null,
    AuthenticationToken: null,
    UserRole: null,
    IsLockedOut: null,
    HasBackDoorAccess: null,
    ContactId: null,
    FirstName: null,
    LastName: null,
    ProgramId: null,
    ProgramRoleId: null,
    ProgramRole: null,
    RoleName: null,
    ProgramNumber: null,
    ProgramType: null,
    ProgramName: null,
    AccountId: null,
    IsCanadianProgram: null,
    ProgramPeriodId: null,
    TrainingPeriodId: null,
    Loading: true,
    TrainingPeriodName: null,
    TrainingStartDate: null,
    TrainingEndDate: null,
    ErrorMessage: null,
}
// This is where you manipulate the state. The state is immutable so you need to return a new state vs changing the state directly.
// This is an example
const User = (state = defaultState, {type, payload}) => {
    switch (type) {
        case ActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                ...payload,
                errorMessage: null,
            }
        case ActionTypes.RESET_REDUX:
            return {...defaultState}
        default:
            return state
    }
}
export default User
