import * as ActionTypes from './ActionTypes'

export const ResidentListingSuccess = (residentListingData) => {
    return {
        type: ActionTypes.RESIDENTLISTING_SUCCESS,
        payload: residentListingData,
    }
}

export const ResidentListingFailed = (errorMessage) => {
    return {
        type: ActionTypes.RESIDENTLISTING_FAILED,
        payload: {errorMessage},
    }
}

export const LoadResidentListing = (loading = true) => {
    return {
        type: ActionTypes.RESIDENTLISTING_LOAD,
        payload: loading,
    }
}
export const UpdateResidentListing = (enrollmentRecord) => {
    return {
        type: ActionTypes.RESIDENTLISTING_UPDATE,
        payload: enrollmentRecord,
    }
}
