import RTIDAPI from '../../../helpers/AxiosInstance'
import {
    ResidentListingSuccess,
    ResidentListingFailed,
} from '../../../redux/Actions/ResidentListingActionCreators'

const getResidentListingData = (programId) => async (dispatch) => {
    try {
        const residentListingData = await RTIDAPI.get(
            `residentListing/residentEnrollmentForms/${programId}`,
            {},
        )
        dispatch(ResidentListingSuccess(residentListingData.data))
    } catch (err) {
        dispatch(
            ResidentListingFailed(
                'An error occurred while loading Resident Listing Data.',
            ),
        )
    }
}
export default getResidentListingData
