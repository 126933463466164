import * as ActionTypes from './ActionTypes'
import RTIDAPI from '../../helpers/AxiosInstance'

export const LoginBegin = () => {
    return {
        type: ActionTypes.LOGIN_BEGIN,
    }
}

export const LoginSuccess = (userData) => {
    return {
        type: ActionTypes.LOGIN_SUCCESS,
        payload: userData,
    }
}

export const LoginFailed = (errorMessage) => {
    return {
        type: ActionTypes.LOGIN_FAILED,
        payload: errorMessage,
    }
}

export const ResetRedux = () => {
    return {
        type: ActionTypes.RESET_REDUX,
    }
}

export const getUserInfo = () => async (dispatch) => {
    try {
        const userInfo = await RTIDAPI.get(`authentication/userInfo`)
        dispatch(LoginSuccess(userInfo.data))
        return userInfo.data
    } catch (error) {
        switch (error.response.status) {
            case (400, 401):
                dispatch(ResetRedux())
                return error
            case 500:
                return error
            default:
                dispatch(ResetRedux())
                return error
        }
    }
}
