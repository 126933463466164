import React, {useState, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import PropTypes from 'prop-types'
import ValidationHelpers from '../../helpers/ValidationHelpers'
import {GeneralEnums} from '../../helpers/GeneralEnums'
import ABAHeader from '../../presentation/components/ABAComponents/ABAHeader'
import ABARequiredField from '../../presentation/components/ABAComponents/ABARequiredField'
import {LoadingOverlay, Button, StyleVariant} from 'aba-ui'
import RTIDAPI from '../../helpers/AxiosInstance'
import TrainingRecord from '../components/Training/TrainingRecord'
import TrainingRotation from '../components/Training/TrainingRotation'
import CombinedSurvey from '../components/Training/CombinedSurvey'
import HelperMethods from '../../helpers/HelperMethods'
import useTraining from '../../libs/hooks/useTraining'
import useUser from '../../libs/hooks/useUser'
import usePDIR from '../../libs/hooks/usePDIR'
import {
    UpdateTrainingRecord,
    LoadTrainingRecords,
} from '../../redux/Actions/TrainingRecordsActionCreators'
import {UpdateTrainingSummary} from '../../redux/Actions/TrainingSummaryActionCreators'
export default function TrainingRecords(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const {createNewTrainingRecord, updateTrainingStatus} = useTraining()
    const {checkForExistingPDIR, createPDIR, approvePDIR, validatePDIR} = usePDIR()
    const {userData} = useUser()

    const [loading, setLoading] = useState(true)

    // state vars for TrainingRecords (parent container)
    const [step, setStep] = useState('training')

    // state vars for TrainingRecord (child container)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [trainingPeriodStartDate, setTrainingPeriodStartDate] = useState('')
    const [trainingPeriodEndDate, setTrainingPeriodEndDate] = useState('')
    const [startDateErr, setStartDateErr] = useState('')
    const [endDateErr, setEndDateErr] = useState('')
    const [willContinueTraining, setWillContinueTraining] = useState('')
    const [priorTraining, setPriorTraining] = useState('0')
    const [priorCBProgramName, setPriorCBProgramName] = useState('')
    const [priorCBProgramCity, setPriorCBProgramCity] = useState('')
    const [priorCBProgramState, setPriorCBProgramState] = useState('')
    const [priorCBProgramDirector, setPriorCBProgramDirector] = useState('')
    const [priorCBProgramType, setPriorCBProgramType] = useState('')
    const [pdirId, setPDIRId] = useState(null)
    const [pdirTrainingPeriodId, setPDIRTrainingPeriodId] = useState(null)
    const [trainingInputStatus, setTrainingInputStatus] = useState({
        startDate: GeneralEnums.textInputStatus.default,
        endDate: GeneralEnums.textInputStatus.default,
        willContinueTraining: GeneralEnums.textInputStatus.default,
        priorTraining: GeneralEnums.textInputStatus.default,
        priorCBProgramName: GeneralEnums.textInputStatus.default,
        priorCBProgramCity: GeneralEnums.textInputStatus.default,
        priorCBProgramState: GeneralEnums.textInputStatus.default,
        priorCBProgramDirector: GeneralEnums.textInputStatus.default,
        priorCBProgramType: GeneralEnums.textInputStatus.default,
    })
    const [trainingRecord, setTrainingRecord] = useState(null)
    const [continuePressed, setContinuePressed] = useState(null)
    const [blockSubmit, setBlockSubmit] = useState(false)
    const residentEnrollmentId = location.state?.residentEnrollmentId
    const trainingId = location.state?.trainingId
    const rotationCount = location.state?.rotationCount
    const residentData = useSelector(({residentListing}) => residentListing)
    const resident = residentData.ResidentListing.find(
        (data) => data.ResidentEnrollmentId === residentEnrollmentId,
    )
    const trainingProps = {
        resident: resident ? resident : {},
        trainingId,
        pdirId,
        trainingRecord,
        userData,
        pdirTrainingPeriodId,
    }

    const trainingData = useSelector(({trainingRecords}) => trainingRecords.TrainingRecords)

    const existingTrainingRecord = useSelector(({trainingRecords}) =>
        trainingRecords.TrainingRecords.find(
            (trainingRecord) => trainingRecord.TrainingId === trainingId,
        ),
    )

    const currentTrainingPeriodRecords = trainingData.filter(
        (data) =>
            data.TrainingPeriodId === userData.TrainingPeriodId &&
            data.ResidentEnrollmentId === residentEnrollmentId,
    )

    const handleMessage = (messageEvent) => {
        const {data} = messageEvent
        switch (data) {
            case 'basic':
                setBlockSubmit(true)
                break
        }
    }
    useEffect(() => {
        if (userData) {
            getTrainingPeriodDetails().then((data) => {
                if (existingTrainingRecord) {
                    const {
                        StartDate,
                        EndDate,
                        WillContinue,
                        PriorTraining,
                        PriorCBTrainingProgramCity,
                        PriorCBTrainingProgramDirector,
                        PriorCBTrainingProgramName,
                        PriorCBTrainingProgramState,
                        PriorCBTrainingProgramType,
                        TrainingPeriodStartDate,
                        TrainingPeriodEndDate,
                    } = existingTrainingRecord
                    setTrainingPeriodStartDate(TrainingPeriodStartDate)
                    setTrainingPeriodEndDate(TrainingPeriodEndDate)
                    setStartDate(HelperMethods.formatDate(StartDate))

                    WillContinue && setWillContinueTraining(WillContinue.toString())
                    setPriorTraining(PriorTraining.toString())
                    setEndDateValidation(EndDate, data.StartDate)
                    if (PriorTraining == 1) {
                        setPriorCBProgramCity(PriorCBTrainingProgramCity)
                        setPriorCBProgramDirector(PriorCBTrainingProgramDirector)
                        setPriorCBProgramName(PriorCBTrainingProgramName)
                        setPriorCBProgramState(PriorCBTrainingProgramState)
                        setPriorCBProgramType(PriorCBTrainingProgramType)
                    }
                } else {
                    setTrainingPeriodStartDate(data.StartDate)
                    setTrainingPeriodEndDate(data.EndDate)
                }
                checkForExistingPDIR(residentEnrollmentId).then((result) => {
                    if (result !== null) {
                        setPDIRId(result.PDIRId)
                        setPDIRTrainingPeriodId(result.TrainingPeriodId)
                    }
                })
                setLoading(false)
            })
        }
        window.addEventListener('message', handleMessage)
        return () => {
            window.removeEventListener('message', handleMessage)
        }
    }, [])

    const setEndDateValidation = (EndDate, startDate) => {
        if (resident.GraduationDate < EndDate && resident.GraduationDate > startDate) {
            setEndDate(HelperMethods.formatDate(resident.GraduationDate))
        } else {
            setEndDate(HelperMethods.formatDate(EndDate))
        }
    }

    const getTrainingPeriodDetails = () => {
        return new Promise((resolve) => {
            RTIDAPI.get(`training/getActiveTrainingPeriodDetails`, {})
                .then((response) => resolve(response.data))
                .catch((err) => err)
        })
    }
    const onContinueHandler = async () => {
        setLoading(true)
        let theTrainingId = null
        if (trainingRecord?.TrainingId) {
            theTrainingId = trainingRecord.TrainingId
        } else if (trainingId) {
            theTrainingId = trainingId
        }
        if (step === 'training') {
            if (validateTrainingRecord()) {
                let trainingPeriod = null
                let programPeriod = null

                if (existingTrainingRecord) {
                    trainingPeriod = existingTrainingRecord.TrainingPeriodId
                    programPeriod = existingTrainingRecord.ProgramPeriodId
                } else {
                    trainingPeriod = userData.TrainingPeriodId
                    programPeriod = userData.ProgramPeriodId
                }

                const newTrainingRecord = {
                    TrainingId: theTrainingId ? theTrainingId : null,
                    ProgramId: userData.ProgramId,
                    ProgramPeriodId: programPeriod,
                    TrainingPeriodId: trainingPeriod,
                    ResidentEnrollmentId: residentEnrollmentId,
                    ProgramType: userData.ProgramType,
                    TrackType: resident?.TrackType,
                    StartDate: startDate,
                    EndDate: endDate,
                    WillContinue: willContinueTraining,
                    ProgramRole: userData.ProgramRole,
                    ProgramRoleId: userData.ProgramRoleId,
                    PriorTraining: priorTraining,
                    PriorCBTrainingProgramName: priorCBProgramName,
                    PriorCBTrainingProgramCity: priorCBProgramCity,
                    PriorCBTrainingProgramState: priorCBProgramState,
                    PriorCBTrainingProgramDirector: priorCBProgramDirector,
                    PriorCBTrainingProgramType: priorCBProgramType,
                }

                const trainingInfo = await createNewTrainingRecord(newTrainingRecord)
                if (priorTraining === '1') {
                    navigate('/residentListing', {
                        state: {
                            residentEnrollmentId,
                        },
                    })
                } else {
                    setTrainingRecord(trainingInfo.data)
                    setStep('rotation')
                    setLoading(false)
                }
            } else {
                setLoading(false)
            }
        } else if (step === 'rotation') {
            try {
                const {
                    data: {summary, trainingRecord},
                } = await updateTrainingStatus(theTrainingId, GeneralEnums.trainingStatusCodes.Validated)
                dispatch(LoadTrainingRecords(false))
                dispatch(UpdateTrainingRecord(trainingRecord))
                dispatch(UpdateTrainingSummary(summary))
                let newPDIR = null
                if (willContinueTraining === '2' && !pdirId) {
                    setLoading(true)
                    const pdirInfo = {
                        PDIRId: pdirId,
                        ProgramId: trainingRecord.ProgramId,
                        ResidentEnrollmentId: residentEnrollmentId,
                        ContactId: trainingRecord.ContactId,
                        ResidentName: resident.ResidentDisplayName,
                        ProgramType: trainingRecord.ProgramType,
                        TrackType: trainingRecord?.TrackType,
                        TrainingPeriodId: trainingRecord.TrainingPeriodId,
                        ProgramRoleId: userData.ProgramRoleId,
                        ProgramPeriod: trainingRecord.ProgramPeriodId,
                        DateStarted: resident.TrainingStartDate,
                        DateCompleted: trainingRecord.TrainingPeriodEndDate,
                    }
                    newPDIR = await createPDIR(pdirInfo)
                    setPDIRId(newPDIR)
                }
                setStep('survey')
            } catch (err) {
                alert(err.response.data.ExceptionMessage)
            } finally {
                setLoading(false)
            }
        } else if (step === 'survey') {
            setContinuePressed(new Date())
            setLoading(false)
        } else {
            setStep('training')
            setLoading(false)
        }
    }
    const onPreviousHandler = () => {
        if (step === 'rotation') setStep('training')
        else if (step === 'survey') setStep('rotation')
        else setStep('training')
    }

    const validateTrainingRecord = () => {
        let status = {...trainingInputStatus}
        const statusErr = GeneralEnums.textInputStatus.error
        Object.keys(status).forEach((value) => (status[value] = GeneralEnums.textInputStatus.default))
        const myStartDate = startDate
        const dateStartparts = myStartDate.split('/')
        const myDateStartParsed = new Date(dateStartparts[2], dateStartparts[0] - 1, dateStartparts[1])
        myDateStartParsed.setHours(0, 0, 0, 0)

        const myEndDate = endDate
        const dateEndparts = myEndDate.split('/')
        const myEndDateParsed = new Date(dateEndparts[2], dateEndparts[0] - 1, dateEndparts[1])
        myEndDateParsed.setHours(0, 0, 0, 0)

        const traPeriodStartDate = trainingPeriodStartDate
        const traPeriodStartDateParsed = new Date(traPeriodStartDate)
        traPeriodStartDateParsed.setHours(0, 0, 0, 0)

        const traPeriodEndDate = trainingPeriodEndDate
        const traPeriodEndDateParsed = new Date(traPeriodEndDate)
        traPeriodEndDateParsed.setHours(0, 0, 0, 0)

        if (priorTraining != '0') {
            if (myDateStartParsed.getTime() >= traPeriodStartDateParsed.getTime()) {
                status.startDate = statusErr
                setStartDateErr('Should not align with the current period')
            }
            if (myEndDateParsed.getTime() >= traPeriodEndDateParsed.getTime()) {
                status.endDate = statusErr
                setEndDateErr('Should not align with the current period')
            }
            if (myEndDateParsed.getTime() <= myDateStartParsed.getTime()) {
                status.endDate = statusErr
                setEndDateErr('End date should be after the start date')
            }
        }

        if (priorTraining === '0') {
            if (!startDate || !ValidationHelpers.validateDate(startDate)) {
                status.startDate = statusErr
                setStartDateErr('Please enter a valid date')
            } else if (
                // if prior training is 'Current Period', then date must be the same
                // as training period start date or start date on REF
                priorTraining === '0' &&
                !HelperMethods.datesAreOnSameDay(
                    new Date(startDate),
                    new Date(resident.TrainingStartDate),
                ) &&
                !HelperMethods.datesAreOnSameDay(new Date(startDate), new Date(trainingPeriodStartDate))
            ) {
                status.startDate = statusErr
                setStartDateErr('Training Dates should align with the current Training Period')
            }

            if (!endDate || !ValidationHelpers.validateDate(endDate)) {
                status.endDate = statusErr
                setEndDateErr('Please enter a valid date')
            } else if (
                priorTraining === '0' &&
                willContinueTraining != GeneralEnums.willContinueTrainingOptions.noGraduation &&
                willContinueTraining != GeneralEnums.willContinueTrainingOptions.leftProgram &&
                !HelperMethods.datesAreOnSameDay(new Date(endDate), new Date(trainingPeriodEndDate))
            ) {
                status.endDate = statusErr
                setEndDateErr('Training Dates should align with the current Training Period')
            }

            if (willContinueTraining == GeneralEnums.willContinueTrainingOptions.leftProgram) {
                if (
                    traPeriodStartDateParsed >= myEndDateParsed ||
                    myEndDateParsed > traPeriodEndDateParsed
                ) {
                    status.endDate = statusErr
                    setEndDateErr('Training Dates should align with the current Training Period')
                }
            }

            if (willContinueTraining == GeneralEnums.willContinueTrainingOptions.noGraduation) {
                if (
                    trainingPeriodEndDate < resident.GraduationDate &&
                    endDate &&
                    !HelperMethods.datesAreOnSameDay(new Date(endDate), new Date(trainingPeriodEndDate))
                ) {
                    status.endDate = statusErr
                    setEndDateErr('Training Dates should align with the current Training Period')
                } else if (
                    trainingPeriodEndDate > resident.GraduationDate &&
                    endDate &&
                    !HelperMethods.datesAreOnSameDay(
                        new Date(endDate),
                        new Date(resident.GraduationDate),
                    )
                ) {
                    status.endDate = statusErr
                    setEndDateErr('Training Dates should align with resident Graduation date')
                }
            } else {
                if (
                    trainingPeriodStartDate < resident.TrainingStartDate &&
                    startDate &&
                    !HelperMethods.datesAreOnSameDay(
                        new Date(startDate),
                        new Date(resident.TrainingStartDate),
                    )
                ) {
                    status.startDate = statusErr
                    setStartDateErr('Training Dates should align with the resident start Training date')
                } else if (
                    trainingPeriodStartDate > resident.TrainingStartDate &&
                    startDate &&
                    !HelperMethods.datesAreOnSameDay(
                        new Date(startDate),
                        new Date(trainingPeriodStartDate),
                    )
                ) {
                    status.startDate = statusErr
                    setStartDateErr('Training Dates should align with the current Training Period')
                }
            }
        }
        if (!willContinueTraining) {
            status.willContinueTraining = statusErr
        }

        if (!priorTraining) {
            status.priorTraining = statusErr
        } else if (priorTraining === '1') {
            !ValidationHelpers.validateString(priorCBProgramName) &&
                (status.priorCBProgramName = statusErr)
            !ValidationHelpers.validateString(priorCBProgramCity) &&
                (status.priorCBProgramCity = statusErr)
            !ValidationHelpers.validateString(priorCBProgramState) &&
                (status.priorCBProgramState = statusErr)
            !ValidationHelpers.validateString(priorCBProgramDirector) &&
                (status.priorCBProgramDirector = statusErr)
            !priorCBProgramType && (status.priorCBProgramType = statusErr)
        }

        setTrainingInputStatus(status)
        if (Object.values(status).includes(statusErr)) {
            return false
        }

        // If we're not editing a record (existingTrainingRecord), then we want to make sure
        // the user isn't creating a new training record for this training period if one already exists
        if (
            priorTraining === '0' &&
            !existingTrainingRecord &&
            currentTrainingPeriodRecords &&
            currentTrainingPeriodRecords.some(
                (tpRecord) => tpRecord.PriorTraining.toString() === priorTraining,
            )
        ) {
            alert('This resident already has an existing training record for this period')
            return false
        }
        return true
    }

    return (
        <div style={{backgroundColor: 'white', paddingBottom: 25}}>
            {loading && <LoadingOverlay />}
            <ABAHeader text="Training"></ABAHeader>
            <div style={{marginLeft: 'auto', marginRight: 'auto'}}>
                {step === 'training' && (
                    <TrainingRecord
                        {...trainingProps}
                        inputStatus={trainingInputStatus}
                        startDate={startDate}
                        endDate={endDate}
                        willContinueTraining={willContinueTraining}
                        priorTraining={priorTraining}
                        priorCBProgramCity={priorCBProgramCity}
                        priorCBProgramDirector={priorCBProgramDirector}
                        priorCBProgramName={priorCBProgramName}
                        priorCBProgramState={priorCBProgramState}
                        priorCBProgramType={priorCBProgramType}
                        setPriorCBProgramCity={setPriorCBProgramCity}
                        setPriorCBProgramDirector={setPriorCBProgramDirector}
                        setPriorCBProgramName={setPriorCBProgramName}
                        setPriorCBProgramState={setPriorCBProgramState}
                        setPriorCBProgramType={setPriorCBProgramType}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        setWillContinueTraining={setWillContinueTraining}
                        setPriorTraining={setPriorTraining}
                        startDateErr={startDateErr}
                        endDateErr={endDateErr}
                        residentEnrollmentId={residentEnrollmentId}
                        userData={userData}
                        validateTrainingRecord={validateTrainingRecord}
                        trainingId={trainingProps.trainingId}
                    />
                )}

                {step === 'rotation' && (
                    <TrainingRotation
                        {...trainingProps}
                        priorTraining={priorTraining}
                        trainingId={trainingRecord.TrainingId}
                    />
                )}
                {step === 'survey' && (
                    <CombinedSurvey
                        {...trainingProps}
                        trainingId={trainingRecord.TrainingId ? trainingRecord.TrainingId : trainingId}
                        continuePressed={continuePressed}
                    />
                )}

                <div
                    style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        textAlign: 'center',
                        maxWidth: 1000,
                    }}
                >
                    {!blockSubmit && (
                        <div
                            style={{
                                textAlign: 'left',
                                maxWidth: 400,
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        >
                            <ABARequiredField text="Indicates Required Field" />
                        </div>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignContent: 'center',
                            maxWidth: 400,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        {blockSubmit ? (
                            <Button
                                style={{width: 125, margin: 'auto', marginTop: 25}}
                                onClick={() => {
                                    navigate(-1)
                                }}
                                variant={StyleVariant.FILLED}
                            >
                                Return
                            </Button>
                        ) : (
                            <>
                                <Button
                                    style={{width: 125}}
                                    onClick={() => {
                                        onPreviousHandler()
                                        {
                                            step === 'training' &&
                                                navigate('/residentListing', {
                                                    state: {residentEnrollmentId},
                                                })
                                        }
                                    }}
                                    variant={StyleVariant.FILLED}
                                >
                                    {step === 'training' ? 'Cancel' : 'Previous'}
                                </Button>
                                <Button
                                    style={{width: 125}}
                                    onClick={onContinueHandler}
                                    variant={StyleVariant.FILLED}
                                >
                                    {step === 'survey' ? 'Submit' : 'Continue'}
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

TrainingRecords.default = {
    residentEnrollmentId: null,
}

TrainingRecords.propTypes = {
    residentEnrollmentId: PropTypes.string,
}
