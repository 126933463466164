import React from 'react'
import {ColorEnums} from '../../../helpers/GeneralEnums'
import {Button, Modal} from 'aba-ui'
const ApproveModal = ({onApprove, onCancel, title, lineItems}) => {
    return (
        <Modal>
            <div
                style={{
                    backgroundColor: 'white',
                    width: '550px',
                    padding: '15px',
                    border: `1px solid ${ColorEnums.cloudBurst}`,
                    borderRadius: '10px',
                }}
            >
                <h4 style={{textAlign: 'center'}}>{title}</h4>
                <table className="table" style={{textAlign: 'center', paddingBottom: 20}}>
                    <thead>
                        <tr className="headerRow" style={{width: '100%'}}>
                            <td>Item</td>
                            <td>Quantity</td>
                            <td>Total</td>
                        </tr>
                    </thead>
                    <tbody>
                        {lineItems.map((lineItem) => {
                            return (
                                <tr key={lineItem.ProductName}>
                                    <td>{lineItem.ProductName}</td>
                                    <td>{lineItem.Count}</td>
                                    <td>
                                        {lineItem.TotalAmount.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 0,
                                        })}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <p style={{paddingBottom: 20}}>
                    Once the Program Director has approved the invoice, the roster
                    quantity and total amount will be locked. If you need to add more
                    Residents, you may create additional rosters and invoices at a later
                    time.
                </p>
                <p>
                    By clicking Approve you are attesting to the accuracy of the
                    information provided on this roster.
                </p>
                <div style={{width: '100%', flexDirection: 'row', display: 'flex'}}>
                    <div style={{flex: 1}}>
                        <div
                            style={{
                                width: 170,
                                margin: 'auto',
                            }}
                        >
                            <Button variant="outlined" onClick={onCancel}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                    <div style={{flex: 1}}>
                        <div
                            style={{
                                width: 170,
                                margin: 'auto',
                            }}
                        >
                            <Button variant="filled" onClick={onApprove}>
                                Approve
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ApproveModal
