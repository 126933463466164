import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import ABAContainer from '../components/ABAComponents/ABAContainer/ABAContainer'
import ABAHeader from '../components/ABAComponents/ABAHeader'
import '../styles/table.css'
import {LoadingOverlay} from 'aba-ui'
import {ColorEnums} from '../../helpers/GeneralEnums'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import useUser from '../../libs/hooks/useUser'
import RTIDAPI from '../../helpers/AxiosInstance'

export default function Invoices() {
    const {userData} = useUser()
    const navigate = useNavigate()

    const [invoices, setInvoices] = useState([])
    const [loading, setLoading] = useState(null)

    const getInvoices = async () => {
        try {
            setLoading('Getting invoices')
            const {data} = await RTIDAPI.get(`invoice/invoices/${userData.ProgramId}`)
            setInvoices(data)
            setLoading(null)
        } catch (error) {
            setLoading(null)
            alert(error)
        }
    }

    const renderInvoices = () => {
        if (invoices?.length > 0) {
            return invoices.map((invoice) => {
                return (
                    <tr
                        className="tableRow"
                        key={invoice.InvoiceId}
                        style={{textAlign: 'left'}}
                        onClick={() => {
                            navigate('/invoiceDetails',{
                                state: {invoiceId: invoice.InvoiceId},
                            })
                        }}
                    >
                        <td>{invoice.DatePaid}</td>
                        <td>{invoice.Description}</td>
                        <td>{`$${invoice.TotalAmount.toFixed(2)}`}</td>
                        <td>
                            <FontAwesomeIcon
                                icon="angle-right"
                                style={{color: ColorEnums.danube}}
                            />
                        </td>
                    </tr>
                )
            })
        }
        return (
            <tr>
                <td style={{textAlign: 'center', paddingLeft: 0}} colSpan={4}>
                    No Invoices for this account
                </td>
            </tr>
        )
    }

    useEffect(() => {
        getInvoices()
    }, [])

    return (
        <div>
            {loading && <LoadingOverlay title={loading} />}
            <ABAHeader text="Account" />
            <ABAContainer label="Invoices">
                <table className="table" style={{width: '800px', paddingBottom: '50px'}}>
                    <thead>
                        <tr className="tableHeader" style={{textAlign: 'left'}}>
                            <th style={{width: '25%'}}>Date</th>
                            <th style={{width: '50%'}}>Description</th>
                            <th style={{width: '15%'}}>Amount</th>
                            <th style={{width: '10%'}} />
                        </tr>
                    </thead>
                    <tbody>{renderInvoices()}</tbody>
                </table>
            </ABAContainer>
        </div>
    )
}
