import React, {useEffect, useState} from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableSortLabel from '@mui/material/TableSortLabel'
import {useNavigate} from 'react-router-dom'
import {Button, Container, Dropdown, StyleVariant} from 'aba-ui'
import {ColorEnums} from '../../../helpers/GeneralEnums'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import useUser from '../../../libs/hooks/useUser'
import styles from './AddRemoveTable.module.css'

const {submitDiv, directorMessage} = styles

const headerCellStyle = {
    borderColor: '#418FDE',
    fontSize: 14,
    color: ColorEnums.blueGray,
    fontFamily: 'lato',
}

const bodyCellStyle = {
    color: ColorEnums.cloudBurst,
    fontFamily: 'lato',
}

const DownSort = () => {
    return <FontAwesomeIcon style={{marginLeft: 10}} icon="sort-down" />
}
const UpSort = () => {
    return <FontAwesomeIcon style={{marginLeft: 10}} icon="sort-up" />
}
const InactiveSort = () => {
    return <FontAwesomeIcon style={{marginLeft: 10}} icon="sort" />
}

const AddRemoveTable = ({
    bottomTableLabel,
    bottomColWidths,
    colWidths,
    headers = [],
    hideBottomTable = false,
    hideBottomColumns = [], // hideBottomColumns should contain index of headers and data that we want to hide
    hideRemove = false,
    initialTopData = [],
    initialBottomData = [],
    onSubmit,
    onAdd,
    onEdit,
    onDelete,
    onTopButtonClick,
    showEdit = false,
    showTopButton = false,
    sortColumn = 0,
    sortableColumns = [],
    submitLabel = 'Submit',
    topButtonLabel,
    topTableLabel,
    topTableLink = [],
    topTableLinkFunc,
    directorOnly = false,
    filterIndex = null,
}) => {
    const navigate = useNavigate()
    const {
        userData: {UserRole},
    } = useUser()
    const [topData, setTopData] = useState([])
    const [bottomData, setBottomData] = useState([])
    const [checkedItems, setCheckedItems] = useState([])
    const [selectedSortColumn, setSelectedSortColumn] = useState(sortColumn)
    const [sortAscending, setSortAscending] = useState(true)
    const [filterOptions, setFilterOptions] = useState([])
    const [filterValue, setFilterValue] = useState(null)
    const [hasData, setHasData] = useState({top: false, bottom: false})

    useEffect(() => {
        setTopData(initialTopData)
        setBottomData(initialBottomData)
        setCheckedItems(Array(initialBottomData?.length || 0).fill(false))
        if (filterIndex !== null && initialTopData && initialBottomData) {
            const allItems = [...initialTopData, ...initialBottomData]
            const filterValues = allItems.map((item) => item[filterIndex])
            setFilterOptions(
                [...new Set(filterValues)].map((item) => {
                    return {name: item, value: item}
                }),
            )
            setFilterValue(null)
        }
    }, [initialTopData, initialBottomData])

    useEffect(() => {
        const newTopData = [...topData].sort(sortData)
        const newBottomData = [...bottomData].sort(sortData)
        setTopData(newTopData)
        setBottomData(newBottomData)
        setCheckedItems(Array(bottomData?.length || 0).fill(false))
    }, [sortAscending, selectedSortColumn])

    useEffect(() => {
        const filterIsInactive =
            filterIndex === null || filterValue === null || filterValue === ''
        const topHasData =
            topData?.length &&
            (filterIsInactive ||
                topData.filter((row) => row[filterIndex] === filterValue).length)
        const bottomHasData =
            bottomData?.length &&
            (filterIsInactive ||
                bottomData.filter((row) => row[filterIndex] === filterValue).length)
        setHasData({top: topHasData, bottom: bottomHasData})
    }, [topData, bottomData, filterValue, filterIndex])

    const sortData = (data1, data2) => {
        if (data1[selectedSortColumn] < data2[selectedSortColumn]) {
            return sortAscending ? -1 : 1
        }
        if (data1[selectedSortColumn] > data2[selectedSortColumn]) {
            return sortAscending ? 1 : -1
        }
        return 0
    }

    const addSelected = () => {
        const addedRows = bottomData.filter((row, i) => checkedItems[i])
        if (onAdd) {
            onAdd(addedRows)
        }
        const newTopData = [...topData, ...addedRows].sort(sortData)
        const newBottomData = bottomData
            .filter((row, i) => !checkedItems[i])
            .sort(sortData)
        setTopData(newTopData)
        setBottomData(newBottomData)
        setCheckedItems(Array(newBottomData?.length || 0).fill(false))
    }

    const removeItem = (itemIndex) => {
        if (onDelete) {
            onDelete(topData[itemIndex])
        }
        const newBottomData = [...bottomData, topData[itemIndex]].sort(sortData)
        const newTopData = [...topData]
        newTopData.splice(itemIndex, 1)
        setTopData(newTopData.sort(sortData))
        setBottomData(newBottomData)
        setCheckedItems(Array(newBottomData?.length || 0).fill(false))
    }

    const renderBottomColumnGrid = () => {
        bottomColWidths &&
            bottomColWidths.map((width, i) => {
                return <col width={width} key={i} />
            })

        colWidths ? (
            colWidths.map((width, i) => {
                return <col width={width} key={i} />
            })
        ) : (
            <col span={headers.length} />
        )
    }

    const setSort = (column) => {
        if (column === selectedSortColumn) {
            setSortAscending(!sortAscending)
        } else {
            setSelectedSortColumn(column)
            setSortAscending(true)
        }
    }

    const renderSortIcon = (column) => {
        return column === selectedSortColumn
            ? sortAscending
                ? UpSort
                : DownSort
            : InactiveSort
    }

    const submitDisabled =
        directorOnly && UserRole !== 'ProgramDirector' && UserRole !== 'SuperUser'

    return (
        <>
            <Container title={topTableLabel} headerJustify="center">
                {showTopButton && (
                    <div
                        className="proctorButton"
                        style={{float: 'right', marginRight: '30px'}}
                        onClick={onTopButtonClick}
                    >
                        {topButtonLabel}
                    </div>
                )}
                <div style={{padding: '30px 30px'}}>
                    {filterIndex !== null && (
                        <div style={{width: 200, float: 'right'}}>
                            <Dropdown
                                placeholder={headers[filterIndex]}
                                onChange={(value) => {
                                    setFilterValue(value)
                                }}
                                options={filterOptions}
                                selectedValue={filterValue}
                            />
                        </div>
                    )}
                    <Table sx={{margin: 'auto'}} style={{tableLayout: 'fixed'}}>
                        <colgroup>
                            {colWidths ? (
                                colWidths.map((width, i) => {
                                    return <col width={width} key={i} />
                                })
                            ) : (
                                <col span={headers.length} />
                            )}
                            <col style={{width: '100px'}} />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                {headers.map((header, i) => (
                                    <TableCell style={headerCellStyle} key={header}>
                                        {sortableColumns.includes(i) ? (
                                            <TableSortLabel
                                                active={selectedSortColumn === i}
                                                direction={
                                                    selectedSortColumn === i
                                                        ? sortAscending
                                                            ? 'asc'
                                                            : 'desc'
                                                        : 'asc'
                                                }
                                                onClick={() => setSort(i)}
                                                IconComponent={renderSortIcon(i)}
                                            >
                                                {header}
                                            </TableSortLabel>
                                        ) : (
                                            header
                                        )}
                                    </TableCell>
                                ))}
                                <TableCell style={headerCellStyle} />
                            </TableRow>
                        </TableHead>
                        {hasData.top ? (
                            <TableBody>
                                {topData.map((rowData, rowIndex) => {
                                    if (
                                        filterIndex !== null &&
                                        filterValue &&
                                        rowData[filterIndex] !== filterValue
                                    ) {
                                        return
                                    }
                                    return (
                                        <TableRow key={JSON.stringify(rowData)}>
                                            {rowData.map((cellData, i) => {
                                                if (
                                                    topTableLink.length > 0 &&
                                                    topTableLink.includes(i)
                                                ) {
                                                    return (
                                                        <TableCell
                                                            key={cellData}
                                                            style={{
                                                                cursor: 'pointer',
                                                                color: ColorEnums.danube,
                                                                fontFamily: 'lato',
                                                            }}
                                                            onClick={() => {
                                                                topTableLinkFunc(rowIndex)
                                                            }}
                                                        >
                                                            {cellData}
                                                        </TableCell>
                                                    )
                                                }
                                                return (
                                                    <TableCell
                                                        key={cellData}
                                                        style={bodyCellStyle}
                                                    >
                                                        {cellData}
                                                    </TableCell>
                                                )
                                            })}
                                            {hideRemove ? (
                                                <TableCell />
                                            ) : (
                                                <TableCell
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: ColorEnums.danube,
                                                        fontFamily: 'lato',
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {showEdit && (
                                                        <span
                                                            style={{marginRight: '20px'}}
                                                            onClick={() => {
                                                                onEdit(rowIndex)
                                                            }}
                                                        >
                                                            Edit
                                                        </span>
                                                    )}

                                                    <span
                                                        onClick={() => {
                                                            removeItem(rowIndex)
                                                        }}
                                                    >
                                                        Remove
                                                    </span>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        colSpan={headers.length + 1}
                                        style={{textAlign: 'center', ...bodyCellStyle}}
                                    >
                                        No records to display
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{width: '250px', padding: '20px 20px'}}>
                        <Button
                            variant={StyleVariant.OUTLINED}
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            <p style={{fontFamily: 'lato'}}>Go Back</p>
                        </Button>
                    </div>
                    <div className={submitDiv}>
                        {submitDisabled && (
                            <div className={directorMessage}>
                                Program Director required
                            </div>
                        )}
                        <Button
                            variant={StyleVariant.FILLED}
                            onClick={() => {
                                if (filterIndex === null || !filterValue) {
                                    onSubmit(topData)
                                } else {
                                    onSubmit(
                                        topData.filter(
                                            (row) => row[filterIndex] === filterValue,
                                        ),
                                    )
                                }
                            }}
                            disabled={submitDisabled}
                        >
                            <p style={{fontFamily: 'lato'}}>{submitLabel}</p>
                        </Button>
                    </div>
                </div>
            </Container>
            {!hideBottomTable && (
                <Container title={bottomTableLabel} headerJustify="center">
                    <div style={{padding: '30px 30px'}}>
                        <Table sx={{margin: 'auto'}} style={{tableLayout: 'fixed'}}>
                            <colgroup>
                                <col style={{width: '100px'}} />
                                {renderBottomColumnGrid()}
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            ...headerCellStyle,
                                            textAlign: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        Select All
                                        <br />
                                        <input
                                            type="checkbox"
                                            style={{margin: 'auto'}}
                                            checked={
                                                !checkedItems.some(
                                                    (checked) => checked === false,
                                                )
                                            }
                                            onChange={() => {
                                                const checked = checkedItems.some(
                                                    (checked) => checked === false,
                                                )
                                                setCheckedItems(
                                                    Array(bottomData?.length || 0).fill(
                                                        checked,
                                                    ),
                                                )
                                            }}
                                        />
                                    </TableCell>
                                    {headers.map((header, i) => {
                                        if (hideBottomColumns.includes(i)) {
                                            return
                                        }
                                        return (
                                            <TableCell
                                                key={header}
                                                style={headerCellStyle}
                                            >
                                                {sortableColumns.includes(i) ? (
                                                    <TableSortLabel
                                                        active={selectedSortColumn === i}
                                                        direction={
                                                            selectedSortColumn === i
                                                                ? sortAscending
                                                                    ? 'asc'
                                                                    : 'desc'
                                                                : 'asc'
                                                        }
                                                        onClick={() => setSort(i)}
                                                        IconComponent={renderSortIcon(i)}
                                                    >
                                                        {header}
                                                    </TableSortLabel>
                                                ) : (
                                                    header
                                                )}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            {hasData.bottom ? (
                                <TableBody>
                                    {bottomData.map((rowData, i) => {
                                        if (
                                            filterIndex !== null &&
                                            filterValue &&
                                            rowData[filterIndex] !== filterValue
                                        ) {
                                            return
                                        }
                                        return (
                                            <TableRow
                                                key={JSON.stringify(rowData)}
                                                onClick={() => {
                                                    const updatedCheckedItems = [
                                                        ...checkedItems,
                                                    ]
                                                    updatedCheckedItems[i] =
                                                        !checkedItems[i]
                                                    setCheckedItems(updatedCheckedItems)
                                                }}
                                                style={{cursor: 'pointer'}}
                                                hover
                                            >
                                                <TableCell>
                                                    <input
                                                        type="checkbox"
                                                        style={{margin: 'auto'}}
                                                        checked={checkedItems[i] || false}
                                                        onChange={() => {}}
                                                    />
                                                </TableCell>
                                                {rowData.map((cellData, i) => {
                                                    if (hideBottomColumns.includes(i)) {
                                                        return
                                                    }
                                                    return (
                                                        <TableCell
                                                            key={i}
                                                            style={bodyCellStyle}
                                                        >
                                                            {cellData}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            colSpan={
                                                headers.length -
                                                hideBottomColumns.length +
                                                1
                                            }
                                            style={{
                                                textAlign: 'center',
                                                ...bodyCellStyle,
                                            }}
                                        >
                                            No records to display
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </div>
                    <div
                        style={{width: '250px', marginLeft: 'auto', padding: '20px 20px'}}
                    >
                        <Button variant={StyleVariant.FILLED} onClick={addSelected}>
                            <p style={{fontFamily: 'lato'}}>Add Selected</p>
                        </Button>
                    </div>
                </Container>
            )}
        </>
    )
}

export default AddRemoveTable
