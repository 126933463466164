import RTIDAPI from '../../helpers/AxiosInstance'
import {useDispatch} from 'react-redux'
import {UpdateResidentListing} from '../../redux/Actions/ResidentListingActionCreators'

const useEnrollment = (enrollmentInfo) => {
    const dispatch = useDispatch()

    const getEnrollmentIdentification = async () => {
        return RTIDAPI.post(`enrollment/enrollmentIdentification`, enrollmentInfo)
    }

    const checkEnrollment = async () => {
        return RTIDAPI.post(`enrollment/enrollmentIdentificationCheck`, enrollmentInfo)
    }

    const submitEnrollment = async (postData, isConfirmation) => {
        if (isConfirmation) {
            return RTIDAPI.put(`enrollment/confirmEnrollmentInformation`, postData)
        } else if (!postData.ResidentEnrollmentId) {
            return RTIDAPI.post(`enrollment/submitEnrollmentInformation`, postData)
        } else {
            return RTIDAPI.patch(`enrollment/updateEnrollmentInformation`, postData)
        }
    }

    const updateEnrollmentStatus = async (
        programId,
        residentEnrollmentId,
        statusCode,
    ) => {
        const enrollment = {
            ProgramId: programId,
            ResidentEnrollmentId: residentEnrollmentId,
            ResidentEnrollmentStatusCode: statusCode,
        }
        const {data} = await RTIDAPI.put(
            `enrollment/residentEnrollmentStatus`,
            enrollment,
        )
        dispatch(UpdateResidentListing(data))
    }

    return {
        getEnrollmentIdentification,
        checkEnrollment,
        submitEnrollment,
        updateEnrollmentStatus,
    }
}

export default useEnrollment
