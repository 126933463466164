import React from 'react'
import Scorecard from '../../components/Scorecard/Scorecard'

export default function ITE() {
    return (
        <div>
            <Scorecard />
            <p style={{textAlign: 'center'}}>In-Training Exams</p>
        </div>
    )
}
