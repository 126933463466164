import * as ActionTypes from './ActionTypes'

export const ScorecardSuccess = (scorecardData) => {
    return {
        type: ActionTypes.SCORECARD_SUCCESS,
        payload: scorecardData,
    }
}

export const ScorecardFailed = (errorMessage) => {
    return {
        type: ActionTypes.SCORECARD_FAILED,
        payload: {errorMessage},
    }
}
