import React, {useEffect, useState} from 'react'
import AddRemoveTable from '../components/AddRemoveTable/AddRemoveTable'
import ABAHeader from '../components/ABAComponents/ABAHeader'
import ABAAlert from '../components/ABAComponents/ABAAlert'
import {LoadingOverlay} from 'aba-ui'
import RTIDAPI from '../../helpers/AxiosInstance'
import {ColorEnums} from '../../helpers/GeneralEnums'

export default function MassApproveTraining() {
    const [loading, setLoading] = useState('Loading Training Records')
    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)
    const [dataObjects, setDataObjects] = useState(null)
    const [topTableData, setTopTableData] = useState(null)
    const [bottomTableData, setBottomTableData] = useState(null)

    useEffect(() => {
        fetchTrainingRecords()
    }, [])

    const fetchTrainingRecords = async () => {
        try {
            setLoading('Loading Training Records')
            const {data} = await RTIDAPI.get('utilities/massApproveTrainingDetails')
            setDataObjects(data)
            const dataArray = data.map((trainingRecord) => {
                const {ABAID, Last4SSN, FullName} = trainingRecord
                return [ABAID, Last4SSN, FullName]
            })
            setTopTableData(dataArray)
            setBottomTableData([])
            setLoading(null)
            setError(null)
        } catch (error) {
            setLoading(null)
            setError('An error occurred while fetching roster')
        }
    }
    const approveTrainingRecords = async (trainingRecords) => {
        try {
            setLoading('Approving your roster')
            const approvedAbaIDs = trainingRecords.map((record) => {
                return record[0]
            })
            const recordsToApprove = dataObjects.filter((trainingRecord) => {
                return approvedAbaIDs.includes(trainingRecord.ABAID)
            })
            const {data} = await RTIDAPI.post(
                'utilities/massApproveTrainingDetails',
                recordsToApprove,
            )
            setMessage(data)
            fetchTrainingRecords()
        } catch (error) {
            setLoading(null)
            setError('An error occurred while approving roster')
        }
    }

    return (
        <>
            {loading && <LoadingOverlay title={loading} />}
            <ABAHeader text="Training Mass Approval" />
            {error && <ABAAlert text={error} style={{width: 400, margin: 'auto'}} />}
            {message && (
                <ABAAlert
                    text={message}
                    style={{
                        width: 400,
                        margin: 'auto',
                        backgroundColor: ColorEnums.aquaHaze,
                        border: `1px solid ${ColorEnums.cloudBurst}`,
                        color: ColorEnums.cloudBurst,
                    }}
                />
            )}
            <AddRemoveTable
                headers={['ABA ID', 'Last 4 SSN', 'Doctor Name']}
                colWidths={['25%', '25%', '50%']}
                topTableLabel="RECORDS TO BE APPROVED"
                bottomTableLabel="RECORDS NOT TO BE APPROVED"
                initialTopData={topTableData}
                initialBottomData={bottomTableData}
                sortColumn={2}
                onSubmit={approveTrainingRecords}
                submitLabel="Approve Training"
            />
        </>
    )
}
