import * as ActionTypes from '../Actions/ActionTypes'

const defaultState = {
    isCertifying: [],
    countries: [],
    genders: [],
    medicalDegrees: [],
    medicalSchoolCountries: [],
    usMedicalSchools: [],
    canadianMedicalSchools: [],
    primarySpecialties: [],
    priorCBProgramTypes: [],
    suffixes: [],
    trackTypes: [],
    willContinue: [],
    priorTrainings: [],
    states: [],
    fetched: false,
}
// This is where you manipulate the state. The state is immutable so you need to return a new state vs changing the state directly.
// This is an example
const OptionSets = (state = defaultState, {type, payload}) => {
    switch (type) {
        case ActionTypes.ADD_OPTION_SETS:
            return {
                ...state,
                ...payload,
                ErrorMessage: null,
                fetched: true,
            }
        case ActionTypes.RESET_REDUX:
            return defaultState
        default:
            return state
    }
}
export default OptionSets
