import React, {useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Container, Button, LoadingOverlay} from 'aba-ui'
import {useDispatch} from 'react-redux'
import {ColorEnums, GeneralEnums} from '../../../helpers/GeneralEnums'
import RTIDAPI from '../../../helpers/AxiosInstance'
import ABALogoInvoice from '../../../images/aba_logo_invoice.jpeg'
import useUser from '../../../libs/hooks/useUser'
import styles from './ITEInvoice.module.css'
import ABAAlert from '../../components/ABAComponents/ABAAlert'
import {useConfiguration} from '../../../libs/hooks/hooks'

const ITEInvoice = () => {
    const dispatch = useDispatch()
    window.statusInterval = null
    const {userData} = useUser()
    const {invoiceId} = useParams()
    const navigate = useNavigate()
    const {environment} = useConfiguration()

    const [invoice, setInvoice] = useState(null)
    const [loading, setLoading] = useState(null)
    const [error, setError] = useState(null)

    const {invoiceHeader, invoiceContainer, invoiceBold, invoiceTable, invoiceButtonContainer} = styles

    const getInvoice = async () => {
        if (!invoiceId || invoiceId === ':invoiceId') {
            navigate('/inTrainingExam')
            return
        }
        try {
            const {data} = await RTIDAPI.get(`/invoice/${invoiceId}`)
            setInvoice(data)
        } catch (error) {
            setError('Failed to retrieve invoice')
            console.log(error)
        }
    }

    const payByCard = async () => {
        setLoading('Redirecting to secure payment site...')
        setError(null)
        try {
            const currentTime = new Date()
            const statusConfig = {params: {timestamp: currentTime.toLocaleString()}}
            const statusResponse = await RTIDAPI.get('/payment/paymentProcessingStatus', statusConfig)
            if (statusResponse.data) {
                setError(
                    `It looks like you've already clicked "Pay Now." Please wait before trying again.`,
                )
                setLoading(null)
                return
            }
            const config = {
                params: {
                    invoiceID: invoiceId,
                },
            }
            const tokenResponse = await RTIDAPI.get('/payment/token', config)
            const authNetURL =
                environment === 'PROD'
                    ? 'https://accept.authorize.net/payment/payment'
                    : 'https://test.authorize.net/payment/payment'
            const authForm = document.createElement('form')
            authForm.method = 'post'
            authForm.action = authNetURL
            document.body.appendChild(authForm)

            const input = document.createElement('input')
            input.type = 'hidden'
            input.name = 'token'
            input.value = tokenResponse.data

            authForm.appendChild(input)
            authForm.submit()
        } catch (error) {
            setError('An error occurred while processing your request.')
            setLoading(null)
        }
    }

    const checkPaymentStatus = async () => {
        try {
            const currentTime = new Date()
            const statusConfig = {params: {timestamp: currentTime.toLocaleString()}}
            const statusResponse = await RTIDAPI.get('/payment/paymentProcessingStatus', statusConfig)
            if (!statusResponse?.data) {
                clearInterval(window.statusInterval)
            }
            return statusResponse.data
        } catch (error) {
            setError('An error occurred while cecking payment status.')
        }
    }

    useEffect(() => {
        ;(async () => {
            setLoading('Loading Invoice...')
            await getInvoice()
            const processingStatus = await checkPaymentStatus()
            if (processingStatus) {
                setLoading('Processing, please wait...')
                window.statusInterval = setInterval(async () => {
                    const status = await checkPaymentStatus()
                    if (!status) {
                        setError(null)
                        await getInvoice()
                        setLoading(null)
                    }
                }, 5000)
            } else {
                setLoading(null)
            }
        })()
        return () => {
            clearInterval(window.statusInterval)
            // dispatch(resetLoadingMessage())
        }
    }, [])

    const renderButtons = () => {
        if (!invoice) {
            return
        }

        if (invoice.StatusCode === GeneralEnums.rosterStatusCodes.Invoiced) {
            return (
                <div className={`no-print ${invoiceButtonContainer}`}>
                    <div>
                        <Button
                            variant="outlined"
                            style={{width: 200, marginRight: 70}}
                            onClick={() => {
                                navigate('/inTrainingExam')
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div>
                        <Button
                            variant="filled"
                            style={{width: 200, marginRight: 70}}
                            onClick={() => {
                                window.print()
                            }}
                        >
                            Print
                        </Button>
                        <Button
                            variant="filled"
                            style={{width: 200, marginRight: 70}}
                            onClick={() => {
                                window.alert(
                                    'Please print invoice and process it for payment. Payment must be received before examination results can be released.',
                                )
                            }}
                        >
                            Pay by Check
                        </Button>
                        <Button variant="filled" style={{width: 200}} onClick={payByCard}>
                            Pay Now
                        </Button>
                    </div>
                </div>
            )
        }

        return (
            <div className={`no-print ${invoiceButtonContainer}`}>
                <div>
                    <Button
                        variant="outlined"
                        style={{width: 230}}
                        onClick={() => {
                            navigate('/inTrainingExam')
                        }}
                    >
                        Return Home
                    </Button>
                </div>
                <div>
                    <Button
                        variant="filled"
                        style={{width: 230}}
                        onClick={() => {
                            window.print()
                        }}
                    >
                        Print
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <Container>
            <img
                src={ABALogoInvoice}
                alt="ABA Logo"
                style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'block',
                    width: '100%',
                    marginBottom: 45,
                }}
            />
            {error && <ABAAlert text={error} style={{width: 400, margin: 'auto'}} />}
            {invoice ? (
                <>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            <div style={{marginBottom: 40}}>
                                <div style={{color: ColorEnums.blueGray}}>
                                    The American Board of Anesthesiology
                                </div>
                                <div>4200 Six Forks Road, Suite 1100</div>
                                <div>Raleigh, NC 27609-2687</div>
                                <div>
                                    <a href="mailto:accountspayable@theaba.org">
                                        accountspayable@theaba.org
                                    </a>
                                </div>
                            </div>
                            <div className={invoiceContainer}>
                                <div className={invoiceHeader} style={{textAlign: 'center'}}>
                                    Bill To:
                                </div>
                                <div>{userData.ProgramName}</div>
                                {invoice.Department && <div>{invoice.Department}</div>}
                                <div>{invoice.AddressLine1}</div>
                                {invoice.AddressLine2 && <div>{invoice.AddressLine2}</div>}
                                <div>{`${invoice.AddressCity}, ${invoice.AddressState} ${invoice.PostalCode}`}</div>
                                {invoice && invoice.CountryId !== GeneralEnums.cert_countryIds.usa && (
                                    <div>{invoice.Country}</div>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className={invoiceContainer}>
                                <div className={invoiceHeader} style={{textAlign: 'center'}}>
                                    Invoice:
                                </div>
                                <div>
                                    <span className={invoiceBold}>Date: </span>
                                    <span>{invoice.FormattedCreatedOn}</span>
                                </div>
                                <div>
                                    <span className={invoiceBold}>Invoice/CM: </span>
                                    <span>{invoice.InvoiceNumber}</span>
                                </div>
                                <div>
                                    <span className={invoiceBold}>Terms: </span>
                                    <span>Due upon receipt</span>
                                </div>
                                <div>
                                    <span className={invoiceBold}>Group: </span>
                                    <span>{userData.ProgramNumber}</span>
                                </div>
                            </div>

                            <div style={{paddingTop: 20}}>
                                <span className={invoiceBold}>ACH Information:</span>
                                <div>
                                    <span>Bank of America</span>
                                </div>
                                <div>
                                    <span>Account: 237025481901</span>
                                </div>
                                <div>
                                    <span>Routing: 053000196</span>
                                </div>
                                <div>
                                    <span>4200 Six Forks Road. Suite 1100</span>
                                </div>
                                <div>
                                    <span>
                                        <span>Raleigh, NC 27609</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 style={{textAlign: 'center', marginTop: 35}}>{invoice.EventName}</h4>
                    <table className={invoiceTable}>
                        <tr
                            style={{
                                width: '100%',
                                backgroundColor: ColorEnums.danube,
                                color: 'white',
                            }}
                        >
                            <td style={{textAlign: 'center'}}>Item</td>
                            <td>Quantity</td>
                            <td>Total</td>
                        </tr>
                        {invoice.LineItems.map((lineItem) => {
                            return (
                                <tr key={lineItem.ProductName}>
                                    <td>{lineItem.ProductName}</td>
                                    <td>{lineItem.Quantity}</td>
                                    <td>${lineItem.Total}</td>
                                </tr>
                            )
                        })}
                    </table>
                    <div style={{marginTop: '15px', textAlign: 'center'}}>
                        <span style={{color: ColorEnums.blueGray}}>Total Amount: </span>
                        <span>${invoice.TotalAmount}</span>
                    </div>
                    {invoice && invoice.StatusCode === GeneralEnums.rosterStatusCodes.Paid && (
                        <h3
                            style={{
                                textAlign: 'center',
                                color: ColorEnums.pomegranate,
                            }}
                        >
                            PAID
                        </h3>
                    )}
                </>
            ) : (
                <div style={{height: 300}} />
            )}
            {renderButtons()}
            {loading && <LoadingOverlay fullscreen={false} title={loading} />}
        </Container>
    )
}

export default ITEInvoice
