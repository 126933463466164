import React, {useEffect, useState, useRef} from 'react'
import {useSelector} from 'react-redux'
import ABAContainer from '../ABAComponents/ABAContainer/ABAContainer'
import useCommon from '../../../libs/hooks/useCommon'
import usePDIR from '../../../libs/hooks/usePDIR'
import useTraining from '../../../libs/hooks/useTraining'
import styles from './Training.module.css'
import {Button, LoadingOverlay, StyleVariant} from 'aba-ui'
import RTIDAPI from '../../../helpers/AxiosInstance'
import {useNavigate} from 'react-router-dom'
import {GeneralEnums} from '../../../helpers/GeneralEnums'
import {useDispatch} from 'react-redux'
import {
    UpdateTrainingRecord,
    LoadTrainingRecords,
} from '../../../redux/Actions/TrainingRecordsActionCreators'
import {UpdateTrainingSummary} from '../../../redux/Actions/TrainingSummaryActionCreators'

const CombinedSurvey = ({
    resident,
    trainingId,
    trainingRecord,
    pdirId,
    userData,
    continuePressed,
    pdirTrainingPeriodId,
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {approveTraining, getTrainingEssentials, updateTrainingStatus} = useTraining()
    const {checkForExistingPDIR, validatePDIR} = usePDIR()
    const firstUpdate = useRef(true)
    const cccIframe = useRef(null)
    const pdirIframe = useRef(null)
    const [pdirSubmitted, setPdirSubmitted] = useState(false)
    const [cccSubmitted, setCccSubmitted] = useState(false)
    const [cccNotApplicable, setCccNotApplicable] = useState(false)
    const [registeredForBasic, setRegisteredForBasic] = useState(false)
    const {training_mainContainer, training_subContainer} = styles
    const [loading, setLoading] = useState(false)
    const {getABASettingStringValue} = useCommon()
    const [cccSurveyUrl, setCCCSurveyUrl] = useState(null)
    const [pdirSurveyUrl, setPDIRSurveyUrl] = useState(null)
    const trainingData = useSelector(({trainingRecords}) =>
        trainingRecords.TrainingRecords.find(
            (trainingRecord) => trainingRecord.TrainingId === trainingId,
        ),
    )

    const cccTitle = 'Clinical Competence'
    trainingData && trainingData.TrainingId
        ? trainingData.TrainingPeriod + ' Clinical Competence'
        : 'Clinical Competence'

    const pdirTitle =
        trainingRecord && trainingRecord.TrainingId
            ? trainingRecord.TrainingPeriod + ' Program Director Reference'
            : 'Program Director Reference'

    const handleMessage = (messageEvent) => {
        const {data} = messageEvent
        switch (data) {
            case 'submitted trn_training':
                setCccSubmitted(true)
                break
            case 'submitted trn_pdir':
                setPdirSubmitted(true)
                break
            case 'n/a':
                setCccNotApplicable(true)
                break
            case 'basic':
                setRegisteredForBasic(true)
                break
        }
    }

    const showPDIRSurvey = () => {
        return (
            pdirId &&
            pdirTrainingPeriodId === trainingRecord.TrainingPeriodId &&
            trainingRecord.StateCode === 0 // Active state code
        )
    }

    useEffect(() => {
        setLoading(true)
        const getData = async () => {
            const url = await getABASettingStringValue(
                process.env.NODE_ENV === 'development'
                    ? 'CRMSurveyURL' //External survey site
                    : 'RTIDSurveyURL', //Internal survey site
            )
            let queryParams = `&hideButtons=true&program=${RTIDAPI.defaults.headers.ProgramHeaderKey}`
            setCCCSurveyUrl(url + `?id=${trainingId}&entity=trn_training${queryParams}`)
            if (showPDIRSurvey()) {
                setPDIRSurveyUrl(url + `?id=${pdirId}&entity=trn_pdir${queryParams}`)
            }
            setLoading(false)
        }
        getData()
        window.addEventListener('message', handleMessage)
        return () => {
            window.removeEventListener('message', handleMessage)
        }
    }, [])
    useEffect(() => {
        if (cccSubmitted && (!pdirId || pdirSubmitted)) {
            completeTraining()
        }
    }, [pdirSubmitted, cccSubmitted])

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false
            return
        }
        handleSubmit()
    }, [continuePressed])

    const completeTraining = async () => {
        setLoading(true)
        try {
            const approveRecord =
                (resident.TrackType === GeneralEnums.trackTypes.AlternateEntryPath &&
                    userData.ProgramRole === GeneralEnums.programRoles.DepartmentChair) ||
                (resident.TrackType !== GeneralEnums.trackTypes.AlternateEntryPath &&
                    userData.ProgramRole === GeneralEnums.programRoles.ProgramDirector)
            if (!showPDIRSurvey()) {
                if (approveRecord) {
                    await approveTraining(trainingId)
                }
                navigate('/residentListing', {
                    state: {residentEnrollmentId: resident.ResidentEnrollmentId},
                })
                return
            }

            if (approveRecord) {
                await approveTraining(trainingId, pdirId)
                const existingPDIR = await checkForExistingPDIR(resident.ResidentEnrollmentId)
                if (existingPDIR?.Result && existingPDIR?.Result === 0) {
                    //Unsatisfactory
                    alert(
                        'An Unsatisfactory PDIR requires consultation with the ABA. Please contact the ABA.',
                    )
                }
            } else {
                await validatePDIR(pdirId)
            }
        } catch (error) {
            console.error(error)
            alert('Failed to submit survey(s)')
        } finally {
            setLoading(false)
            navigate('/residentListing', {state: {residentEnrollmentId: resident.ResidentEnrollmentId}})
        }
    }
    const handleSubmit = () => {
        window.scrollTo({top: 0})
        setCccSubmitted(false)
        setPdirSubmitted(false)
        cccIframe.current.contentWindow.postMessage('submit', '*')
        if (showPDIRSurvey()) {
            pdirIframe.current.contentWindow.postMessage('submit', '*')
        }
    }

    const handleDefaults = () => {
        window.scrollTo({top: 0})
        cccIframe.current.contentWindow.postMessage('defaults', '*')
        if (showPDIRSurvey()) {
            pdirIframe.current.contentWindow.postMessage('defaults', '*')
        }
    }

    let surveyHeight = 1100
    if (cccNotApplicable) surveyHeight = 70
    if (registeredForBasic) surveyHeight = 100
    return (
        <>
            {loading && <LoadingOverlay title="Loading..." />}
            {!registeredForBasic && (pdirSurveyUrl || !cccNotApplicable) && (
                <div style={{margin: 'auto', width: 200}}>
                    <Button onClick={handleDefaults} variant={StyleVariant.FILLED}>
                        Set Defaults
                    </Button>
                </div>
            )}
            <ABAContainer
                label={cccTitle}
                residentName={resident.ResidentDisplayName}
                textAlign="center"
                loading={false}
            >
                <div className={training_mainContainer}>
                    <div className={training_subContainer}>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            {cccSurveyUrl && (
                                <iframe
                                    id="surveyFrame"
                                    src={cccSurveyUrl}
                                    width="800"
                                    height={surveyHeight}
                                    sandbox="allow-same-origin allow-forms allow-scripts"
                                    ref={cccIframe}
                                    style={{borderWidth: 0}}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </ABAContainer>
            {pdirSurveyUrl && (
                <>
                    <div style={{height: 50}} />
                    <ABAContainer
                        label={pdirTitle}
                        residentName={resident.ResidentDisplayName}
                        textAlign="center"
                        loading={false}
                    >
                        <div className={training_mainContainer}>
                            <div className={training_subContainer}>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <iframe
                                        id="pdirSurveyFrame"
                                        src={pdirSurveyUrl}
                                        width="800"
                                        height="700"
                                        sandbox="allow-same-origin allow-forms allow-scripts"
                                        ref={pdirIframe}
                                        style={{borderWidth: 0}}
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </ABAContainer>
                </>
            )}
        </>
    )
}

export default CombinedSurvey
