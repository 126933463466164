import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getOptionSets} from '../../redux/Actions/GenericActionCreators'

const useOptionSets = () => {
    const dispatch = useDispatch()
    const options = useSelector(({optionSets}) => optionSets)

    useEffect(() => {
        const fetchOptionSets = async () => {
            dispatch(getOptionSets())
        }
        fetchOptionSets()
    }, [dispatch])

    return options
}

export default useOptionSets
