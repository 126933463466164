import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from './AccountHeader.module.css'

import HelperMethods from '../../../helpers/HelperMethods'

export default function AccountHeader() {
    const {
        programUserContainer,
        userIconContainer,
        programNameContainer,
        userRoleContainer,
        TrainingPeriodLabel,
        TrainingPeriodInfo,
    } = styles
    const UserInfo = useSelector(({user}) => user)
    const {
        ProgramName,
        ProgramNumber,
        RoleName,
        TrainingPeriodName,
        TrainingStartDate,
        TrainingEndDate,
    } = UserInfo

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    useEffect(() => {
        setStartDate(HelperMethods.formatDate(TrainingStartDate))
        setEndDate(HelperMethods.formatDate(TrainingEndDate))
    }, [])
    return (
        <div className={programUserContainer}>
            <div className={userIconContainer}>
                <FontAwesomeIcon
                    icon="user-circle"
                    color="#418fde"
                    style={{fontSize: '2rem', marginRight: 15, color: 'white'}}
                />
            </div>
            <div className={programNameContainer}>
                {ProgramName} ({ProgramNumber})<div>{RoleName}</div>
            </div>

            <div className={userRoleContainer}>
                <label className={TrainingPeriodLabel}>Active Training Period:</label>
                <div className={TrainingPeriodInfo}>
                    {TrainingPeriodName} ({startDate} - {endDate})
                </div>
            </div>
        </div>
    )
}
