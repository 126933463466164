import React from 'react'
import {TextInput, Dropdown} from 'aba-ui'
import HelperMethods from '../../helpers/HelperMethods'
import useOptionSets from '../../libs/hooks/useOptionSets'

const PriorCBTrainingForm = (props) => {
    const {formik, getInputStatus, isConfirmation, disabledInputs} = props
    const {priorCBProgramTypes} = useOptionSets()

    return (
        <div>
            <TextInput
                label="Date Started"
                maxLength={10}
                {...formik.getFieldProps('DatePriorCBTrainingStarted')}
                onChange={(event) => {
                    formik.setFieldValue(
                        'DatePriorCBTrainingStarted',
                        HelperMethods.dateInputMask(
                            event.target.value,
                            formik.values.DatePriorCBTrainingStarted,
                        ),
                    )
                }}
                placeholder="Date Started (mm/dd/yyyy)"
                errorMessage="Please enter a valid date"
                required
                textInputStatus={getInputStatus(formik, 'DatePriorCBTrainingStarted')}
                disabled={isConfirmation}
                value={formik.values.DatePriorCBTrainingStarted}
            />
            <TextInput
                label="Duration (number of months)"
                maxLength={2}
                {...formik.getFieldProps('PriorCBTrainingDuration')}
                onChange={(event) => {
                    formik.setFieldValue(
                        'PriorCBTrainingDuration',
                        event.target.value.replace(/\D/, ''),
                    )
                }}
                placeholder="Duration (number of months)"
                errorMessage="Please enter a valid value"
                required
                textInputStatus={getInputStatus(formik, 'PriorCBTrainingDuration')}
                disabled={isConfirmation}
                value={String(formik.values.PriorCBTrainingDuration)}
            />
            <TextInput
                label="Program Name"
                maxLength={50}
                {...formik.getFieldProps('PriorCBTrainingProgramName')}
                onChange={(event) => {
                    formik.setFieldValue('PriorCBTrainingProgramName', event.target.value)
                }}
                placeholder="Program Name"
                required
                textInputStatus={getInputStatus(formik, 'PriorCBTrainingProgramName')}
                disabled={isConfirmation}
                value={formik.values.PriorCBTrainingProgramName}
                errorMessage="Please enter a program name"
            />
            <TextInput
                placeholder="Program City"
                label="Program City"
                maxLength={25}
                {...formik.getFieldProps('PriorCBTrainingProgramCity')}
                onChange={(event) => {
                    formik.setFieldValue('PriorCBTrainingProgramCity', event.target.value)
                }}
                required
                textInputStatus={getInputStatus(formik, 'PriorCBTrainingProgramCity')}
                disabled={isConfirmation}
                errorMessage="Please enter a city"
            />
            <TextInput
                placeholder="Program State"
                label="Program State"
                maxLength={15}
                {...formik.getFieldProps('PriorCBTrainingProgramState')}
                onChange={(event) => {
                    formik.setFieldValue(
                        'PriorCBTrainingProgramState',
                        event.target.value,
                    )
                }}
                required
                textInputStatus={getInputStatus(formik, 'PriorCBTrainingProgramState')}
                disabled={isConfirmation}
                errorMessage="Please enter a state"
            />
            <TextInput
                placeholder="Program Director"
                label="Program Director"
                maxLength={30}
                {...formik.getFieldProps('PriorCBTrainingProgramDirector')}
                errorMessage="Please enter a director"
                onChange={(event) => {
                    formik.setFieldValue(
                        'PriorCBTrainingProgramDirector',
                        event.target.value,
                    )
                }}
                required
                textInputStatus={getInputStatus(formik, 'PriorCBTrainingProgramDirector')}
                disabled={isConfirmation}
            />
            <Dropdown
                options={priorCBProgramTypes.map((option) => {
                    return {
                        value: option.Value,
                        name: option.Label,
                    }
                })}
                errorMessage={'Please select a program type.'}
                onChange={(value) =>
                    formik.setFieldValue('PriorCBTrainingProgramType', value)
                }
                selectedValue={formik.values.PriorCBTrainingProgramType}
                dropdownStatus={getInputStatus(formik, 'PriorCBTrainingProgramType')}
                disabled={isConfirmation}
                placeholder="Program Type"
                required
                style={{width: '100%'}}
            />
        </div>
    )
}

export default PriorCBTrainingForm
