import React from 'react'
import PropTypes from 'prop-types'
import {ColorEnums} from '../../../helpers/GeneralEnums'

export default function ABAAlert(props) {
    const {text, style} = props
    return (
        <div
            style={{
                width: '100%',
                backgroundColor: ColorEnums.pomegranateLight,
                padding: '10px 10px',
                border: `1px solid ${ColorEnums.pomegranate}`,
                textAlign: 'center',
                borderRadius: 10,
                color: ColorEnums.pomegranateDark,
                ...style,
            }}
        >
            {text}
        </div>
    )
}

ABAAlert.defaultProps = {
    style: {},
}

ABAAlert.propTypes = {
    text: PropTypes.string.isRequired,
    style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
}
