import {Container, Dropdown, LoadingOverlay} from 'aba-ui'
import ABAHeader from '../components/ABAComponents/ABAHeader'
import React, {useEffect, useState} from 'react'
import useReport from '../../libs/hooks/useReport'
import RTIDAPI from '../../helpers/AxiosInstance'
import ABAAlert from '../components/ABAComponents/ABAAlert'
import {openPdf} from '../../helpers/HelperMethods'
import ReportList from '../components/Reports/ReportList'

export default function BasicReports() {
    const [event, setEvent] = useState(null)
    const [events, setEvents] = useState([])
    const [reports, setReports] = useState([])
    const {getPostedDocumentsByEvent, getPostedDocument} = useReport()
    const [loading, setLoading] = useState(0)
    const [errorMessage, setErrorMessage] = useState('')

    const openReport = async (report) => {
        setErrorMessage('')
        const {DocumentID} = report
        try {
            setLoading(true)
            const document = await getPostedDocument(DocumentID)
            openPdf(document)
        } catch (error) {
            console.error(error)
            setErrorMessage('An error occurred while loading your report')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const loadPage = async () => {
            setErrorMessage('')
            setLoading(true)
            try {
                const eventResponse = await RTIDAPI.get('/report/basicDocumentEvents')
                setEvents(
                    eventResponse.data.map(({Value, Label}) => {
                        return {
                            value: Value,
                            name: Label,
                        }
                    }),
                )
                if (eventResponse.data?.length) {
                    setEvent(eventResponse.data[0].Value)
                }
            } catch (error) {
                console.error(error)
                setErrorMessage('Failed to retrieve BASIC reports')
            } finally {
                setLoading(false)
            }
        }
        loadPage()
    }, [])

    useEffect(() => {
        const getReports = async () => {
            if (!event) {
                setReports([])
                return
            }
            setErrorMessage('')
            setLoading(true)
            try {
                setReports(await getPostedDocumentsByEvent(event))
            } catch (error) {
                console.error(error)
                setErrorMessage('Failed to retrieve BASIC reports')
            } finally {
                setLoading(false)
            }
        }
        getReports()
    }, [event])

    return (
        <div>
            <ABAHeader text="Reports" />
            {errorMessage && (
                <ABAAlert
                    text={errorMessage}
                    style={{
                        width: 400,
                        margin: 'auto',
                    }}
                />
            )}
            <Container title="BASIC Exam" headerJustify="center">
                <div style={{width: 400, margin: 'auto'}}>
                    <Dropdown
                        placeholder={'BASIC Event'}
                        onChange={(value) => {
                            setEvent(value)
                        }}
                        options={events}
                        selectedValue={event}
                        hideFirstOption={events?.length}
                    />
                </div>
                <ReportList reports={reports} onOpen={openReport} />
                {loading && <LoadingOverlay fullscreen={false} />}
            </Container>
        </div>
    )
}
