import RTIDAPI from '../../helpers/AxiosInstance'

const getGradClassReportDetails = async () => {
    try {
        const result = await RTIDAPI.get('report/gradClassReportDetails')
        return result.data
    } catch (err) {
        alert(err.response.data.ExceptionMessage)
    }
}

const getBoardPassReports = async () => {
    const result = await RTIDAPI.get('report/boardPassReports')
    return result.data
}

const getBoardPassReport = async (displayDate) => {
    const result = await RTIDAPI.get(`report/boardPassReports/${displayDate.replaceAll('/', '-')}`, {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
        },
    })
    return result.data
}

const getPostedDocumentsByEvent = async (eventId) => {
    const result = await RTIDAPI.get(`report/postedDocuments`, {params: {eventId}})
    return result.data
}

const getPostedDocument = async (documentId) => {
    const {data} = await RTIDAPI.get(`report/postedDocuments/${documentId}`, {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
        },
    })
    return data
}

const useReport = () => {
    return {
        getGradClassReportDetails,
        getBoardPassReports,
        getBoardPassReport,
        getPostedDocumentsByEvent,
        getPostedDocument,
    }
}

export default useReport
