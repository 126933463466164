import RTIDAPI from '../../helpers/AxiosInstance'

const getABASettingStringValue = async (settingName) => {
    try {
        const result = await RTIDAPI.get(
            `common/abaSettingStringValue/${settingName}`,
            {},
        )
        return result.data
    } catch (err) {
        alert(err.response.data.ExceptionMessage)
    }
}

const getABASettingIntValue = async (settingName) => {
    try {
        const result = await RTIDAPI.get(`common/abaSettingIntValue/${settingName}`, {})
        return result.data
    } catch (err) {
        alert(err.response.data.ExceptionMessage)
    }
}

const useCommon = () => {
    return {getABASettingStringValue, getABASettingIntValue}
}

export default useCommon
