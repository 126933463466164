import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'

Sentry.init({
    dsn: 'https://3d78c34366fc40ce9263e6a8ebd226c1@o1114836.ingest.sentry.io/6146293',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_Environment,
    release: process.env.REACT_APP_BUILD_BUILDNUMBER,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
