import React from 'react'
import PropTypes from 'prop-types'

export default function ABAButton(props) {
    const {label, radioId, value, checked, onChange} = props
    return (
        <label htmlFor={radioId}>
            <input
                type="radio"
                id={radioId}
                value={value}
                style={{display: 'inline'}}
                checked={checked}
                onChange={onChange}
            />
            <span style={{paddingLeft: 5}}>{label}</span>
        </label>
    )
}
ABAButton.defaultProps = {
    checked: false,
}

ABAButton.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    radioId: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
}
