import {useLocation} from 'react-router-dom'

export function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export const useConfiguration = () => {
    const buildID = process.env.REACT_APP_BUILD_BUILDNUMBER
    const environment = process.env.REACT_APP_Environment
    return {
        buildID,
        environment,
    }
}
