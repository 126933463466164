import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {LoadingOverlay, Dropdown, Button, TextInput, StyleVariant} from 'aba-ui'
import Scorecard from '../../components/Scorecard/Scorecard'
import ABAContainer from '../../components/ABAComponents/ABAContainer/ABAContainer'
import {GeneralEnums} from '../../../helpers/GeneralEnums'
import TrainingRecordTable from '../../components/Training/TrainingRecordTable'
import useTraining from '../../../libs/hooks/useTraining'

export default function TrainingReports() {
    const {TrainingRecordsList} = useTraining({loadTraining: true})
    const ResidentListingList = useSelector(({residentListing}) => residentListing)

    const [statusFilter, setStatusFilter] = useState('')
    const [yearFilter, setYearFilter] = useState('')
    const [gradeFilter, setGradeFilter] = useState('')
    const [pdirFilter, setPdirFilter] = useState('')
    const [nameFilter, setNameFilter] = useState('')

    const {Loading} = TrainingRecordsList
    const trainingRecords = [...(TrainingRecordsList?.TrainingRecords || [])]
        .sort((record1, record2) => {
            return record1.ResidentName > record2.ResidentName ? 1 : -1
        })
        .filter(
            (record) =>
                record.StatusCode === GeneralEnums.trainingStatusCodes.Validated ||
                record.StatusCode === GeneralEnums.trainingStatusCodes.NeedsAction ||
                record.StatusCode === GeneralEnums.trainingStatusCodes.Draft,
        )
    const [filteredRecords, setFilteredRecords] = useState(trainingRecords)

    const statusOptions = [...new Set(trainingRecords.map((resident) => resident.Status))].sort()
    const yearOptions = [...new Set(trainingRecords.map((resident) => resident.ProgramYear))].sort()
    const gradeOptions = [
        ...new Set(trainingRecords.map((resident) => resident.GradeValue).filter(Boolean)),
    ].sort()
    useEffect(() => {
        let filteredRecords = [...trainingRecords].filter(
            (record) =>
                record.StatusCode === GeneralEnums.trainingStatusCodes.Validated ||
                record.StatusCode === GeneralEnums.trainingStatusCodes.NeedsAction ||
                record.StatusCode === GeneralEnums.trainingStatusCodes.Draft,
        )
        filteredRecords = statusFilter
            ? filteredRecords.filter((record) => record.Status === statusFilter)
            : filteredRecords
        filteredRecords = yearFilter
            ? filteredRecords.filter((record) => record.ProgramYear === yearFilter)
            : filteredRecords
        filteredRecords = gradeFilter
            ? filteredRecords.filter((record) => record.GradeValue === gradeFilter)
            : filteredRecords
        filteredRecords = nameFilter
            ? filteredRecords.filter((record) => record.ResidentName.toLowerCase().includes(nameFilter))
            : filteredRecords
        filteredRecords = pdirFilter
            ? filteredRecords.filter(
                  (record) =>
                      (pdirFilter === 'Yes' && record.WithPDIR) ||
                      (pdirFilter === 'No' && !record.WithPDIR),
              )
            : filteredRecords
        setFilteredRecords(filteredRecords)
    }, [
        statusFilter,
        yearFilter,
        gradeFilter,
        nameFilter,
        pdirFilter,
        Loading,
        TrainingRecordsList?.TrainingRecords,
    ])
    return (
        <>
            <Scorecard />
            <ABAContainer label="Training Reports">
                <div style={{padding: '20px 20px'}}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            alignContent: 'center',
                            width: '100%',
                        }}
                    >
                        <div style={{width: '15%', marginRight: '10px'}}>
                            <Dropdown
                                placeholder="Status"
                                selectedValue={statusFilter}
                                options={statusOptions.map((statusOption) => {
                                    return {
                                        value: statusOption,
                                        name: statusOption,
                                    }
                                })}
                                onChange={(value) => {
                                    setStatusFilter(value)
                                }}
                                style={{
                                    marginRight: 100,
                                    width: '300px',
                                }}
                            />
                        </div>
                        <div style={{width: '15%', marginRight: '10px'}}>
                            <Dropdown
                                placeholder="Current Year"
                                selectedValue={yearFilter}
                                options={yearOptions.map((currentYearOption) => {
                                    return {
                                        value: currentYearOption,
                                        name: currentYearOption,
                                    }
                                })}
                                onChange={(value) => {
                                    setYearFilter(value)
                                }}
                                style={{
                                    marginRight: 100,
                                    width: '300px',
                                }}
                            />
                        </div>
                        <div style={{width: '15%', marginRight: '10px'}}>
                            <Dropdown
                                placeholder="With PDIR"
                                selectedValue={pdirFilter}
                                options={[
                                    {
                                        value: 'Yes',
                                        name: 'Yes',
                                    },
                                    {
                                        value: 'No',
                                        name: 'No',
                                    },
                                ]}
                                onChange={(value) => {
                                    setPdirFilter(value)
                                }}
                                style={{
                                    marginRight: 100,
                                    width: '300px',
                                }}
                            />
                        </div>
                        <div style={{width: '15%', marginRight: '10px'}}>
                            <Dropdown
                                placeholder="Grade"
                                selectedValue={gradeFilter}
                                options={gradeOptions.map((gradeOption) => {
                                    return {
                                        value: gradeOption,
                                        name: gradeOption,
                                    }
                                })}
                                onChange={(value) => {
                                    setGradeFilter(value)
                                }}
                                style={{
                                    marginRight: 100,
                                    width: '300px',
                                }}
                            />
                        </div>
                        <div style={{width: '15%', marginRight: '10px'}}>
                            <TextInput
                                placeholder="Name Search"
                                onChange={(event) => {
                                    setNameFilter(event.target.value)
                                }}
                                value={nameFilter}
                                name="Name Search"
                            />
                        </div>
                        <div>
                            <Button
                                text="Reset Filters"
                                variant={StyleVariant.FILLED}
                                style={{
                                    marginTop: 0,
                                    width: '150px',
                                }}
                                onClick={() => {
                                    setYearFilter('')
                                    setStatusFilter('')
                                    setGradeFilter('')
                                    setNameFilter('')
                                    setPdirFilter(null)
                                }}
                            >
                                Reset Filters
                            </Button>
                        </div>
                    </div>
                    {trainingRecords && <TrainingRecordTable trainingData={filteredRecords} showName />}

                    {Loading && <LoadingOverlay title={'Loading training records'} />}
                </div>
            </ABAContainer>
            <div style={{height: 50}} />
        </>
    )
}
