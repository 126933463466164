import {FocusBox, LoginLogo} from 'aba-ui'
import React, {useEffect} from 'react'
import styles from './Landing.module.css'
import RTIDLogo from '../../../images/ABA-RTID-Logo-White-transparent.png'
import {PageFooter} from 'aba-ui/lib/esm/components'
import {useConfiguration} from '../../../libs/hooks/hooks'
import RTIDAPI from '../../../helpers/AxiosInstance'
import {useNavigate} from 'react-router-dom'

export default function Landing() {
    const {buildID, environment} = useConfiguration()
    const navigate = useNavigate()

    useEffect(() => {
        const checkAuth = async () => {
            try {
                await RTIDAPI.get('authentication/check')
                navigate('/residentListing',{replace:true})
            } catch {
                navigate('/login',{replace:true})
            }
        }
        checkAuth()
    }, [])
    return (
        <div className={styles.landingContainer}>
            <LoginLogo src={RTIDLogo} width="300px" />
            <FocusBox>
                <p style={{textAlign: 'center', padding: '20px 0px 60px 0px'}}>
                    <b>Redirecting, please wait...</b>
                </p>
            </FocusBox>
            <div style={{height: '20vh'}} />
            <PageFooter buildID={buildID} environment={environment} />
        </div>
    )
}
