import React, {useEffect, useState} from 'react'
import {Container, Button, StyleVariant, Dropdown, TextInput, LoadingOverlay} from 'aba-ui'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import RTIDAPI from '../../helpers/AxiosInstance'
import ABAHeader from '../components/ABAComponents/ABAHeader'
import ABAAlert from '../components/ABAComponents/ABAAlert'
import {GeneralEnums, ColorEnums} from '../../helpers/GeneralEnums'

const headerCellStyle = {
    borderColor: '#418FDE',
    fontSize: 14,
    color: ColorEnums.blueGray,
    fontFamily: 'lato',
}

const bodyCellStyle = {
    color: ColorEnums.cloudBurst,
    fontFamily: 'lato',
}

export default function CCCReportResults() {
    const {error, standard} = GeneralEnums.textInputStatus
    const [programOptions, setProgramOptions] = useState([])
    const [trainingResult, setTrainingResult] = useState('All')
    const [programPeriod, setProgramPeriod] = useState('')
    const [ssn, setSsn] = useState('')
    const [ssnStatus, setSsnStatus] = useState(standard)
    const [searchResults, setSearchResults] = useState([])
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        ;(async () => {
            try {
                setLoading('Loading...')
                const {data} = await RTIDAPI.get('report/periodOptions')
                setProgramOptions(
                    data.map((option) => {
                        return {name: option.Label, value: option.Value}
                    }),
                )
                setProgramPeriod(data[0].Value)
                getSearchResults(-1, data[0].Value, '')
            } catch (error) {
                setErrorMessage('An error occurred while fetching reports')
                setLoading(false)
            }
        })()
    }, [])

    const getSearchResults = async (trainingResult, programPeriodID, ssn) => {
        try {
            if (ssn && !/^\d{9}$/.test(ssn)) {
                setSsnStatus(error)
                return
            }
            setLoading('Loading...')
            setErrorMessage('')
            setSsnStatus(standard)
            if (!ssn) {
                ssn = null
            }
            if (trainingResult == -1) {
                trainingResult = null
            }

            const {data} = await RTIDAPI.get('report/survey', {
                params: {
                    ssn,
                    trainingResult,
                    programPeriodID,
                },
            })
            setSearchResults(data)
            setLoading(false)
        } catch (error) {
            setErrorMessage('An error occurred while fetching reports')
            setLoading(false)
        }
    }
    const getReport = async (responseID) => {
        setErrorMessage('')
        try {
            setLoading('Loading...')
            const {data} = await RTIDAPI.get(`report/survey/${responseID}`, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/pdf',
                },
            })
            const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}))
            const link = document.createElement('a')
            link.href = url
            link.target = '_blank'
            link.rel = 'noopener noreferrer'
            document.body.appendChild(link)
            link.click()
            setLoading(false)
        } catch (error) {
            setErrorMessage('An error occurred while loading your report')
            setLoading(false)
        }
    }

    return (
        <>
            <ABAHeader text="CCC Report Results" />
            {errorMessage && (
                <ABAAlert
                    text={errorMessage}
                    style={{
                        width: 400,
                        margin: 'auto',
                    }}
                />
            )}
            <Container title="Select CCC Result" headerJustify="center">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        margin: '10px 50px',
                    }}
                >
                    <Dropdown
                        onChange={(value) => {
                            setTrainingResult(value)
                        }}
                        options={[
                            {value: -1, name: 'All'},
                            {value: 1, name: 'Satisfactory'},
                            {value: 0, name: 'Unsatisfactory'},
                        ]}
                        placeholder="Training Result"
                        hideFirstOption
                        selectedValue={trainingResult}
                    />
                    <div style={{width: 20}} />
                    <Dropdown
                        onChange={(value) => {
                            setProgramPeriod(value)
                        }}
                        options={programOptions}
                        placeholder="Program Period"
                        hideFirstOption
                        selectedValue={programPeriod}
                    />
                    <div style={{alignItems: 'center', margin: '20px 20px'}}>OR</div>
                    <div
                        style={{
                            width: 200,
                            marginRight: 20,
                        }}
                    >
                        <TextInput
                            name="ssnSearch"
                            value={ssn}
                            label="SSN no dashes(-) or spaces"
                            onChange={(event) => {
                                setSsn(event.target.value)
                            }}
                            textInputStatus={ssnStatus}
                            errorMessage="Please enter a valid SSN"
                            placeholder="SSN no dashes(-) or spaces"
                            maxLength={50}
                            width="300px"
                            style={{height: 200}}
                            pattern="\d*"
                        />
                    </div>
                    <div>
                        <Button
                            variant={StyleVariant.FILLED}
                            style={{marginTop: 0, marginBottom: 10}}
                            onClick={() => {
                                getSearchResults(trainingResult, programPeriod, ssn)
                            }}
                        >
                            Search
                        </Button>
                    </div>
                </div>
                <Table sx={{margin: 'auto'}} style={{tableLayout: 'fixed'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={headerCellStyle}>Name</TableCell>
                            <TableCell style={headerCellStyle}>Period</TableCell>
                            <TableCell style={headerCellStyle}>Result</TableCell>
                            <TableCell style={headerCellStyle}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    {searchResults?.length ? (
                        <TableBody>
                            {searchResults.map((result, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell style={bodyCellStyle}>
                                            {result.FirstName} {result.LastName} {result.MedicalDegree}
                                        </TableCell>
                                        <TableCell style={bodyCellStyle}>{result.PeriodName}</TableCell>
                                        <TableCell style={bodyCellStyle}>
                                            {result.TrainingResultName}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                cursor: 'pointer',
                                                color: ColorEnums.danube,
                                                fontFamily: 'lato',
                                            }}
                                            onClick={() => {
                                                getReport(result.ResponseID)
                                            }}
                                        >
                                            View Report
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    ) : (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={4} style={{textAlign: 'center', ...bodyCellStyle}}>
                                    No records to display
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
                {loading && <LoadingOverlay fullscreen={false} />}
            </Container>
        </>
    )
}
