import * as ActionTypes from '../Actions/ActionTypes'

const defaultState = {
    residentEnrollmentId: null,
    data: {},
    scrollValue: 0,
}

const expandedResident = (state = defaultState, {type, payload}) => {
    switch (type) {
        case ActionTypes.RESIDENT_OPEN:
            return {
                ...state,
                ...payload,
                ErrorMessage: null,
            }
        case ActionTypes.RESIDENT_CLOSE:
            return {
                ...defaultState,
                ErrorMessage: payload.ErrorMessage,
            }
        case ActionTypes.RESET_REDUX:
            return defaultState
        default:
            return state
    }
}
export default expandedResident
