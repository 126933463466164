import RTIDAPI from '../../../helpers/AxiosInstance'
import {
    ScorecardSuccess,
    ScorecardFailed,
} from '../../../redux/Actions/ScorecardActionCreators'

const getScorecardValues = (programId) => async (dispatch) => {
    const scorecardValues = await RTIDAPI.get(
        `residentListing/scorecard/${programId}`,
        {},
    ).catch((error) => error)

    if (scorecardValues) {
        dispatch(ScorecardSuccess(scorecardValues.data))
    } else {
        dispatch(ScorecardFailed('An error occurred while loading the Scorecard'))
    }
    return scorecardValues.data
}
export default getScorecardValues
