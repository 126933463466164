import * as ActionTypes from '../Actions/ActionTypes'

const defaultState = {
    TrainingSummary: [],
    Loading: false,
    ErrorMessage: null,
}

const TrainingSummary = (state = defaultState, {type, payload}) => {
    switch (type) {
        case ActionTypes.TRAININGSUMMARY_SUCCESS:
            return {
                ...state,
                TrainingSummary: payload,
                Loading: false,
                ErrorMessage: null,
            }
        case ActionTypes.TRAININGSUMMARY_FAILED:
            return {
                ...defaultState,
                Loading: false,
                ErrorMessage: payload.ErrorMessage,
            }
        case ActionTypes.RESET_REDUX:
            return defaultState
        case ActionTypes.TRAININGSUMMARY_UPDATE:
            return {
                TrainingSummary: state.TrainingSummary.map((summary) => {
                    if (summary.ResidentEnrollmentId === payload.ResidentEnrollmentId) {
                        return payload
                    }
                    return summary
                }).filter((summaryRecord) => {
                    if (summaryRecord.StatusCode !== null) return true
                    return false
                }),
                Loading: false,
                ErrorMessage: null,
            }
        default:
            return state
    }
}
export default TrainingSummary
