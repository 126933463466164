import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import ABAActionPopup from '../ABAActionPopup/ABAActionPopup'
import {ColorEnums} from '../../../../helpers/GeneralEnums'

const ABATable = ({
    data,
    header,
    editRotation,
    setEditId,
    deleteRotation,
    noDataMessage,
}) => {
    const row = (x, i, header) => (
        <TableRow key={data[i].RotationId}>
            {header.map((y, k) => {
                if (y.prop === 'action') {
                    return (
                        <TableCell key={`trKey-${k}`}>
                            <div>
                                <ABAActionPopup
                                    uniqueId={data[i].RotationId}
                                    actions={[
                                        {
                                            name: 'Edit',
                                            onClick: () => {
                                                setEditId(data[i].RotationId)
                                                editRotation(data[i].RotationId)
                                            },
                                        },
                                        {
                                            name: 'Delete',
                                            onClick: () => {
                                                window.confirm(
                                                    'Are you sure you wish to delete this rotation?',
                                                )
                                                    ? deleteRotation(data[i].RotationId)
                                                    : onCancel('cancel')
                                            },
                                        },
                                    ]}
                                />
                            </div>
                        </TableCell>
                    )
                } else {
                    return <TableCell key={`trc-${k}`}>{x[y.prop]}</TableCell>
                }
            })}
        </TableRow>
    )

    return (
        <div>
            <Table style={{width: '80%', minWidth: '40px'}} sx={{margin: 'auto'}}>
                <TableHead>
                    <TableRow>
                        {header.map((x, i) => (
                            <TableCell
                                style={{
                                    borderColor: '#418FDE',
                                    fontSize: 14,
                                    color: ColorEnums.blueGray,
                                }}
                                key={`thc-${i}`}
                            >
                                {x.name}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {data.length !== 0 ? (
                    <TableBody>{data.map((x, i) => row(x, i, header))}</TableBody>
                ) : (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan="6" style={{textAlign: 'center'}}>
                                {noDataMessage}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
        </div>
    )
}

export default ABATable
