import React from 'react'
import PropTypes from 'prop-types'
import styles from '../../styles/ABAComponents.module.css'

export default function ABAButton(props) {
    const {abaHeader} = styles
    const {text} = props
    return <h1 className={abaHeader}>{text}</h1>
}
ABAButton.propTypes = {
    text: PropTypes.string.isRequired,
}
