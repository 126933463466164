import RTIDAPI from '../../helpers/AxiosInstance'

const getProgramInformation = async (accountId) => {
    return new Promise((resolve) => {
        RTIDAPI.get(`account/getProgramInformation/${accountId}`, {})
            .then((response) => resolve(response.data))
            .catch((err) => err)
    })
}

const useProgram = () => {
    return {getProgramInformation}
}

export default useProgram
