import RTIDAPI from '../../helpers/AxiosInstance'

const checkForExistingPDIR = async (enrollmentId) => {
    try {
        const result = await RTIDAPI.get(`pdir/pdirCheck/${enrollmentId}`, {})
        return result.data
    } catch (err) {
        alert(err.response.data.ExceptionMessage)
    }
}

const getSurveyDetails = async (pdirInfo) => {
    try {
        const result = await RTIDAPI.patch(`pdir/getSurveyDetails`, pdirInfo)
        return result.data
    } catch (err) {
        alert(err.response.data.ExceptionMessage)
    }
}

const createPDIR = async (pdir) => {
    try {
        const result = await RTIDAPI.post(`pdir/createPDIR`, pdir)
        return result.data
    } catch (err) {
        alert(err.response.data.ExceptionMessage)
    }
}

const approvePDIR = async (pdirId, goToResidentListing) => {
    try {
        return await RTIDAPI.put(`pdir/approvePDIR/${pdirId}`, {})
    } catch (err) {
        alert(err.response.data.ExceptionMessage)
    } finally {
        goToResidentListing
    }
}

const validatePDIR = async (pdirId) => {
    try {
        return await RTIDAPI.put(`pdir/validatePDIR/${pdirId}`, {})
    } catch (err) {
        alert(err.response.data.ExceptionMessage)
    }
}

const usePDIR = () => {
    return {checkForExistingPDIR, getSurveyDetails, createPDIR, approvePDIR, validatePDIR}
}

export default usePDIR
